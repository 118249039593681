import React from 'react';
import styled, { css } from 'styled-components';
import { respondTo } from '../../theme/mixin';

const BoxWrapper = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  box-shadow: 0px 5px 15px #00000026;
  padding: 16px;
  background-color: ${props => (props.reverse ? props.color : '#fff')};
  border-radius: 15px;
  background-image: url(${process.env.PUBLIC_URL + '/images/spring.svg'});
  background-repeat: no-repeat;
  background-position: bottom 15px right 0px;
  background-size: 45% auto;
  p {
    font-size: 14px;
  }
  ${respondTo.sm`
background-size: auto;
`}
  ${respondTo.md`
background-size: 45% auto;
 `}
  .wrapper {
    position: relative;

    flex-grow: 1;
    height: 100%;
    .box-body {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    span {
      color: #ff7db9;
      font-size: 18px;
      font-weight: 300;
    }

    .box-header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 11px;

      h5 {
        font-weight: 700;
        font-size: 21px;
        color: ${props => props.color};
        margin-right: 5px;
      }
    }
  }

  .box-subtitle {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px 20px -15px;

    h5 {
      font-weight: 700;
      font-size: 21px;
      color: ${props => (props.reverse ? '#fff' : props.color)};
      margin-right: 5px;
      padding-left: ${props => (props.reverse ? '15px' : '35px')};
    }

    .box-second-title {
      width: 100%;
      background-color: ${props => (props.reverse ? '#fff' : props.color)};
      color: ${props => (props.reverse ? props.color : '#fff')};
      font-size: 18px;
      font-weight: 300;
      padding: 5px 0 5px ${props => (props.reverse ? '15px' : '35px')};
      margin-top: 3px;
    }
  }
`;

const ContentBox = props => {
  return (
    <BoxWrapper
      color={props.color}
      position={props.position}
      style={props.style}
      reverse={props.reverse}
      fullheight={props.fullheight}
      className={props.className}
      spring={props.spring}
    >
      {props.subtitle && (
        <div className='box-subtitle'>
          <h5>{props.title}</h5>
          {props.icon && <img src={props.icon} alt='' />}
          <div className='box-second-title'>{props.subtitle}</div>
        </div>
      )}
      <div className='wrapper'>
        {props.title && !props.subtitle && (
          <div className='box-header'>
            <h5>{props.title}</h5>
            {props.icon && <img src={props.icon} alt='' />}
          </div>
        )}
        <div className='box-body'>{props.children}</div>
      </div>
    </BoxWrapper>
  );
};

export default ContentBox;
