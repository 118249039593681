import React from 'react';
import PropTypes from 'prop-types';
//mport Response from "../../Response";
//import { Click } from "../../Interactions";
import { ClickHandler } from '../../Handlers';
import { getElement } from '../../../../utils/utils';

const Mechanic = props => {
  const {
    action: { data, links },
    g: { id, dynamic, interaction },
    authObj: { isLogged },
  } = props;

  let content = null;

  let handler = null;

  // qui non so c'è qualcosa che non mi torna... forse dovrei controllare anche l'interaction e nel caso di click instanziare ClickHandler

  switch (interaction.type) {
    case 'click':
      handler = <ClickHandler {...props} />;
      break;
  }

  return <div style={{ border: '1px solid #002D72' }}>{handler}</div>;

  // switch (dynamic) {
  //   case "getcode":
  //     let code = getElement(data, id);
  //     content = (
  //       <React.Fragment>
  //         {code && isLogged ? (
  //           <Response
  //             {...props}
  //             code={data.find((x) => x.id === id.toString())["codes"]}
  //           />
  //         ) : (
  //           <Click {...props} />
  //         )}
  //       </React.Fragment>
  //     );
  //     break;
  //   case "getlink":
  //     // let link = getElement(links, id);
  //     // content = (
  //     //     <React.Fragment>
  //     //         { link ? <Response {...props} id={id} /> : <Click {...props} /> }
  //     //     </React.Fragment>
  //     // )
  //     content = <div>asd</div>;
  //     break;
  //   case "instantwin":
  //     content = (
  //       <React.Fragment>
  //         <Click {...props} />
  //       </React.Fragment>
  //     );
  //     break;
  // }

  // return <div>{content}</div>;
};

Mechanic.propTypes = {
  dynamic: PropTypes.string,
  codes: PropTypes.array,
  links: PropTypes.array,
  id: PropTypes.number,
};

export default Mechanic;
