import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../../../theme/mixin';
import { Text, Button } from '../../../Ui';
import { Link } from 'react-router-dom';
const Cont = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 34px 16px 200px 16px;
  border-radius: 16px;
  box-shadow: 0px 2px 15px #00000029;
  button {
    flex-direction: row-reverse;
    svg {
      right: 0;
    }
  }
`;
const TextContainer = styled.div`
  margin-bottom: 22px;
  ${respondTo.sm`
		margin-bottom: 30px;
	`}
`;
const ThankYouPage = props => {
  let cor_answ = null;

  if (props.correct_answ !== null) {
    cor_answ = props.correct_answ;
  } else {
    cor_answ = props.g.log.correct_answers ? props.g.log.correct_answers : 0;
  }
  return (
    <>
      <Cont>
        <TextContainer>
          <Text as='p' size={24} bold color='#172344'>
            Grazie per aver giocato!
          </Text>
          <Text as='p' size={18} margin={'12px 0 12px 0'} color='#172344'>
            Hai dato {cor_answ} {cor_answ === 1 ? 'risposta esatta' : 'risposte esatte'}.
          </Text>
          <Text as='p' size={18} margin={'12px 0 32px 0'} color='#172344'>
            A breve riceverai una mail in cui scoprirai quante possibilità hai di essere estratto in
            base al numero di risposte esatte che hai dato.
          </Text>
          <Link to='/'>
            <Button border hoverGrey arrowLeft>
              TORNA ALLA HOME PAGE
            </Button>
          </Link>
        </TextContainer>
      </Cont>
    </>
  );
};

export default ThankYouPage;
