import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Memory } from '../../Interactions';
import { Box, Loader } from '../../../Ui';
import PreInteraction from '../../PreInteraction';
import ResultGame from '../../Response/ResultGame';
const MemoryHandler = props => {
  const {
    g: {
      preinteraction,
      log: { steps },
    },
    played,
    action: { loading },
  } = props;

  let showPreInteraction = false;

  if (preinteraction !== null) {
    showPreInteraction = true;
  }

  const [showResult, setShowResult] = useState(false);
  const [showInteraction, setShowInteraction] = useState(!showPreInteraction); // true or false in base al fatto che ci sia la prop preinteraction

  let content = null;

  const onShowResult = () => setShowResult(true);

  const onShowInteractionHandler = () => {
    setShowInteraction(true);
  };

  content = (
    <Box>
      {loading ? (
        <Loader show={loading} full />
      ) : showResult || played !== null ? (
        <ResultGame {...props} />
      ) : !showInteraction && steps.preinteraction.data === null ? (
        <PreInteraction
          preinteraction={preinteraction}
          onShowInteractionHandler={onShowInteractionHandler}
          {...props}
        />
      ) : (
        <Memory typeInteraction='interaction' onShowResult={onShowResult} {...props} />
        // <div>Qui</div>
      )}
    </Box>
  );

  return content;
};

MemoryHandler.propTypes = {
  action: PropTypes.object,
};

export default MemoryHandler;
