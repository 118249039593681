import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fade from '../../components/Fade';
import Form from '../../components/Games/Form';
import { getGame, getGames, getUserInfo } from '../../store/actions';
import { useHistory } from 'react-router';
import { Box, Text, Wrapper } from '../../components/Ui';

function FormWin() {
  const dispatch = useDispatch();
  const history = useHistory();
  //const isLogged = useSelector(state => state.auth.token);
  const formInfo = useSelector(state => state.user.formInfo || {});
  const loadingForm = useSelector(state => state.user.loadingForm);
  const lang = useSelector(state => state.app.config?.lang);
  //const { game } = useSelector(state => state.promo || {});
  //const { log, played, form } = game.length > 0 ? game[0] : {};

  useEffect(() => {
    //dispatch(getGame(wins[0]?.game_id, 'test-click', ''));
    return () => {
      dispatch(getUserInfo());
    };
  }, []);

  useEffect(() => {
    /*    if (wins?.length > 0) {
      wins.every(({ pending }) => !pending) && history.push('/');
    } */
  }, []);
  //const { form, id_game, playslog_id, promotion, game: gameName, prize } = formInfo || {};
  return formInfo?.form ? (
    <Wrapper>
      <Form lang={lang} {...formInfo} />
    </Wrapper>
  ) : (
    <Wrapper>
      <Box style={{ marginTop: '115px' }}>
        <Text> Il form non esiste</Text>
      </Box>
    </Wrapper>
  );
}

export default FormWin;
