import * as actionTypes from './actionTypes';
import customaxios from '../../config/axios-refresh-token';
import customAxiosNm from '../../config/axios-refresh-token-no-multipart';
import { sendLogError } from './error';

const addToCartLoading = () => {
  return {
    type: actionTypes.GET_CART_LOADING,
  };
};
const addToCartLoadingSuccess = () => {
  return {
    type: actionTypes.GET_CART_LOADING_SUCCESS,
  };
};
export const addToCart = productId => {
  let endpoint = 'products/addtocart';
  const data = { product_id: productId };
  return dispatch => {
    dispatch(addToCartLoading());
    customAxiosNm.post(endpoint, data).then(result => {
      dispatch(toCartSuccess(result.data.cart));
      dispatch(addToCartLoadingSuccess());
    });
  };
};

export const removeFromCart = productId => {
  let endpoint = 'products/removefromcart';
  const data = { product_id: productId };
  return dispatch => {
    dispatch(addToCartLoading());
    customAxiosNm.post(endpoint, data).then(result => {
      if (result.status === 200) {
        dispatch(toCartSuccess(result.data.cart));
        dispatch(addToCartLoadingSuccess());
      }
    });
  };
};

export const changeQtyProduct = (productId, qty) => {
  let endpoint = 'products/updatequantity';
  const data = { product_id: productId, quantity: +qty };
  return dispatch => {
    dispatch(addToCartLoading());
    customAxiosNm.post(endpoint, data).then(result => {
      if (result.status === 200) {
        dispatch(toCartSuccess(result.data.cart));
        dispatch(addToCartLoadingSuccess());
      }
    });
  };
};

const toCartSuccess = cart => {
  return {
    type: actionTypes.TO_CART_SUCCESS,
    cart,
  };
};

const getProductCartSuccess = objectCart => {
  return {
    type: actionTypes.GET_PRODUCT_CART_SUCCESS,
    objectCart,
  };
};
export const getProductCart = () => {
  let endpoint = 'products/cart';
  return dispatch => {
    customaxios.get(endpoint).then(result => {
      dispatch(getProductCartSuccess(result.data));
    });
  };
};
