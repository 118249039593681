import * as actionTypes from '../actions/actionTypes';

const initialState = {
  token: null,
  refreshToken: null,
  userId: null,
  loading: true,
  loadingFirstAccess: true,
  errorFirstAccess: '',
  error: null,
  errorsignin: null,
  loadingsignin: false,
  logout: false,
  loadingRecovery: false,
  recovery: false,
  errorRecovery: null,
  loadingReset: true,
  reset: false,
  errorReset: null,
  loadingChangePsw: false,
  changePsw: false,
  errorChangePsw: null,
  redirect: false,
  loadingSignup: false,
  signup: false,
  errorSignup: null,
  isOauth: false,
  loadingOauthSendToken: true,
  errorOauthSendoToken: null,
  loadingCognito: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIRST_LOGIN_SUCCESS:
      return {
        ...state,
        errorFirstAccess: '',
        loadingFirstAccess: false,
        token: action.token,
        refreshToken: action.refreshToken,
      };

    case actionTypes.FIRST_LOGIN_ERROR:
      return {
        ...state,
        errorFirstAccess: action.error,
        loadingFirstAccess: false,
      };
    case actionTypes.CLEAR_FIRST_LOGIN_ACCESS:
      return {
        ...state,
        errorFirstAccess: '',
      };
    case actionTypes.AUTH_START:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case actionTypes.AUTH_SUCCESS:
      return {
        ...state,
        token: action.token,
        userId: action.userId,
        loading: false,
        error: null,
        logout: false,
      };
    case actionTypes.AUTH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.SIGNIN_START:
      return {
        ...state,
        loadingsignin: true,
      };
    case actionTypes.SIGNIN_SUCCESS:
      return {
        ...state,
        errorsignin: null,
        loadingsignin: false,
        token: action.token,
        userId: action.userId,
      };
    case actionTypes.SIGNIN_ERROR:
      return {
        ...state,
        loadingsignin: false,
        errorsignin: action.error,
      };
    case actionTypes.SIGNIN_CLEAR_ERROR:
      return {
        ...state,
        errorsignin: null,
      };
    case actionTypes.SIGNUP_START:
      return {
        ...state,
        loadingSignup: true,
      };
    case actionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        loadingSignup: false,
        signup: true,
        errorSignup: null,
      };
    case actionTypes.SIGNUP_ERROR:
      return {
        ...state,
        loadingSignup: false,
        errorSignup: action.error,
      };
    case actionTypes.SIGNUP_CLEAR_ERROR:
      return {
        ...state,
        loadingSignup: false,
        signup: false,
        errorSignup: null,
      };
    case actionTypes.LOGOUT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        token: null,
        logout: true,
      };
    case actionTypes.LOGOUT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.RECOVERY_PASSWORD_START:
      return {
        ...state,
        loadingRecovery: true,
      };
    case actionTypes.RECOVERY_PASSWORD_SUCCESS:
      return {
        ...state,
        loadingRecovery: false,
        recovery: true,
        errorRecovery: null,
      };
    case actionTypes.RECOVERY_PASSWORD_ERROR:
      return {
        ...state,
        loadingRecovery: false,
        errorRecovery: action.error,
      };
    case actionTypes.RECOVERY_PASSWORD_CLEAR_ERROR:
      return {
        ...state,
        errorRecovery: null,
        recovery: false,
      };
    case actionTypes.RESET_PASSWORD_START:
      return {
        ...state,
        loadingReset: true,
      };
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loadingReset: false,
        reset: true,
      };
    case actionTypes.RESET_PASSWORD_ERROR:
      return {
        ...state,
        loadingReset: false,
        errorReset: action.error,
      };
    case actionTypes.RESET_PASSWORD_CLEAR_ERROR:
      return {
        ...state,
        loadingReset: false,
        errorReset: null,
      };
    case actionTypes.RESET_CHANGE_PASSWORD_START:
      return {
        ...state,
        loadingChangePsw: true,
      };
    case actionTypes.RESET_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loadingChangePsw: false,
        changePsw: true,
      };
    case actionTypes.RESET_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        loadingChangePsw: false,
        errorChangePsw: action.error,
      };
    case actionTypes.RESET_CHANGE_PASSWORD_CLEAR_ERROR:
      return {
        ...state,
        loadingChangePsw: false,
        errorChangePsw: null,
      };
    case actionTypes.SET_REDIRECT:
      return {
        ...state,
        redirect: true,
      };
    case actionTypes.SET_REDIRECT_FALSE:
      return {
        ...state,
        redirect: false,
      };
    case actionTypes.OAUTH_SEND_TOKEN_START:
      return {
        ...state,
        loadingOauthSendToken: true,
        isOauth: true,
      };
    case actionTypes.OAUTH_SEND_TOKEN_SUCCESS:
      return {
        ...state,
        loadingOauthSendToken: false,
        token: action.token,
        isOauth: false,
      };
    case actionTypes.OAUTH_SEND_TOKEN_ERROR:
      return {
        ...state,
        loadingOauthSendToken: false,
        error: action.error,
        isOauth: false,
        errorOauthSendoToken: action.error,
      };
    case actionTypes.CHECK_AUTH_ERROR:
      return {
        ...state,
        loading: false,
        token: null,
        refreshToken: null,
        loadingFirstAccess: false,
      };
    case actionTypes.AUTH_COGNITO_LOADING:
      return {
        ...state,
        loadingCognito: true,
      };
    case actionTypes.AUTH_COGNITO_END:
      return {
        ...state,
        loadingCognito: false,
      };
    default:
      return state;
  }
};

export default authReducer;
