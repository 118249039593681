import React from 'react';
import { Flex, Col, Text } from '../Ui';
import { useEffect } from 'react';
import styled from 'styled-components';
import { checkoutform } from '../../formsConfig/formsConfig';
import useForm from '../../hooks/useForm';
import { Input, Button } from '../Ui';
import { connect, useSelector } from 'react-redux';
import * as actionCreators from '../../store/actions';
import BoxLayout from '../Ui/BoxLayout';
import { useState } from 'react';
import { Modal } from '../Ui';
const ContentForm = styled.div`
  .textBlue {
    font-size: 10px;
    color: #03c2e2;
  }
  .label-contact {
    color: #03c2e2;
    font-size: 10px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const CartForm = ({ cartInfo, userInfo, orderProduct, closed }) => {
  const checkout_form = cartInfo.checkout_form_type;

  const agencyDetails = cartInfo.agency_details;

  const profileForm = checkoutform(userInfo || {});

  const getOrder = () => {
    let editData = {
      firstname: formData.firstname.value,
      lastname: formData.lastname.value,
      address: formData.address.value,
      city_id: formData.city_id.value,
      province_id: formData.province_id.value,
      zipcode: formData.zipcode.value,
      phone: formData.phone.value,
      country: formData.country.value,
      notes: formData.notes.value,
    };
    orderProduct(editData);
  };

  let datePickerNames = [];

  for (var prop in profileForm) {
    if (profileForm[prop].elementType === 'datepicker') {
      datePickerNames.push(profileForm[prop].elementConfig.name);
    }
  }

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    startDate,
    datePickerHandler,
    fileUploadHandler,
    deleteFileHandler,
    imgPreviewUrl,
    districts,
    cities,
    zipcode,
    onSetFormValid,
    resetFormHandler,
  } = useForm(getOrder, profileForm, datePickerNames);
  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }
  let inputs = inputArr.map((inp, indx) => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        startdate={startDate}
        changedDatePicker={datePickerHandler}
        fileUploadHandler={fileUploadHandler}
        deleteFileHandler={deleteFileHandler}
        imagePreviewUrl={imgPreviewUrl}
        inModal={true}
        radioInputs={inp.inputs}
        districts={districts}
        cities={cities}
        zipcode={zipcode}
        onSetFormValid={onSetFormValid}
        resetFormHandler={resetFormHandler}
        {...inp}
      />
    );
  });
  return (
    <Container>
      {checkout_form.physical_prize && checkout_form.agency && (
        <BoxLayout title={'Indirizzo di spedizione'} color='#002D72'>
          <ContentForm>
            <Text className='mb-20' size={18}>
              Spediremo i tuoi premi a questo indirizzo.
            </Text>

            <Flex className='mb-20'>
              <Col width={33} padding='0' className='sm_mb-20'>
                <Text className='textBlue mb-5' upper bold>
                  nome e cognome
                </Text>
                <Text size={12} bold>
                  {userInfo?.firstname} {userInfo?.lastname}
                </Text>
              </Col>
              <Col width={33} padding='0' className='sm_mb-30'>
                <Text className='textBlue mb-5' upper bold>
                  Indirizzo
                </Text>
                <Text size={12} bold>
                  {agencyDetails?.indirizzo}
                </Text>
              </Col>
              <Col width={33} padding='0'>
                <Text className='textBlue mb-5' upper bold>
                  Città
                </Text>
                <Text size={12} bold>
                  {agencyDetails?.citta}
                </Text>
              </Col>
            </Flex>
            <Flex wrap='wrap' className='mb-20'>
              <Col width={33} padding='0' className='sm_mb-30'>
                <Text className='textBlue mb-5' upper bold>
                  Cap
                </Text>
                <Text size={12} bold>
                  {agencyDetails?.cap}
                </Text>
              </Col>
              <Col width={33} padding='0' className='sm_mb-30'>
                <Text className='textBlue mb-5' upper bold>
                  Paese
                </Text>
                <Text size={12} bold>
                  Italia
                </Text>
              </Col>
              <Col width={33} padding='0'>
                <Text className='textBlue mb-5' upper bold>
                  Presso
                </Text>
                <Text size={12} bold>
                  {agencyDetails?.nome}
                </Text>
              </Col>
            </Flex>
          </ContentForm>
        </BoxLayout>
      )}
      {checkout_form.physical_prize && !checkout_form.agency && (
        <BoxLayout title={'Indirizzo di spedizione'} color='#002D72'>
          <ContentForm>
            <Text className='mb-20' size={18}>
              Inserisci i dati per la consegna.
            </Text>
            <Flex className='mb-20'>
              <Col style={{ padding: '0 20px 0 0' }} width={25} className='sm_mb-20'>
                {inputs[0]}
              </Col>
              <Col style={{ padding: '0 20px 0 0' }} width={25}>
                {inputs[1]}
              </Col>
              <Col style={{ padding: '0 20px 0 0' }} width={25}>
                {inputs[4]}
              </Col>
              <Col style={{ padding: '0 20px 0 0' }} width={25}>
                {inputs[3]}
              </Col>
            </Flex>
            <Flex wrap='wrap' className='mb-20 '>
              <Col style={{ padding: '0 20px 0 0' }} width={25}>
                {inputs[5]}
              </Col>
              <Col style={{ padding: '0 20px 0 0' }} width={25} className='sm_mb-30'>
                {inputs[2]}
              </Col>
              <Col style={{ padding: '0 20px 0 0' }} width={25}>
                {inputs[6]}
              </Col>
              <Col style={{ padding: '0 20px 0 0' }} width={25}>
                {inputs[8]}
              </Col>
              <Col style={{ padding: '0 20px 0 0' }} width={25}>
                {inputs[7]}
              </Col>
            </Flex>
          </ContentForm>
        </BoxLayout>
      )}
      {checkout_form.digital_prize && (
        <BoxLayout title={'SPEDIREMO IL TUO VOUCHER TRAMITE EMAIL'} color='#002D72'>
          <ContentForm>
            <Col width={100} padding='0' className='sm_mb-30'>
              <Text className='textBlue mb-5' upper bold>
                Email
              </Text>
              <Text size={12} bold>
                {userInfo?.email}
              </Text>
            </Col>
          </ContentForm>
        </BoxLayout>
      )}
      <Flex wrap='wrap' justify='space-between'>
        <Button
          border
          hoverGrey
          gradient={{ left: '#ADB1BC', right: '#ADB1BC' }}
          upper
          onClick={closed}
        >
          Indietro
        </Button>
        <Button
          active
          gradient={{ left: '#E61274', right: '#FF7DB9' }}
          upper
          onClick={event => {
            checkout_form.physical_prize && !checkout_form.agency
              ? formSubmitHandler(event)
              : orderProduct();
          }}
        >
          Conferma
        </Button>
      </Flex>
    </Container>
  );
};
const mapStateToProps = state => {
  return {
    userLogged: state.user.userLogged,
    userInfo: state.user.user.userinfo,
    loading: state.user.loading,
    updateError: state.user.user.updateError,
    errorOrder: state.user.user.errorOrder,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    orderProduct: data => dispatch(actionCreators.orderProduct(data)),
    getUserIsLogged: () => dispatch(actionCreators.getUserIsLogged()),
    logout: () => dispatch(actionCreators.logout()),
    toggleSidebar: () => dispatch(actionCreators.toggleSidebar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartForm);
