import React, { useState, useEffect } from 'react';
import CardElement from './CardElement';
import { getSlug } from '../../../../utils/utils';
import Dynamic from '../../Dynamics/Dynamic';
import { Flex, Col, Text } from '../../../../components/Ui';
import Fade from '../../../../components/Fade';
import LeftBoxCardHandler from '../../Handlers/CardHandler/LeftBoxCardHandler';
import styled from 'styled-components';
import { respondTo } from '../../../../theme/mixin';

const Card = props => {
  const {
    onShowResult,
    typeInteraction,
    g: {
      id,
      interaction,
      preinteraction,
      log: { sid },
    },
    action: { data: dataAction, loading },
  } = props;

  const [finalCards, setFinalCards] = useState([]);
  const [action, setAction] = useState(false);

  let rules = JSON.parse(interaction.data);

  if (typeInteraction === 'preinteraction') {
    rules = JSON.parse(preinteraction.data);
  }

  let slug = getSlug();

  const {
    settings: {
      numberofcards,
      aspect: { brand, loosecard, wincard },
    },
    i18l: {
      it: { textUp },
    },
  } = rules;

  useEffect(() => {
    let data = [];
    for (let i = 0; i < numberofcards; i++) {
      data.push({
        id: i,
        open: false,
        brand,
        loosecard,
        wincard,
      });
    }
    setFinalCards(data);
  }, []);

  useEffect(() => {
    if (dataAction.length > 0) {
      setTimeout(() => {
        onShowResult();
      }, 1500);
    }
  }, [dataAction]);

  const clickCardHandler = index => {
    finalCards[index].open = true;
    setAction(true);
  };

  return (
    <Fade>
      <Flex row={15} align='stretch'>
        <Container>
          {textUp && (
            <TextParent>
              <Text primary capit bold size={20} margin='0 0 20px 0'>
                {textUp}
              </Text>
            </TextParent>
          )}
          {finalCards.map((card, index) => {
            return (
              <CardElement
                key={card.id}
                index={index}
                brand={card.brand}
                loosecard={card.loosecard}
                wincard={card.wincard}
                isOpen={card.open}
                result={dataAction}
                idGame={id}
                loading={loading}
                clicked={() => clickCardHandler(index)}
              />
            );
          })}
        </Container>
      </Flex>

      {action ? (
        <Dynamic
          id={id}
          slug={slug}
          section={typeInteraction}
          action={props.action}
          sid={sid}
          {...props}
        />
      ) : null}
    </Fade>
  );
};
const TextParent = styled.div`
  text-align: center;
`;
const TextContainer = styled.div`
  background-color: #fff;
  border-radius: 60px;
  display: inline-block;
  font-size: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 22px;
  margin-left: 10px;
  margin-right: 10px;
  ${respondTo.sm`
	font-size: 22px;
	margin-left: 10px;
  `}
`;
const Container = styled.div`
  padding: 30px;
  box-sizing: border-box;
  overflow: auto;
  flex: 1;
  width: 100%;
  min-height: 360px;
  text-align: center;
  ${respondTo.sm`
 min-height: 500px;
  `}
`;
export default Card;
