import React, { forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { respondTo } from '../../../theme/mixin';

const Col = forwardRef((props, ref) => {
  return (
    <ColWrap {...props} ref={ref}>
      {props.children}
    </ColWrap>
  );
});

const ColWrap = styled.div`
  width: 100%;
  padding: 0 ${props => props.padding}px;

  ${respondTo.sm`
    width: ${props => props.width}%;
  `}
`;

Col.defaultProps = {
  width: 100,
  padding: 15,
};

Col.propsTypes = {
  width: PropTypes.number,
  padding: PropTypes.number,
};

export default Col;
