import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Col, Box, Text, Flex } from '../../../Ui';

const LeftBoxCardHandler = () => {
  const game = useSelector(state => state.promo.game);

  return (
    <Col width={33.333}>
      <Box primary fullheight>
        <Flex
          wrap='wrap'
          direction='column'
          justify='space-between'
          align='center'
          style={{ height: '100%' }}
        >
          <div>
            <Text white size={34} className='mb-20'>
              {game[0].i18l.info.title}
            </Text>
            <Text white>{game[0].i18l.info.content}</Text>
          </div>
          {game[0].remaining_attempts !== null && (
            <AttemptsBox>
              <Text bold upper primary size={18} align='center'>
                Hai ancora
              </Text>
              <Text primary light size={70} align='center'>
                {game[0].remaining_attempts}
              </Text>
              <Text bold upper primary size={18} align='center'>
                tentativi di gioco
              </Text>
            </AttemptsBox>
          )}
        </Flex>
      </Box>
    </Col>
  );
};

const AttemptsBox = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  margin-top: 30px;

  span:nth-child(2) {
    line-height: 1;
  }
`;

export default LeftBoxCardHandler;
