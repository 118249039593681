import React from 'react';
import styled from 'styled-components';
import { Text } from '../Ui';

const PrizeBox = ({ prize }) => {
  return (
    <PrizeBoxWrapper>
      <div className='prize-image'>
        <img src={prize.image} alt='' />
      </div>
      <div className='prize-info'>
        <Text white bold size={14} align='center'>
          Ogni giorno
        </Text>
      </div>
      <div className='prize-title'>
        <Text upper bold primary size={18} align='center'>
          {prize.title}
        </Text>
      </div>
    </PrizeBoxWrapper>
  );
};

const PrizeBoxWrapper = styled.div`
  border-radius: 10px;
  box-shadow: 0px 2px 10px #00000026;
  overflow: hidden;
  margin-bottom: 5px;

  .prize-image {
    height: 240px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .prize-info {
    background-color: ${props => props.theme.primary};
    padding: 5px 0;
  }

  .prize-title {
    padding: 15px 0;
    background-color: #fff;
  }
`;

export default PrizeBox;
