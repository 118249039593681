import React from 'react';
import Text from '../Text';
import styled from 'styled-components';
const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;

  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
`;
function Heading({ img, text, ...rest }) {
  return (
    <Container {...rest}>
      {img && <img src={img} />}
      <Text type='descGame' primary={!rest.white} upper white={rest.white}>
        {text}
      </Text>
    </Container>
  );
}

export default Heading;
