import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import { Flex, Text, Button } from '../Ui';
import CouponBox from '../CouponBox/CouponBox';
import Heading from '../Ui/heading';
import imgIcon from '../../assets/images/iconCart.svg';
import { respondTo } from '../../theme/mixin';

const TravelCouponsModule = () => {
  const options = {
    infinite: false,
    dots: false,
    arrows: false,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          dots: false,
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 760,
        settings: {
          dots: false,
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const partners = useSelector(state => state.partners.partnerList);

  return (
    <TravelCouponsWrapper>
      <Heading text='alcuni premi del nostro catalogo' img={imgIcon} />

      <Slider {...options}>
        {partners?.map((partner, partnerIndex) => (
          <CouponBox coupon={partner} key={partnerIndex} />
        ))}
      </Slider>
      <Flex padding='0 15px' justify='space-between' align='flex-end' style={{ marginTop: 'auto' }}>
        <Text size={12} className='tcw-text'>
          It is a long established fact that a reader will be distracted by the readable content of
          a page when looking at its layout. The point of using Lorem Ipsum is that it has a
          more-or-less normal distribution of letters,
        </Text>
        <Button active upper size='14px' hover className='tcw-btn'>
          Scopri di più
        </Button>
      </Flex>
    </TravelCouponsWrapper>
  );
};

const TravelCouponsWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 15px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadow};
  display: flex;
  flex-direction: column;
  ${respondTo.sm`
      width: 60%;
  `}
  .tcw-text {
    width: 100%;
    ${respondTo.sm`
      width: 60%;
  `}
  }
  .tcw-btn {
    margin-top: 15px;
    ${respondTo.sm`
      margin-top: 0;
  `}
  }
  .slick-slider {
  }

  .slider-dots {
    position: absolute;
    bottom: -47px;
    right: 0;
    list-style-type: none;
    display: flex !important;

    li {
      button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 0;
        color: inherit;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: inherit;
        background-color: #f1f3f7;
        width: 35px;
        height: 4px;
        margin-right: 15px;
      }

      &.slick-active button {
        background-color: ${props => props.theme.primary};
      }
    }
  }
`;

const SliderArrow = styled.div`
  position: absolute;
  left: ${props => (props.prev ? '15px' : props.next ? '60px' : null)};
  bottom: -45px;
  border: 2px solid ${props => props.theme.primary};
  color: ${props => props.theme.primary};
  cursor: pointer;
  height: 30px;
  width: 30px;
  display: flex !important;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${props => props.theme.primary};
    color: #fff;
  }
`;

export default TravelCouponsModule;
