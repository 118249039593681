import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Delete } from '@styled-icons/material-twotone';
import { Button, Text } from '../../../Ui';

const ImagePreviewContainer = styled.div`
  overflow: hidden;
  width: 100%;
  background-color: #e4eaef;
  border-radius: 10px;
  position: relative;
  display: ${props => (props.show ? 'inline-block' : 'none')};

  img {
    width: 100%;
    object-fit: cover;
  }
`;

const CustomFile = styled.div`
  display: ${props => (props.show ? 'flex' : 'none')};
  gap: 10px;
`;
const ImagePreview = styled.div`
  display: flex;
  justify-content: center;
`;

const DeleteContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #a3aaae;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UploadButton = styled(Button)`
  display: ${props => (props.show ? 'inline-block' : 'none')};
  border: 2px solid
    ${props =>
      (!props.isvalid && props.istouched && props.firstSubmit) ||
      (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : props.theme.primary};
`;

const File = props => {
  const { setImage, setFormValid, formSetSelectedFiles, resetFormHandler, resetImage, concorso } =
    props;
  const upRef = useRef(null);

  const [imagePreviewUrl2, setImgPreviewUrl2] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const fileUploadHandler2 = event => {
    event.preventDefault();

    const file = event.target.files[0];

    if (file !== undefined) {
      setSelectedFile({
        name: event.target.name,
        file: event.target.files[0],
      });

      setFormValid(event);

      const reader = new FileReader();
      reader.onloadend = () => {
        if (file.type !== 'application/pdf') {
          setImgPreviewUrl2(reader.result);
          setImage(true);
        }
      };
      reader.readAsDataURL(file);
      event.target.value = null;
    } else {
      setImgPreviewUrl2(null);
    }
  };

  const deleteFileHandler2 = () => {
    setImgPreviewUrl2(null);
    setImage(false);
    //resetFormHandler();
    setSelectedFile(null);
  };

  useEffect(() => {
    if (selectedFile) {
      formSetSelectedFiles(selectedFile);
    }
  }, [selectedFile]);

  useEffect(() => {
    if (resetImage) {
      setImgPreviewUrl2(null);
    }
  }, [resetImage]);

  return (
    <>
      <CustomFile show={imagePreviewUrl2}>
        <ImagePreviewContainer show={imagePreviewUrl2} className='customFile-img'>
          <ImagePreview>{imagePreviewUrl2 && <img src={imagePreviewUrl2} alt='' />}</ImagePreview>
          <DeleteContainer onClick={deleteFileHandler2}>
            <Delete size={23} className='customFile-icon' />
          </DeleteContainer>
        </ImagePreviewContainer>
        {concorso && (
          <div className='customFile-info'>
            <div className='mb-20'>
              <Text bold size={10} color='#0A89CB'>
                NOME FILE
              </Text>
              <Text size={12} bold>
                {selectedFile?.file?.name}
              </Text>
            </div>
            <div>
              <Text bold color='#0A89CB' size={10}>
                DIMENSIONE FILE
              </Text>
              <Text bold size={12}>
                {(selectedFile?.file.size * 0.000001).toFixed(2)} Mb
              </Text>
            </div>
          </div>
        )}
      </CustomFile>
      <input
        style={{ display: 'none' }}
        ref={upRef}
        {...props.elementConfig}
        onChange={fileUploadHandler2}
      />
      <UploadButton
        {...props}
        fullwidth
        upper
        active
        hover
        onClick={() => upRef.current.click()}
        show={!imagePreviewUrl2}
        type='button'
      >
        {props.elementConfig.placeholder}
      </UploadButton>
    </>
  );
};

export default File;
