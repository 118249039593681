import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import appReducer from './reducers/app';
import promoReducer from './reducers/promo';
import authReducer from './reducers/auth';
import uiReducer from './reducers/ui';
import userReducer from './reducers/user';
import viewerReducer from './reducers/viewer';
import cmsReducer from './reducers/cms';
import gameReducer from './reducers/game';
import errorReducer from './reducers/error';
import bootAppReducer from './reducers/bootApp';
import partnersReducer from './reducers/partners';
import partnersTurisandaReducer from './reducers/partnersturisanda';
import productsReducer from './reducers/products';
import productReducer from './reducers/product';

const rootReducer = combineReducers({
  app: appReducer,
  promo: promoReducer,
  auth: authReducer,
  ui: uiReducer,
  user: userReducer,
  homeViewer: viewerReducer,
  cms: cmsReducer,
  game: gameReducer,
  error: errorReducer,
  bootApp: bootAppReducer,
  partners: partnersReducer,
  products: productsReducer,
  product: productReducer,
  partnersTurisanda: partnersTurisandaReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
