import React from 'react';
import styled from 'styled-components';
import { Button, Text } from '../../../Ui';
import iconParticipe from '../../../../assets/images/boxWin.png';

import { useDispatch } from 'react-redux';
import { getFormInfo } from '../../../../store/actions/user';
import { useHistory } from 'react-router';
import { ChevronRight } from '@styled-icons/boxicons-regular';
import { respondTo } from '../../../../theme/mixin';
const ResultDynamicWrapper = styled.div`
  position: relative;
  text-align: ${({ click }) => (click ? 'left' : 'center')};
  //margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${({ click }) => (click ? 'stretch' : 'center')};
  height: 100%;
  .btnDesk,
  .btnMob {
    width: fit-content;
    margin-bottom: 15px;
    margin-left: 15px;
  }
  .btnDesk {
    display: none;
    ${respondTo.sm` 
        display: block;
    `};
  }
  .btnMob {
    display: block;
    ${respondTo.sm` 
        display: none;
    `};
  }
  .flex-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .me-2 {
    margin-right: 15px;
  }
`;

const ResultDynamicCalendar = props => {
  const thePrize = props.thePrize;

  const {
    dynamicData: { title = '', content },
    click,
  } = props;

  const titleWin = props.thePrize.title ? props.thePrize.title : '';
  const contentWin = props.thePrize.content ? props.thePrize.content : '';
  const extra = props.thePrize.extra ? props.thePrize.extra : '';

  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <ResultDynamicWrapper click={click}>
      <div>
        {' '}
        <Text primary size={24} upper style={{ ...(click && { padding: '0 15px 15px 15px' }) }}>
          Hai vinto!
        </Text>
        <Text
          size={18}
          style={{
            ...(click && {
              padding: '0 15px 20px 15px',
            }),
          }}
        >
          {titleWin}
        </Text>
        <Text
          size={18}
          style={{
            ...(click && {
              padding: '0 15px 20px 15px',
            }),
          }}
        >
          {contentWin}
        </Text>
      </div>

      <div className=''>
        <Button
          arrowLeft
          hoverGrey
          border
          className='btnMob me-2'
          upper
          onClick={() => props.onDayClicked()}
        >
          Torna indietro
        </Button>
      </div>

      <div>
        <div className='flex-btn'>
          <Button
            arrowLeft
            hoverGrey
            border
            className='btnDesk me-2'
            upper
            onClick={() => props.onDayClicked()}
          >
            Torna indietro
          </Button>
        </div>
      </div>
    </ResultDynamicWrapper>
  );
};

export default ResultDynamicCalendar;
