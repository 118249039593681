import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import NavigationItem from './NavigationItem/NavigationItem';
import { Text } from '../../Ui';
import cartIcon from '../../../assets/images/cart-icon.svg';

import { respondTo } from '../../../theme/mixin';
import { useTranslation } from 'react-i18next';
import Logout from '../../Logout/Logout';
import LinkDownload from './NavigationItem/LinkDownload/LinkDownload';
import { Link } from 'react-router-dom';

const NavUl = styled.ul`
  list-style: none;

  .last-child {
    margin-right: 0;
  }
  .cart-container {
    position: relative;
    display: flex;
    justify-content: center;
    .cart-img {
      background: #002d72;
      width: 29px;
      height: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
    .cart-numb {
      position: absolute;
      top: 0px;
      right: -6px;
      background: #0a89cb;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      span {
        color: white;
        font-size: 7px;
        border: 0;
      }
      @media (max-width: 1024px) {
        top: 20%;
        left: 52%;
        transform: translateX(-50%);
      }
    }
  }

  ${respondTo.md`
    display: flex;
    align-items: center;
    height: 100%;
    box-shadow: none;
	`}

  .only-desktop {
    display: none;

    ${respondTo.md`
      display: block;
    `}
  }

  .only-mobile {
    display: block;

    &.logout {
      border-top: 1px solid #dce4e8;
      padding: 15px 0;
    }

    ${respondTo.md`
      display: none;
    `}
  }
`;

const NavigationItems = props => {
  const { isLogged, multipromo, i18l, idGame, isOpen, logout, webview, refresh_token } = props;
  const show_turisanda = useSelector(state => state.user.user.show_turisanda);
  const [t] = useTranslation();
  const cart = useSelector(state => state.user.user.cart);
  let qty = 0;
  cart.map(ele => (qty += ele?.pivot?.quantity ? ele?.pivot?.quantity : 0));
  return (
    <NavUl isOpen={isOpen} onClick={props.clicked}>
      {isLogged && (
        <>
          {!show_turisanda && (
            <NavigationItem link='/premi' active='true'>
              <Text bold size={12}>
                premi
              </Text>
            </NavigationItem>
          )}
          {show_turisanda && (
            <NavigationItem link='/premi' active='true'>
              <Text bold size={12}>
                PREMI Y&S
              </Text>
            </NavigationItem>
          )}
          {show_turisanda && (
            <NavigationItem link='/turisanda'>
              <Text bold size={12}>
                gift Turisanda
              </Text>
            </NavigationItem>
          )}
          <NavigationItem link='/concorsi' active='true'>
            <Text bold size={12}>
              Concorsi
            </Text>
          </NavigationItem>
          <NavigationItem link='/howitworks' active='true'>
            <Text bold size={12}>
              come funziona
            </Text>
          </NavigationItem>

          <NavigationItem link='/contact/#faq' active='true'>
            <Text bold size={12}>
              {t('header.contact')}
            </Text>
          </NavigationItem>

          <div className='only-mobile'>
            <NavigationItem link={`${process.env.PUBLIC_URL}/pdf/regolamento.pdf`} target='_blank'>
              <Text bold size={12}>
                Regolamento You and Sun
              </Text>
            </NavigationItem>
          </div>

          <div className='only-mobile'>
            <NavigationItem
              link={`${process.env.PUBLIC_URL}/pdf/Regolamento_OP_OLTRE_LA_VACANZA.pdf`}
              target='_blank'
            >
              <Text bold size={12}>
                Regolamento Oltre la vacanza
              </Text>
            </NavigationItem>
          </div>

          <div className='only-desktop'>
            <LinkDownload />
          </div>
          <NavigationItem link='/user/profile' onlyMobile={show_turisanda} active='true'>
            <Text bold size={12}>
              {t('header.profile')}
            </Text>
          </NavigationItem>

          {show_turisanda && (
            <NavigationItem to='/carrello' active='true' className='last-child cart-container'>
              {cart?.length > 0 && (
                <div className='cart-numb'>
                  <span>{qty}</span>
                </div>
              )}
              <div className='cart-img'>
                <img src={cartIcon} alt='' />
              </div>
            </NavigationItem>
          )}

          {!webview && (
            <Link to='/'>
              <div className='only-mobile logout' onClick={() => logout(refresh_token)}>
                <Logout label={t('auth.logoutlabel')} />
              </div>
            </Link>
          )}
        </>
      )}

      {i18l.nav.menu_static_links
        ? i18l.nav.menu_static_links.map((item, i) => {
            return (
              <NavigationItem link={item.url} active='true' key={i}>
                <Text>{item.label}</Text>
              </NavigationItem>
            );
          })
        : null}
    </NavUl>
  );
};

NavigationItems.propTypes = {
  isLogged: PropTypes.string,
};
export default NavigationItems;
