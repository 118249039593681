import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';

function Banner({ children }) {
  return <HowItWorks className='profile'>{children}</HowItWorks>;
}
const HowItWorks = styled.div`
  margin: 0 -15px 20px -15px;
  position: relative;
  .hiw-content {
    min-height: 600px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .hiw-text {
    position: absolute;
    left: 20px;
    top: 100px;
    width: 60%;
    padding-right: 100px;

    ${respondTo.sm` 
      margin-top:0;
      width: 370px;
      margin-right: 35px;
      padding-right:initial;
      left: auto;
      bottom: auto;
      top: 40%;
      right: 0px;
      transform: translateY(-40%);
    `};

    ${respondTo.md` 
      margin-right: 100px;
    `};
  }
  .slider-dots {
    position: absolute;
    bottom: 10px;
    right: 0;
    list-style-type: none;
    display: flex !important;
    ${respondTo.sm` 
      bottom: 80px;
    `};
    li {
      button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 0;
        color: inherit;

        padding: 0;
        cursor: pointer;
        outline: inherit;
        border: 3px solid white;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: transparent;
        margin-right: 15px;
        border-radius: 50%;
      }

      &.slick-active button {
        transition: 1s;
        background-color: ${props => props.theme.primary};
        border-radius: 50px;
        width: 35px;
        border: none;
      }
    }
  }
`;
export default Banner;
