import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const AuthNav = ({ active }) => {
  return (
    <AuthNavTabs>
      <Tab active={active === 'login' ? true : false}>
        <Link to='/auth/login'>
          <span>Accedi</span>
        </Link>
      </Tab>
      <Tab active={active === 'register' ? true : false}>
        <Link to='/auth/register'>
          <span>Registrati</span>
        </Link>
      </Tab>
    </AuthNavTabs>
  );
};

const AuthNavTabs = styled.div`
  display: flex;
  overflow: hidden;
  border-radius: 50px;
  border: 2px solid ${props => props.theme.primary};
`;

const Tab = styled.div`
  width: 50%;

  a {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => (props.active ? props.theme.primary : '#fff')};

    &:hover {
      background-color: ${props => props.theme.primary};
      color: #fff;
    }

    span {
      font-size: 14px;
      font-weight: 700;
      color: ${props => (props.active ? '#fff' : '#DCE4E8')};
    }
  }
`;

Tab.propTypes = {
  active: PropTypes.bool,
};

AuthNav.propTypes = {
  active: PropTypes.string,
};

export default AuthNav;
