import React from 'react';
import styled from 'styled-components';
import { Flex, Col, Text, Button, Box, Input } from '../../../Ui';

import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import { useState } from 'react';

import { respondTo } from '../../../../theme/mixin';
import { concorsoForm } from '../../../../formsConfig/formsConfig';
import useForm from '../../../../hooks/useForm';
import Fade from '../../../Fade';

const ConcorsiGame = () => {
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [immagine, setImmagine] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [resetImage, setResetImage] = useState(false);
  const [info, setInfo] = useState('');
  const dispatch = useDispatch();

  const dataForm = concorsoForm();

  const setImage = value => {
    setImmagine(value);
    if (!value) {
      setResetImage(true);
    }
  };
  const formSetSelectedFiles = file => {
    setSelectedFiles(prevState => {
      return [...prevState, file];
    });
  };

  const closeModal = () => {
    setInfo('');
    setShowModal(false);
  };

  let datePickerNames = [];

  for (var prop in dataForm) {
    if (dataForm[prop].elementType === 'datepicker') {
      datePickerNames.push(dataForm[prop].elementConfig.name);
    }
  }

  const submitHandler = () => {
    /*   setIsLoading(true);
    const dataToSend = {};
    for (const property in formData) {
      dataToSend[property] =
        property !== 'date' ? formData[property].value : formData[property].valuetosend;
    }

    var data = new FormData();

    let fileArray = [];
    let fileExtraArray = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].name === 'file') {
        fileArray.push(selectedFiles[i]);
      } else if (selectedFiles[i].name === 'fileextra') {
        fileExtraArray.push(selectedFiles[i]);
      }
    }

    fileArray.forEach(function (element) {
      if (fileArray[fileArray.length - 1] === element) {
        data.append(element.name, element.file);
      }
    });
    fileExtraArray.forEach(function (element) {
      if (fileExtraArray[fileExtraArray.length - 1] === element) {
        data.append(element.name, element.file);
      }
    });

    dataToSend.type = 'receipt';

    data.append('data', JSON.stringify(dataToSend));
    data.append('sid', sid);
    data.append('section', typeInteraction === 'preinteraction' ? 'preinteraction' : 'interaction');
    setLoadingUploadForm(true);

    customaxios
      .post(`game/${slug}/${id}/data`, data)
      .then(response => {
        setIsLoading(false);
        const { data } = response;
        setLoadingUploadForm(false);
        if (response.status === 200) {
          if (typeInteraction === 'preinteraction') {
            if (sid) {
              if (data.log.sidcheck.collection.status) {
                onShowInteractionHandler();
              } else {
                const {
                  log: {
                    sidcheck: { collection },
                  },
                } = data;
                setCollectDue(collection.collectiondue);
                setCollected(collection.collected);
                deleteFileHandler();
                setResetImage(true);
              }
            } else {
              onShowInteractionHandler();
            }
          } else {
            setAction(true);
          }
        }
      })
      .catch(error => {
        setIsLoading(false);
        const {
          response: {
            status,
            data: { error: message },
          },
        } = error;
        if (status === 400) {
          setTextModal(message);
          setShowModal(true);
          setLoadingUploadForm(false);
        }
      }); */
  };

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    startDate,
    datePickerHandler,
    fileUploadHandler,
    deleteFileHandler,
    selectedFile,
    onSetFormValid,
    imgPreviewUrl,
    districts,
    cities,
    provinces,
    zipcode,
    resetFormHandler,
  } = useForm(submitHandler, dataForm, datePickerNames, false, true);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map(inp => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        readonly={inp.readonly}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        startdate={startDate}
        changedDatePicker={datePickerHandler}
        fileUploadHandler={fileUploadHandler}
        deleteFileHandler={deleteFileHandler}
        imagePreviewUrl={imgPreviewUrl}
        inModal={true}
        radioInputs={inp.inputs}
        setImage={setImage}
        setFormValid={onSetFormValid}
        formSetSelectedFiles={formSetSelectedFiles}
        resetFormHandler={resetFormHandler}
        districts={districts}
        concorso
        {...inp}
      />
    );
  });

  return (
    <>
      <div>
        <Text primary className='mb-10'>
          1. CARICA LA FOTO
        </Text>
        <Flex row={15}>
          <Col width={50}>{inputs[0]}</Col>
          {/*  <Col width={50}>
            <Button border hoverGrey fullwidth style={{ justifyContent: 'center' }}>
              SCATTA
            </Button>
          </Col> */}
        </Flex>
      </div>
      <div>
        <Text primary className='mt-20'>
          2. SCEGLI UNA LOCALITÀ E LA DATA
        </Text>
        <Flex align='end' row={15}>
          <Col width={50}>{inputs[1]}</Col>
          <Col width={50}>{inputs[2]}</Col>
        </Flex>
      </div>
      <hr style={{ borderColor: '#aaaaa314', margin: '0 -15px' }} />
      <Flex justify='flex-end' align='center' style={{ marginTop: '20px' }}>
        <Link to='/contact' style={{ width: '47%', display: 'block' }}>
          <Button active hover padding='0 50px' fullwidth style={{ justifyContent: 'center' }}>
            Invia
          </Button>
        </Link>
      </Flex>
    </>
  );
};

const DownloadRegulation = styled.div`
  height: 230px;
  position: relative;
  margin-top: 30px;
  padding: 15px;

  & > div {
    position: relative;
    z-index: 1;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default ConcorsiGame;
