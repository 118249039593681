//gestore di dinamica e response dell'interazione scratch and win
//posso creare uno stato interno che prende i codici per esempio o result iw
//oppure prenderli da redux
//es: getcode lift up the resul code qui e lo salvo in questo stato interno per poi passarlo al response... per esempio response modal o default

import React, { useState, useEffect } from 'react';
import { Box } from '../../../Ui';
import { ScratchAndWin } from '../../Interactions';
import PreInteraction from '../../PreInteraction';
import ResultGame from '../../Response/ResultGame';
const ScratchAndWinHandler = props => {
  const {
    g: {
      id,
      interaction,
      preinteraction,
      log: { steps },
    },
    played,
    action: { data, loading, error },
  } = props;

  let showPreInteraction = false;

  if (preinteraction !== null) {
    showPreInteraction = true;
  }

  const [result, setResult] = useState(null);
  const [loadingDyn, setLoadingDyn] = useState(false);
  const [attempts, setAttempts] = useState(4);
  const [showSw, setShowSw] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showInteraction, setShowInteraction] = useState(!showPreInteraction); // true or false in base al fatto che ci sia la prop preinteraction

  const onShowResult = () => setShowResult(true);

  const onShowInteractionHandler = () => {
    setShowInteraction(true);
  };

  const onSetResult = value => {
    setResult(value);
  };
  const onSetLoadingDyn = () => {
    setLoadingDyn(true);
  };

  const onSetAttempts = () => {
    setAttempts(prevState => prevState - 1);
  };

  useEffect(() => {
    setShowSw(true);
  }, []);

  useEffect(() => {
    if (attempts === -1) {
      setTimeout(() => {
        onShowResult();
      }, 1000);
    }
  }, [attempts]);

  let content = null;
  content = (
    <Box>
      {showResult || played !== null ? (
        <ResultGame {...props} />
      ) : !showInteraction && steps.preinteraction.data === null ? (
        <PreInteraction
          preinteraction={preinteraction}
          onShowInteractionHandler={onShowInteractionHandler}
          {...props}
        />
      ) : (
        <ScratchAndWin
          result={result}
          onSetResult={onSetResult}
          onSetLoadingDyn={onSetLoadingDyn}
          onSetAttempts={onSetAttempts}
          attempts={attempts}
          showSw={showSw}
          onShowResult={onShowResult}
          typeInteraction='interaction'
          {...props}
        />
      )}
    </Box>
  );
  return content;
};

export default ScratchAndWinHandler;
