import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MechanicGame, MechanicGoToGame, MechanicBox } from './Mechanics';
import Form from './Form';
import { connect } from 'react-redux';

const RouteGames = props => {
  const {
    g: {
      id,
      ingame,
      interaction: { page },
      form,
      log,
    },
    lang,
    played,
    checkFormCompiled,
  } = props;

  let content = null;

  const [formResponsed, setFormResponsed] = useState(
    checkFormCompiled || (log && log.form) ? true : false,
  );

  useEffect(() => {
    if (checkFormCompiled) {
      setFormResponsed(checkFormCompiled);
    }
  }, [checkFormCompiled]);

  if (ingame) {
    if (form && form.type === 'pre' && !formResponsed) {
      content = <Form form={form} formResponse={log.form} lang={lang} id={id} played={played} />;
    } else {
      content = <MechanicGame {...props} />;
    }
  } else {
    if (page) {
      content = <MechanicGoToGame {...props} />;
    } else {
      content = <MechanicBox {...props} />;
    }
  }

  return <>{content}</>;
};

const mapStateToProps = state => {
  return {
    checkFormCompiled: state.game.checkFormCompiled,
  };
};

RouteGames.propTypes = {
  withpage: PropTypes.bool,
  ingame: PropTypes.bool,
};

export default connect(mapStateToProps)(RouteGames);
