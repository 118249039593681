import React, { useState, useEffect } from 'react';
import { respondTo } from '../../theme/mixin';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Button, Col, Box, Flex, Text } from '../../components/Ui';
import BoxLayout from '../../components/Ui/BoxLayout';
import { getElement } from '../../utils/utils';
import { useSelector } from 'react-redux';
import ResultDynamicCalendar from '../../components/Games/Response/ResultDynamicCalendar';
import bgLoose from './../../assets/images/bgLoose.png';

const CustomFlex = styled(Flex)`
  margin-bottom: 30px;
  background-color: transparent;
  z-index: 5;
  position: relative;
  .qui {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
  .card-image .section-heading {
    display: none;
  }
  .loose {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    button {
      width: max-content;
      align-self: flex-end;
      margin-right: 16px;
      margin-bottom: 16px;
    }
  }
  .image-result {
    height: 100%;
    object-fit: cover;
  }
  ${respondTo.sm` 
      margin-top: -60px;
    `};
`;
const CustomCol = styled(Col)`
  .section-heading {
    margin: 15px 0 0 15px;
  }
`;
const SingleDayViewResult = (props, resultDynamicData) => {
  const dayClicked = props.dayClicked;

  const history = useHistory();
  const {
    g: {
      id,
      interaction: { data: rules },
      log,
      check: { attemptslimit },
      log: { steps, won, code, error, form: formResponse, playslog: playslog_id, formend },
      i18l,
      dynamic: { type },
      form,
    },
    lang,
    played,
    action,
    onDayClicked,
    action: { cleanData },
    click,
  } = props;
  const [isFormOpen, setIsFormOpen] = useState(false);
  useEffect(() => {
    // window.scrollTo(0, 0);
    return () => {
      cleanData(id);
    };
  }, []);
  let userWon = dayClicked?.won;
  let prizeContent = null;
  if (dayClicked) {
    prizeContent = {
      title: dayClicked.prize_title,
      content: dayClicked.prize_content,
      extra: '',
    };
  }
  let thePrize = dayClicked ? prizeContent : log.prize;
  let rulesData = JSON.parse(rules);
  let theData = {};
  let theProfileData = action.data ? getElement(action.data, id) : {};
  let correctResponse = action.correctAnsw;
  let errorNoprize = action.errorNoprize;
  let correct_answ = null;
  let playsLogId = dayClicked?.playslog_id;
  let prizeImage = dayClicked?.prize_image;
  if (props.resultPageDynamic) {
    if (played) {
      theData = steps.interaction.data;
    } else {
      correct_answ = correctResponse;

      theData = theProfileData.data ? theProfileData.data : null;
    }
    let theCodeData = {};

    if (theProfileData) {
      theCodeData = {
        code: theProfileData.code,
        error: theProfileData.error,
      };
    } else {
      theCodeData = { code: code, error: error };
    }

    userWon = theProfileData ? theProfileData.won : won;
    thePrize = theProfileData ? theProfileData.prize : log.prize;
    prizeImage = theProfileData?.prize_image;
    playsLogId = theProfileData?.playslog;
  }
  let resultDynamic = null;
  if (attemptslimit && type === 'noprize') {
    resultDynamic = 'info';
  } else if (userWon) {
    resultDynamic = 'win';
  } else {
    resultDynamic = 'loose';
  }
  let dynamicData = i18l[resultDynamic];

  return (
    <CustomFlex align='stretch' justify='center'>
      <Col width={50} className='hiwBoxMobile'>
        <BoxLayout
          title={''}
          noMinHeight
          className='card-image'
          style={{ height: '100%', position: 'relative', padding: 0 }}
        >
          {!userWon ? (
            <img src={bgLoose} className='image-result' alt='' />
          ) : (
            <img src={prizeImage} className='image-result' alt='' />
          )}
        </BoxLayout>
      </Col>

      <CustomCol width={50} className='sm_mt-15 col2'>
        <BoxLayout
          title={''}
          noMinHeight
          style={{ height: '100%', position: 'relative', padding: 0 }}
        >
          {userWon ? (
            <ResultDynamicCalendar
              dynamicData={dynamicData}
              thePrize={thePrize || {}}
              playslog_id={playsLogId}
              id_game={id}
              onDayClicked={props.onGetGame ? props.onGetGame : props.onDayClicked}
              click
              attemptslimit={attemptslimit}
            />
          ) : (
            <div className='loose'>
              <div>
                <Text looseCalendar size={24} bold style={{ ...(click && { padding: '0 15px ' }) }}>
                  {i18l?.loose?.title}
                </Text>
                <Text size={16} style={{ ...(click && { padding: '0 15px 15px 15px' }) }}>
                  {i18l?.loose?.content}
                </Text>
              </div>

              <Button
                arrowLeft
                hoverGrey
                border
                className='col2-btn'
                upper
                onClick={() => {
                  props.onGetGame ? props.onGetGame() : props.onDayClicked();
                }}
              >
                Torna indietro
              </Button>
            </div>
          )}
        </BoxLayout>
      </CustomCol>
    </CustomFlex>
  );
};
export default SingleDayViewResult;
