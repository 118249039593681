import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { MdArrowForwardIos, MdOutlineArrowBackIosNew } from 'react-icons/md';

const ArrowNext = styled.button`
  width: auto;
  height: auto;
  right: -20px;
  z-index: 1;
  &:before {
    content: '';
  }
  svg {
    color: ${({ theme }) => theme.primary};
    font-size: 50px;
  }
`;
const ArrowPrev = styled(ArrowNext)`
  right: auto;
  left: -20px;
`;
function SampleNextArrow(props) {
  return (
    <ArrowNext {...props}>
      <MdArrowForwardIos />
    </ArrowNext>
  );
}

function SamplePrevArrow(props) {
  return (
    <ArrowPrev {...props}>
      <MdOutlineArrowBackIosNew />
    </ArrowPrev>
  );
}
function CustomSlider({ children, settings }) {
  const options = {
    dots: false,
    infinite: true,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    ...settings,
  };
  return <Slider {...options}>{children}</Slider>;
}

export default CustomSlider;
