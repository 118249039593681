import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import { Text } from '../Ui';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@styled-icons/material';
import CouponBox from '../CouponBox/CouponBox';

const ShowcaseTravelModule = () => {
  const options = {
    infinite: false,
    dots: true,
    dotsClass: 'slider-dots',
    prevArrow: (
      <SliderArrow prev>
        <KeyboardArrowLeft size={22} />
      </SliderArrow>
    ),
    nextArrow: (
      <SliderArrow next>
        <KeyboardArrowRight size={22} />
      </SliderArrow>
    ),
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const partners = useSelector(state => state.partners.partnerList);

  return (
    <ShowcaseTravelWrapper>
      <Text light size={48} className='mb-10'>
        Vetrina viaggi
      </Text>

      <Slider {...options}>
        {partners?.map((partner, partnerIndex) => (
          <CouponBox coupon={partner} key={partnerIndex} />
        ))}
      </Slider>
    </ShowcaseTravelWrapper>
  );
};

const ShowcaseTravelWrapper = styled.div`
  position: relative;
  padding-bottom: 45px;
  margin-bottom: 30px;

  .slick-slider {
    margin: 0 -15px;
  }

  .slider-dots {
    position: absolute;
    bottom: -47px;
    right: 0;
    list-style-type: none;
    display: flex !important;

    li {
      button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 0;
        color: inherit;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: inherit;
        background-color: #f1f3f7;
        width: 35px;
        height: 4px;
        margin-right: 15px;
      }

      &.slick-active button {
        background-color: ${props => props.theme.primary};
      }
    }
  }
`;

const SliderArrow = styled.div`
  position: absolute;
  left: ${props => (props.prev ? '15px' : props.next ? '60px' : null)};
  bottom: -45px;
  border: 2px solid ${props => props.theme.primary};
  color: ${props => props.theme.primary};
  cursor: pointer;
  height: 30px;
  width: 30px;
  display: flex !important;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${props => props.theme.primary};
    color: #fff;
  }
`;

export default ShowcaseTravelModule;
