import React from 'react';
import { useTranslation } from 'react-i18next';

const Twitter = (props) => {
    
    const [t] = useTranslation();
    const {

    } = props;

    const handlShare = () => {
        alert('twiiter');
    } 

    return (
        <div onClick={handlShare}>
            {t('app.share')}
        </div>
    );
}

export default Twitter;