import moment from 'moment';
import { generateDays, generateYears } from '../utils/utils';
import { useSelector } from 'react-redux';

export const signinform = (ref, inModal) => {
  return {
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Username',
        ref,
      },
      label: 'Email',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal,
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: 'Password',
      },
      label: 'Password',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal,
    },
  };
};
export const filtersform = max_points => {
  return {
    search: {
      elementType: 'input',
      elementConfig: {
        type: 'text',

        name: 'search',
        id: 'search',
        placeholder: 'Cerca tra i prodotti',
      },
      label: 'PRODOTTI',
      value: '',
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
    category: {
      elementType: 'select',
      isCategories: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: `Seleziona una categoria`,
            disabled: true,
          },
        ],
        name: 'category',
      },
      label: 'CATEGORIE',
      value: '',
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
      isStore: true,
    },
    rangeFrom: {
      elementType: 'inputrange',
      elementConfig: {
        name: 'rangeFrom',
        id: 'rangeFrom',
        options: [
          {
            value: '',
            displayValue: `DA`,
          },
          {
            value: '2500',
            displayValue: `2500`,
          },
          {
            value: '5000',
            displayValue: `5000`,
          },
          {
            value: '10000',
            displayValue: `10000`,
          },
          {
            value: '15000',
            displayValue: `15000`,
          },
          {
            value: '20000',
            displayValue: `20000`,
          },
        ],
      },
      isStore: true,
      label: 'SELEZIONA UNA FASCIA DI PUNTI',
      value: '',
      maxValue: max_points,
      validation: {
        required: false,
        touched: false,
      },

      isRange: true,
      valid: true,
    },
    rangeTo: {
      elementType: 'inputrange',
      elementConfig: {
        name: 'rangeTo',
        id: 'rangeTo',
        options: [
          {
            value: '',
            displayValue: `A`,
          },
          {
            value: '2500',
            displayValue: `2500`,
          },
          {
            value: '5000',
            displayValue: `5000`,
          },
          {
            value: '10000',
            displayValue: `10000`,
          },
          {
            value: '15000',
            displayValue: `15000`,
          },
          {
            value: '20000',
            displayValue: `20000`,
          },
        ],
      },
      isStore: true,
      label: '',
      value: '',
      maxValue: max_points,

      validation: {
        required: false,
        touched: false,
      },

      isRange: true,
      valid: true,
    },
    requestable_only: {
      elementType: 'inputcheckboxslide',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'requestable_only',
        id: 'requestable_only',
      },
      label: 'Visualizza solo premi richiedibili',
      value: 0,
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
  };
};
export const registrationform = (
  email,
  firstname,
  lastname,
  username,
  psw,
  pswrepeat,
  dateofbirth,
  sex,
  male,
  female,
  pswerror,
  pswconfirmerror,
  ref,
  gdpr,
  gdprAccept,
  gdprReject,
) => {
  return {
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Email',
      },
      label: `${email}`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: 'Password',
      },
      label: `${psw}`,
      value: '',
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswerror}`,
      valid: false,
    },
    repeatpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'repeatpassword',
        id: 'repeatpassword',
        placeholder: 'Conferma password',
      },
      label: `${pswrepeat}`,
      value: '',
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswconfirmerror}`,
      valid: false,
    },
    firstname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'firstname',
        id: 'firstname',
        placeholder: 'Nome',
      },
      label: `${firstname}`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    lastname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'lastname',
        id: 'lastname',
        placeholder: 'Cognome',
      },
      label: `${lastname}`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    username: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'username',
        id: 'username',
        placeholder: '',
      },
      label: `${username}`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    address: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'address',
        id: 'address',
        placeholder: 'Indirizzo',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    phone: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        name: 'phone',
        id: 'phone',
        placeholder: 'Cellulare',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    dob: {
      elementType: 'datepicker',
      elementConfig: {
        placeholder: 'Data di nascita',
        name: 'dob',
      },
      label: `${dateofbirth}`,
      value: '',
      valuetosend: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    gender: {
      elementType: 'select',
      elementConfig: {
        options: [
          { value: 'm', displayValue: `${male}` },
          { value: 'f', displayValue: `${female}` },
        ],
        placeholder: '',
        name: 'gender',
      },
      label: `${sex}`,
      value: ``,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    district: {
      elementType: 'select',
      isDistrict: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Seleziona provincia',
            disabled: true,
          },
        ],
        placeholder: 'Provincia',
        name: 'district',
      },
      label: 'Provincia',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    city: {
      elementType: 'select',
      isCity: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Seleziona città',
            disabled: true,
          },
        ],
        placeholder: 'Città',
        name: 'city',
      },
      label: 'Città',
      value: ``,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    zipcode: {
      elementType: 'input',
      isZipcode: true,
      elementConfig: {
        type: 'text',
        name: 'zipcode',
        id: 'zipcode',
        placeholder: 'CAP',
      },
      label: `zipcode`,
      value: '',
      validation: {
        required: true,
        touched: false,
        maxLength: 5,
        minLength: 4,
      },
      valid: false,
      errorMessage: `Il cap deve essere massimo di 5 cifre`,
    },
    regulation: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'regulation',
        id: 'regulation',
      },
      label: '<p>Dichiaro di aver letto e accettato il <a href="">regolamento</a></p>',
      value: 0,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    gdpr_privacy: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'gdpr_privacy',
        id: 'gdpr_privacy',
      },
      label:
        '<p>Dichiaro di aver letto e accettato l\'<a href="">informativa sulla privacy</a></p>',
      value: 0,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    gdpr_marketing: {
      elementType: 'inputradio',
      elementConfig: {
        type: 'radio',
        placeholder: '',
        name: 'gdpr_marketing',
        id: 'gdpr_marketing',
      },
      label: `${gdpr.gdpr_marketing.label}`,
      value: null,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inputs: [
        {
          id: 'gdpr_marketing_accept',
          label: `${gdprAccept}`,
          value: 1,
        },
        {
          id: 'gdpr_marketing_reject',
          label: `${gdprReject}`,
          value: 0,
        },
      ],
    },
    gdpr_profiling: {
      elementType: 'inputradio',
      elementConfig: {
        type: 'radio',
        placeholder: '',
        name: 'gdpr_profiling',
        id: 'gdpr_profiling',
      },
      label: `${gdpr.gdpr_profiling.label}`,
      value: null,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inputs: [
        {
          id: 'gdpr_profiling_accept',
          label: `${gdprAccept}`,
          value: 1,
        },
        {
          id: 'gdpr_profiling_reject',
          label: `${gdprReject}`,
          value: 0,
        },
      ],
    },
  };
};

export const recoveryPasswordForm = (emailLabel, ref) => {
  return {
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Email',
        ref,
      },
      label: `${emailLabel}`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const changePasswordForm = (
  newPswLabel,
  confirmNewPsw,
  pswerror,
  pswconfirmerror,
  pswwrong,
  ref,
) => {
  return {
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: 'Password',
        ref,
      },
      value: '',
      validation: {
        isPassword: true,
        required: true,
        touched: false,
      },
      valid: false,
    },
    confirmpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'confirmpassword',
        id: 'confirmpassword',
        placeholder: `${newPswLabel}`,
        ref,
      },
      value: '',
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswerror}`,
      valid: false,
    },
    repeatpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'repeatpassword',
        id: 'repeatpassword',
        placeholder: `${confirmNewPsw}`,
      },
      value: '',
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswconfirmerror}`,
      valid: false,
    },
  };
};

export const resetPasswordForm = (newPswLabel, confirmNewPsw, pswerror, pswconfirmerror, ref) => {
  return {
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: '',
        ref,
      },
      label: `${newPswLabel}`,
      value: '',
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswerror}`,
      valid: false,
    },
    confirmpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'confirmpassword',
        id: 'confirmpassword',
        placeholder: '',
      },
      label: `${confirmNewPsw}`,
      value: '',
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswconfirmerror}`,
      valid: false,
    },
  };
};

export const uploadReceiptForm = (dateLabel, numberLabel, amountLabel, timeLabel, ref) => {
  return {
    file: {
      elementType: 'inputfile',
      elementConfig: {
        type: 'file',
        accept: 'image/*',
        placeholder: 'Carica',
        name: 'file',
        id: 'file',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal: true,
      ref,
    },
    /* fileextra: {
      elementType: 'inputfile',
      elementConfig: {
        type: 'file',
        accept: 'image/*',
        placeholder: 'Carica il retro dello scontrino',
        name: 'fileextra',
        id: 'fileextra',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal: true,
    }, */
    date: {
      elementType: 'datepicker',
      elementConfig: {
        placeholder: `${dateLabel}`,
        name: 'date',
      },
      label: '',
      value: '',
      valuetosend: '',
      validation: {
        required: true,
        touched: false,
        maxDate: 'today', //mm-dd-yyyy or today
      },
      valid: false,
    },
    time: {
      elementType: 'inputtime',
      elementConfig: {
        type: 'time',
        name: 'time',
        id: 'time',
        placeholder: `${timeLabel}`,
      },
      label: '',
      value: '',
      valuetosend: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    number: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        name: 'number',
        id: 'number',
        placeholder: `${numberLabel}`,
      },
      label: '',
      value: '',
      min: '0',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    amount: {
      elementType: 'price',
      elementConfig: {
        type: 'number',
        name: 'amount',
        id: 'amount',
        placeholder: `${amountLabel}`,
      },
      label: '',
      value: '',
      min: '0',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const contactForm = (show_turisanda, orders_turisanda) => {
  let optionsContact = [
    { value: '', displayValue: `Seleziona`, selected: true },
    { value: 'Punti You and Sun', displayValue: 'Punti You and Sun' },
    { value: 'Premi You and Sun', displayValue: 'Premi You and Sun' },
    { value: 'Concorso You and Sun', displayValue: 'Concorso You and Sun' },
  ];
  let orders = [{ value: '', displayValue: `Seleziona`, selected: true }];

  if (show_turisanda) {
    optionsContact = [
      ...optionsContact,
      {
        value: 'Punti Turisanda - Oltre la Vacanza',
        displayValue: 'Punti Turisanda - Oltre la Vacanza',
      },
      {
        value: 'Problema con Ordine',
        displayValue: 'Problema con un ordine o un premio Turisanda - Oltre la Vacanza',
      },
    ];
  }
  if (!show_turisanda) {
    optionsContact = [
      ...optionsContact,
      {
        value: 'Gift Turisanda - Oltre la Vacanza',
        displayValue: 'Gift Turisanda - Oltre la Vacanza',
      },
    ];
  }
  if (orders_turisanda?.length > 0) {
    orders_turisanda.map(ord =>
      orders.push({
        value: `${ord.id}`,
        displayValue: `Ordine n° ${ord.id} del ${moment(ord.created_at).format('DD/MM/YYYY')}`,
      }),
    );
  }
  return {
    topic: {
      elementType: 'select',
      elementConfig: {
        options: optionsContact,
        placeholder: `Seleziona`,
        name: 'topic',
      },
      label: 'Scegli un argomento',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    order_id: {
      elementType: 'select',
      elementConfig: {
        options: orders,
        placeholder: `Seleziona`,
        name: 'order_id',
      },
      label: 'Scegli un ordine',
      value: '',
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },

    message: {
      elementType: 'textarea',
      elementConfig: {
        name: 'message',
        id: 'message',
        placeholder: 'Scrivi',
      },
      label: 'Il tuo messaggio',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    rules: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'rules',
        id: 'rules',
      },
      label: `<p>Dichiaro di aver letto e accettato <a href='${process.env.PUBLIC_URL}/pdf/informativa-form-contatti.pdf' target='_blank' rel='noopener noreferrer'>l'informativa privacy</a>.</p> `,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const profileform = user => {
  return {
    firstname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'firstname',
        id: 'firstname',
        placeholder: 'Nome',
      },
      label: 'Nome',
      value: user.firstname,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.firstname ? true : false,
    },
    lastname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'lastname',
        id: 'lastname',
        placeholder: `Cognome`,
      },
      label: 'Cognome',
      value: user.lastname,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.lastname ? true : false,
    },
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Email',
      },
      label: 'Email',
      value: user.email,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.email ? true : false,
    },
    code: {
      elementType: 'input',
      isCode: true,
      elementConfig: {
        type: 'text',
        name: 'code',
        id: 'code',
        placeholder: 'N° tessera',
      },
      label: 'N° tessera',
      value: user.code || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.code ? true : false,
    },
    address: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'address',
        id: 'address',
        placeholder: 'Indirizzo',
      },
      label: 'Indirizzo',
      value: user.address,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.address ? true : false,
    },
    district: {
      elementType: 'select',
      isDistrict: true,
      elementConfig: {
        options: [{ value: '', displayValue: 'Provincia', disabled: true }],
        placeholder: 'Provincia',
        name: 'district',
      },
      label: 'Provincia',
      value: user.district || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.district ? true : false,
    },
    city: {
      elementType: 'select',
      isCity: true,
      elementConfig: {
        options: [
          {
            value: user.city || '',
            displayValue: user.city || 'Città',
            disabled: true,
          },
        ],
        name: 'city',
      },
      label: 'Città',
      value: user.city || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.city ? true : false,
    },
    zipcode: {
      elementType: 'input',
      isZipcode: true,
      elementConfig: {
        type: 'text',
        name: 'zipcode',
        id: 'zipcode',
        placeholder: 'CAP',
      },
      label: 'CAP',
      value: user.zipcode || '',
      validation: {
        required: true,
        touched: false,
        maxLength: 5,
        minLength: 4,
      },
      valid: user.zipcode ? true : false,
      errorMessage: `Il cap deve essere massimo di 5 cifre`,
    },
    phone: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        name: 'phone',
        id: 'phone',
        placeholder: 'Cellulare',
      },
      label: 'Cellulare',
      value: user.phone,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.phone ? true : false,
    },
    dob: {
      elementType: 'datepicker',
      elementConfig: {
        placeholder: 'Data di nascita',
        name: 'dob',
      },
      lang: 'it',
      label: 'Data di nascita',
      value: moment(user.dob).format('DD/MM/YYYY') || '',
      valuetosend: moment(user.dob).format('L') || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.dob ? true : false,
    },
  };
};
export const checkoutform = user => {
  return {
    firstname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'firstname',
        id: 'firstname',
        placeholder: 'Nome',
      },
      label: 'Nome',
      value: user.firstname,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.firstname ? true : false,
    },
    lastname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'lastname',
        id: 'lastname',
        placeholder: `Cognome`,
      },
      label: 'Cognome',
      value: user.lastname,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.lastname ? true : false,
    },
    address: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'address',
        id: 'address',
        placeholder: 'Indirizzo',
      },
      label: 'Indirizzo',
      value: user.address,
      validation: {
        required: true,
        touched: false,
      },
      valid: user.address ? true : false,
    },
    city_id: {
      elementType: 'select',
      isCity2: true,
      elementConfig: {
        options: [
          {
            value: user.city_id || '',
            displayValue: user.city_id || 'Città',
            disabled: true,
          },
        ],
        name: 'city_id',
      },
      label: 'Città',
      value: user.city || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.city ? true : false,
    },
    province_id: {
      elementType: 'select',
      isDistrict2: true,
      elementConfig: {
        options: [{ value: '', displayValue: 'Provincia', disabled: true, dataName: '' }],
        placeholder: 'Provincia',
        name: 'province_id',
      },
      label: 'Provincia',
      value: user.province_id || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.province_id ? true : false,
    },
    zipcode: {
      elementType: 'input',
      isZipcode: true,
      elementConfig: {
        type: 'text',
        name: 'zipcode',
        id: 'zipcode',
        placeholder: 'CAP',
      },
      label: 'CAP',
      value: user.zipcode || '',
      validation: {
        required: true,
        touched: false,
        maxLength: 5,
        minLength: 4,
      },
      valid: user.zipcode ? true : false,
      errorMessage: `Il cap deve essere massimo di 5 cifre`,
    },
    country: {
      elementType: 'input',
      isZipcode: true,
      elementConfig: {
        type: 'text',
        name: 'country',
        id: 'country',
        placeholder: 'Paese',
      },
      label: 'Paese',
      value: 'Italia',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.country ? true : false,
    },
    notes: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'notes',
        id: 'notes',
        placeholder: 'Note',
      },
      label: 'Note',
      value: '',
      validation: {
        required: false,
        touched: false,
        maxLength: 250,
      },
      valid: true,
    },
    phone: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'phone',
        id: 'phone',
        placeholder: 'Recapito Telefonico',
      },
      label: 'Recapito Telefonico',
      value: user.phone || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: user.phone ? true : false,
    },
  };
};
export const firstAccessForm = () => {
  return {
    rules: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'rules',
        id: 'rules',
      },
      label: `<span>Ho letto e accetto il  <a href='${process.env.PUBLIC_URL}/pdf/regolamento.pdf' target='_blank' rel='noopener noreferrer' >Regolamento</a></span>`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    invited_by: {
      elementType: 'input',
      isInvited_by: true,
      elementConfig: {
        type: 'text',
        name: 'invited_by',
        id: 'invited_by',
        placeholder: 'Codice invito (opzionale)',
      },
      label: '',
      value: '',
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
    day: {
      elementType: 'select',
      elementConfig: {
        options: [{ value: '', displayValue: `GG`, disabled: false }, ...generateDays(31)],
        placeholder: 'GG',
        name: 'day',
      },
      label: 'Data di nascita',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    month: {
      elementType: 'select',
      elementConfig: {
        options: [{ value: '', displayValue: `MM`, disabled: false }, ...generateDays(12)],
        placeholder: 'MM',
        name: 'month',
      },
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    year: {
      elementType: 'select',
      elementConfig: {
        options: [{ value: '', displayValue: `YYYY`, disabled: false }, ...generateYears(2022)],
        placeholder: 'GG',
        name: 'year',
      },
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    country: {
      elementType: 'select',
      isCountries: true,
      elementConfig: {
        options: [{ value: '', displayValue: `Seleziona`, disabled: false }],
        placeholder: '',
        name: 'country',
      },
      label: `Nazione di residenza`,
      value: ``,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const concorsoForm = ({ maxDate, pdf }) => {
  return {
    file: {
      elementType: 'inputfile',
      elementConfig: {
        type: 'file',
        accept: 'image/*',
        placeholder: 'Carica',
        name: 'file',
        id: 'file',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal: true,
    },
    photo_title: {
      elementType: 'input',
      isInvited_by: true,
      elementConfig: {
        type: 'text',
        name: 'photo_title',
        id: 'photo_title',
        placeholder: '',
      },
      label: 'TITOLO FOTO',
      value: '',
      validation: {
        required: false,
        touched: false,
        maxLength: 150,
      },
      valid: false,
    },
    photo_date: {
      elementType: 'datepicker',
      elementConfig: {
        placeholder: '',
        name: 'photo_date',
      },
      label: 'INSERISCI LA DATA DEL MATRIMONIO',
      value: '',
      valuetosend: '',
      validation: {
        required: true,
        touched: false,
        minDate: new Date('07/01/2024'),
        maxDate: new Date('11/30/2024'), //mm-dd-yyyy or today
      },
      valid: false,
    },
    store: {
      elementType: 'select',
      isStore: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: '',
            disabled: true,
          },
        ],
        placeholder: 'Seleziona un atelier',
        name: 'store',
      },
      label: 'SELEZIONA UN ATELIER',
      value: '',

      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    rules: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'rules',
        id: 'rules',
      },
      label: `<span>Dichiaro di avere in programma di sposarmi tra il 01/07/2024 ed entro il 30/11/2024 
      (compresi)</span>`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    privacy: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'privacy',
        id: 'privacy',
      },
      label: `<span>Dichiaro di aver letto e accettato il <a href='${process.env.PUBLIC_URL}/pdf/Regolamento_Mettiti_in_Sposa.pdf' target='_blank'>regolamento</a> </span>`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};
