import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Section, Typography } from '../../../Ui';
import { Row, Col } from 'react-awesome-styled-grid';
import Mechanic from './Mechanic';
import styled from 'styled-components';
import { respondTo } from '../../../../theme/mixin';

const TypoContainer = styled.div`
    margin-bottom: 20px;
`;

const Container = styled.div`
    margin-bottom: 20px;
    ${respondTo.sm`
        margin-bottom: 0;
    `}
`;

const MechanicBox = props => {

    const {
        g: {image, i18l: { title, content }}
    } = props;

    // console.log(props)


    return (
        <Section>
            <Wrapper>
                <Row>
                    <Col xs={4} sm={8} md={8} lg={8}>
                        <Container>
                            <TypoContainer>
                                <Typography as="p" type="bscream">{title}</Typography>
                                <Typography as="p" type="bbody">{content}</Typography>
                            </TypoContainer>
                            <Mechanic {...props} />
                        </Container>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4}>
                        <img src={image} />
                    </Col>
                </Row>
            </Wrapper>
        </Section>
    )
}

MechanicBox.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string
}

export default MechanicBox
