import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StepContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const StepNumber = styled.div`
  background-color: ${props => (props.current ? '#002d72' : '#AAAAA3')};
  color: white;
  border-radius: 1.5625rem;

  top: 0;
  right: 0;
  width: 21px;
  height: 21px;
  text-align: center;
  line-height: 21px;
  font-size: 12px;
`;
const StepsSurvey = props => {
  const { current, total } = props;

  const [t] = useTranslation();

  const numbers = [];
  for (let i = 1; i < total + 1; i++) {
    numbers.push(i);
  }

  return (
    <StepContainer>
      {numbers.map((number, index) => (
        <StepNumber current={current === number ? true : false} key={index}>
          {number}
        </StepNumber>
      ))}
    </StepContainer>
  );
};
export default StepsSurvey;
