import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import faviconImage from '../../assets/images/favicon.png';

const Favicon = props => {
  const { favicon } = props;

  return (
    <Helmet>
      <link rel='shortcut icon' href={faviconImage} />
    </Helmet>
  );
};

Favicon.propTypes = {
  favicon: PropTypes.string,
};

export default Favicon;
