import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Flex, Col, Text, Button, Wrapper } from '../../components/Ui';
import Image from '../../assets/images/meccanica-image.png';
import Fade from '../../components/Fade/Fade';
import { useSelector } from 'react-redux';
import Banner from '../../components/banner';
import ContentSlide from '../../components/ContentSlide';
import img from '../../assets/images/howitwork.PNG';
import icon from '../../assets/images/icon_howitwork.svg';
import { respondTo } from '../../theme/mixin';
import BoxLayout from '../../components/Ui/BoxLayout';
import GuadagnarePunti from '../../components/GetPoints/GetPoints';
import bgInvitAmico from '../../assets/images/bgInvitAmico.jpg';
import icon2 from '../../assets/images/icon_msg.svg';
import { Link } from 'react-router-dom';
const HowItWorks = () => {
  const howItWorksCms = useSelector(state => state.cms.howItWorks);

  const how_text = howItWorksCms[0]?.contents?.find(e => e.name === 'how_it_works_text');

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <Fade>
      <Wrapper>
        <Banner>
          <HiwBanner>
            <ContentSlide
              title='Come funziona?'
              text='Non perderti nessun dettaglio su come funziona il programma You and Sun.'
              img={img}
              icon={icon}
              noBtn
              linear
              size={30}
              xBold='800'
              textBold
            />
          </HiwBanner>
        </Banner>
        <CustomFlex align='stretch' justify='center'>
          <Col padding={10} width={60}>
            <BoxLayout
              title='YOU AND SUN TUTTO QUELLO CHE DEVI SAPERE'
              height='100%'
              styling={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Text size={18} margin='0 0 25px 0'>
                Partecipare al programma<strong> You and Sun </strong>è semplice: iscriviti dal
                <strong> 24/05/2022</strong> e fino al <strong>31/03/2025</strong> e accumula punti
                con i tuoi acquisti di prodotti del <strong>Tour Operator Alpitour</strong>{' '}
                (Alpitour, Francorosso, Bravo, Eden viaggi, Turisanda 1924, Presstour e Made) come
                nella tabella riportata{' '}
                <Link className='qui' to='/howitworks/howtoearn#accumulobase'>
                  qui
                </Link>
                . Ti faranno accumulare punti tutte le prenotazioni effettuate dal 24/05/2022 e con
                data rientro sino al 31/03/2025, ma anche le azioni qualificanti e i Punti Extra che
                trovi riportati{' '}
                <Link className='qui' to='/howitworks/howtoearn#puntiextra'>
                  qui
                </Link>
                .
                <br />
                <br />
                Una volta superata la<strong> soglia minima di 1.400 punti</strong>, entra nella
                sezione <strong>"Premi"</strong> e<strong> scegli quello più adatto a te</strong>. I
                premi potranno essere richiesti fino al 30/04/2025 e dovranno essere utilizzati
                entro il 31/05/2025. Per utilizzare i buoni è possibile effettuare la prenotazione
                tramite agenzie di viaggi, call center o sito, comunicando il codice del buono
                ottenuto.
                <br />
                <br />
                Puoi decidere di <strong>dividere i punti </strong>generati dalla prenotazione
                <strong> con i tuoi compagni di viaggio</strong>. A patto di avere un saldo punti
                maggiore o uguale a quelli maturati dalla pratica da condividere, potrai condividere
                i punti con tutti o con solo alcuni dei partecipanti e tale azione potrà essere
                fatta una sola volta per ogni pratica e non potrà essere annullata (verifica{' '}
                <Link className='qui' to='/user/profile'>
                  qui
                </Link>{' '}
                il tuo saldo punti). É sufficiente che anche loro siano iscritti al programma{' '}
                <strong>You and Sun</strong> e che abbiano associato il numero di pratica
                all’interno della propria area riservata (prima della condivisione dei punti), entro
                e non oltre il <strong>31/03/2025</strong>.
                <br />
                <br />
                <strong>
                  Ricorda che le prenotazioni annullate o non fruite non generano punti.{' '}
                </strong>
                <br />
                Per saperne di più, scarica il regolamento completo.
              </Text>
              <Flex justify='space-between' align='center'>
                <a
                  href={`${process.env.PUBLIC_URL}/pdf/regolamento.pdf`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Button active hover>
                    SCARICA IL REGOLAMENTO
                  </Button>
                </a>
                <Link to='/contact/#faq'>
                  <Button border hoverGrey>
                    BISOGNO D'AIUTO?
                  </Button>
                </Link>
              </Flex>
            </BoxLayout>
          </Col>
          <Col padding={10} className='sm_mt-20 ' stype={{ flex: 1 }} width={38}>
            <Flex align='center' direction='column' style={{ height: '100%' }}>
              <GuadagnarePunti width='100%' />
              <BoxLayout
                icon={icon2}
                title='PUNTI POTENZIALI VS PUNTI EFFETTIVI'
                bg1={bgInvitAmico}
                width='100%'
                margin='20px 0 0 0'
                style={{ flex: 1 }}
              >
                <Text bold size={14} style={{ marginTop: 40 }}>
                  I punti sono da considerarsi potenziali fino al rientro dal viaggio quando
                  diventeranno effettivi e potranno essere utilizzati per la scelta del premio.
                </Text>
              </BoxLayout>
            </Flex>
          </Col>
        </CustomFlex>
      </Wrapper>
    </Fade>
  );
};
const CustomFlex = styled(Flex)`
  margin-bottom: 30px;
  background-color: transparent;
  z-index: 5;
  position: relative;
  .qui {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
  ${respondTo.sm` 
      margin-top: -60px;
    `};
`;
const HiwBanner = styled.div`
  .hiw-img {
    object-position: 7%;
    ${respondTo.sm` 
      object-position: top;
    `};
  }
`;
const TablePoints = styled.table`
  margin-top: 15px;
  width: 100%;
  border-collapse: collapse;

  thead {
    td {
      padding: 10px 20px;
      background-color: ${props => props.theme.primary};
      border-left: 1px solid #fff;
    }
  }

  tbody {
    td {
      font-size: 15px;
      padding: 10px 20px;
      border-bottom: 1px solid #a3aec5;
    }
  }
`;

const DownloadRegulation = styled.div`
  height: 230px;
  position: relative;
  margin-top: 30px;
  padding: 15px;

  & > div {
    position: relative;
    z-index: 1;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default HowItWorks;
