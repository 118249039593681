import * as actionTypes from './actionTypes';
import customaxios from '../../config/axios-refresh-token';

export const getPartnerList = () => {
  let token = localStorage.getItem('token');
  let endpoint = `partners`;
  return dispatch => {
    return customaxios
      .get(endpoint)
      .then(response => {
        dispatch(getPartnerSuccess(response.data));
      })
      .catch(error => {
        console.log(error);
      });
  };
};
export const getPartnerListTurisanda = () => {
  let token = localStorage.getItem('token');
  let endpoint = `partners`;
  let data = {
    is_turisanda: true,
  };
  return dispatch => {
    return customaxios
      .get(endpoint, {
        params: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        dispatch(getPartnerTurisandaSuccess(response.data));
      })
      .catch(error => {
        console.log(error);
      });
  };
};
const getPartnerSuccess = data => {
  return {
    type: actionTypes.GET_PARTNERS_SUCCESS,
    partnerList: data,
  };
};
const getPartnerTurisandaSuccess = data => {
  return {
    type: actionTypes.GET_PARTNERS_TURISANDA_SUCCESS,
    partnerListTurisanda: data,
  };
};
