import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Typography } from '../../../../../Ui';
import { darken, lighten } from 'polished';
import { respondTo } from '../../../../../../theme/mixin';
import { CheckCircle } from 'styled-icons/boxicons-solid';
const SingleAnswer = styled.div`
  flex: 1;
  margin-bottom: 8px;
  ${respondTo.sm`
        flex: 0 50%;
        margin-bottom: 8px;
    `}
`;

const AnswerButton = styled(Button)`
  width: 100%;
  display: block;
  font-size: 18px;
  height: auto;
  padding: 12px;
  opacity: ${props => (props.disabled ? (props.tcomplete ? '.5' : '1') : '1')};
  margin: auto;
  box-shadow: 0px 5px 15px #00000026;
  border-radius: 15px;
  .check {
    position: absolute;
    top: 5px;
    right: 5px;
    color: ${props => (props.isSelected ? '#e4e7f1' : '#002d72')};
  }
  > svg {
    display: none;
  }
  color: #161615;
  background: white;
  &:hover {
    background: #002d72;
    color: white;
    svg {
      color: white;
    }
  }
  svg {
    color: ${props => (props.isSelected ? '#002d72' : '#AAAAA3')};
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${respondTo.sm`
        font-size: 21px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    `}
`;

const Answer = props => {
  const [dis, setDis] = useState(false);
  //const [isValid, setIsValid] = useState(false);
  const answerRef = useRef(null);

  const {
    value,
    id,
    idQuestion,
    change,
    disabled,
    points,
    children,
    clicked,
    instantcheck,
    selectedcheck,
    selectedAnswer,
    selectedAnsw,
  } = props;

  const handleClick = e => {
    setDis(true);

    change();

    let answ = {};
    if (value) {
      answ.result = value.toString();
    } else {
      answ.result = 0;
    }
    if (points) {
      answ.points = points.toString();
    } else {
      answ.points = 0;
    }
    answ.questionid = idQuestion.toString();
    answ.answerid = id.toString();

    // answ.result = e.currentTarget.getAttribute('value');
    // answ.questionid = e.currentTarget.getAttribute('idquestion');
    // answ.answerid = e.currentTarget.getAttribute('id');
    // answ.points = e.currentTarget.getAttribute('points');

    setTimeout(() => {
      if (instantcheck) {
        if (selectedcheck) {
          answerRef.current &&
            answerRef.current.setAttribute(
              'style',
              `background-color: ${value === 1 ? props.colorWin : props.colorLoose};`,
            );
        }
        clicked(e, answ);
      } else {
        clicked(e, answ);
      }
    }, 600);

    answerRef.current &&
      answerRef.current.setAttribute(
        'style',
        `background-color: ${darken(0.1, props.colorAnswers)};`,
      );
  };

  // LIFT UP STATE ?? DISABLE AND ISVALID

  return (
    <SingleAnswer {...props}>
      <AnswerButton
        ref={answerRef}
        type='answer'
        name={`radio${idQuestion}`}
        id={id}
        idquestion={idQuestion}
        points={points}
        value={value}
        //onClick={change}
        onClick={handleClick}
        disabled={disabled}
        //disabled={true}
        //disabled="disabled"
        isSelected={+props.selectedAnsw === props.id}
        {...props}
      >
        {children}
        <div className=''>
          <CheckCircle size={23} />
        </div>
      </AnswerButton>
    </SingleAnswer>
  );
};

Answer.propTypes = {
  change: PropTypes.func,
  value: PropTypes.number,
  id: PropTypes.number,
  idQuestion: PropTypes.number,
  points: PropTypes.number,
  isvalid: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.any,
};

export default Answer;
