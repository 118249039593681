import React from 'react';
import styled from 'styled-components';
import Banner from '../../components/banner';
import ContentSlide from '../../components/ContentSlide';
import Fade from '../../components/Fade';
import { Flex, Wrapper } from '../../components/Ui';
import { respondTo } from '../../theme/mixin';
import img from '../../assets/images/premi.png';
import icon from '../../assets/images/icon_regalo.svg';
import CouponBox from '../../components/CouponBox/CouponBox';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-awesome-styled-grid';
function Premi() {
  const { partnerList } = useSelector(state => state.partners);
  return (
    <Fade>
      <Wrapper>
        <Banner>
          <HiwBanner>
            <ContentSlide
              title='I premi*'
              text='Scegli il tuo premio e prepara la valigia per la prossima vacanza.'
              textasterisk='Premi fruibili solo per servizi prenotati dall’Italia ante partenza e presenti su estratto conto'
              img={img}
              icon={icon}
              noBtn
              linear
              size={30}
              xBold='800'
              textBold
            />
          </HiwBanner>
        </Banner>
        <CustomContainer>
          <Row style={{ alignItems: 'stretch' }}>
            {partnerList.map(item => (
              <Col xs={4} xsm={6} sm={6} md={4} lg={3} style={{ marginBottom: '15px' }}>
                <CouponBox key={item.id} partner={item} />
              </Col>
            ))}
          </Row>
        </CustomContainer>
      </Wrapper>
    </Fade>
  );
}

export default Premi;

const CustomContainer = styled(Container)`
  margin-bottom: 30px;
  background-color: transparent;
  z-index: 5;
  position: relative;

  ${respondTo.sm` 
      margin-top: -60px;
    `};
`;
const CustomFlex = styled(Flex)`
  margin-bottom: 30px;
  background-color: transparent;
  z-index: 5;
  position: relative;

  ${respondTo.sm` 
      margin-top: -60px;
    `};
`;
const HiwBanner = styled.div`
  .hiw-img {
    object-position: 7%;
    ${respondTo.sm` 
      object-position: initial;
    `};
  }
`;
