import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  product: {},

  orderModal: {
    isOpen: false,
    product: null,
  },
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.product,
        loading: false,
      };
    case actionTypes.GET_PRODUCT_LOADING:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.CLEAR_ORDER:
      return {
        ...state,

        orderModal: {
          isOpen: true,
          product: null,
        },
      };
    default:
      return state;
  }
};

export default productReducer;
