import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useForm from '../../hooks/useForm';
import customaxios from '../../config/axios-refresh-token';
import { contactForm } from '../../formsConfig/formsConfig';

import { Link } from 'react-router-dom';
import { Input, Button, Box, Text, Loader } from '../Ui';
import Heading from '../Ui/heading';
import img1 from '../../assets/images/iconContactBox.svg';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';

const CustomBox = styled(Box)`
  width: 100%;
  height: 100%;
  ${respondTo.sm`
     
  `}
  .d-none {
    display: none;
  }
`;
const Form = styled.form`
  .label-contact {
    color: #03c2e2;
    font-size: 10px;
  }
  .select-contact {
    option {
      color: #002d72;
    }
    & option:first-child {
      color: #47545d59;
    }
    &:invalid,
    & option[value=''] {
      color: #47545d59;
    }
  }
  .btn {
    padding: 0 24px;
    margin: 0 0 0 auto;
  }
`;
const Contact = ({ setModal, setModalContactOpen }) => {
  const [loading, setLoading] = useState(false);

  const submitHandler = () => {
    setLoading(true);
    let formDataToSend = {
      topic: formData.topic.value,
      message: formData.message.value,
      type: problemOrderSelected ? 'ticket' : 'email',
    };
    if (problemOrderSelected) {
      formDataToSend.order_id = formData.order_id.value;
    }
    customaxios
      .post('/app/contactus', formDataToSend)
      .then(response => {
        if (response.status === 200) {
          setModal(true);
          setModalContactOpen(false);
          setLoading(false);
          resetFormHandler();
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const ordersTurisanda = useSelector(state => state.user.user.orders);
  const show_turisanda = useSelector(state => state.user.user.show_turisanda);
  const {
    inputChangedHandler,
    formData,
    problemOrderSelected,
    formSubmitHandler,
    firstSubmit,
    resetFormHandler,
  } = useForm(submitHandler, contactForm(show_turisanda, ordersTurisanda));
  let inputArr = [];

  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  const inputs = inputArr.map(inp => (
    <Input
      inModal={inp.inModal}
      key={inp.name}
      value={inp.value}
      elementType={inp.elementType}
      elementConfig={inp.elementConfig}
      changed={inputChangedHandler}
      label={inp.label}
      isvalid={inp.valid}
      istouched={inp.validation.touched}
      firstSubmit={firstSubmit}
      problemOrderSelected={problemOrderSelected}
      resetFormHandler={resetFormHandler}
    />
  ));

  const contactcms = useSelector(state => state.cms.contact);
  const contact_image = contactcms[0]?.contents?.find(e => e.name === 'contact_image');
  const contact_text = contactcms[0]?.contents?.find(e => e.name === 'contact_text');

  return (
    <ContainerForm padding='15px'>
      <Heading className='d-none-desktop font-size-desk' img={img1} text='SCRIVICI' />
      <Form onSubmit={formSubmitHandler}>
        {inputs && inputs[0]}
        {!problemOrderSelected ? (
          <input type='hidden' name='order_id' value='null' />
        ) : (
          <div className={problemOrderSelected ? '' : 'd-none'}>{inputs && inputs[1]}</div>
        )}
        {inputs && inputs[2]}
        {inputs && inputs[3]}
        <input type='hidden' name='type' value={problemOrderSelected ? 'ticket' : 'email'} />
        <Button active upper size='14px' hover className='btn btn-contact' disabled={loading}>
          {loading ? <Loader inBtn show={true} /> : <span>Invia</span>}
        </Button>
      </Form>
    </ContainerForm>
  );
};
const ContainerForm = styled.div`
  .d-none-desktop {
    display: flex;
    align-items: center;
    ${respondTo.sm` 
      display: none;
    `};
  }
  .font-size-desk span {
    font-size: 24px;
  }
  .btn-contact {
    width: 100%;
    display: block;
    text-align: center;
    ${respondTo.sm` 
      width:initial;
      text-align: initial;
    `};
  }
  label {
    text-transform: uppercase;
    &[for='rules'] {
      text-transform: none;
    }
  }
`;
export default Contact;
