import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../../theme/mixin';
import Flex from '../Flex/Flex';
import Heading from '../heading';

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 15px;
  border-radius: 20px;
  overflow: hidden;
  color: ${({ theme }) => theme.primary};
  background-color: ${({ bgColor, theme, primary }) =>
    bgColor ? bgColor : primary ? theme.primary : theme.default_colortext};
  box-shadow: ${({ theme, noBoxShadow }) => (noBoxShadow ? 'none' : theme.shadow)};
  background-image: url(${({ bg2, bgMob }) => (bgMob ? bgMob : bg2)}), url(${({ bg1 }) => bg1});
  background-position: ${({ bgPosition }) => (bgPosition ? bgPosition : 'center')};
  background-size: ${({ bgSizeMobile }) => (bgSizeMobile ? bgSizeMobile : '100% 100%')};
  background-repeat: no-repeat;
  overflow: 'hidden';
  min-height: ${({ noMinHeight, minHeight }) =>
    minHeight ? minHeight : noMinHeight ? 'initial' : '250px'};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};

  ${respondTo.sm`
      width: ${({ width }) => width};
      background-size: ${({ bgSize }) => (bgSize ? bgSize : '100% 100%')};
      min-height: auto;
      background-image: url(${({ bg2 }) => bg2}), url(${({ bg1 }) => bg1});
  `}
`;
const Content = styled.div`
  flex: 1;
  ${({ styling }) => styling};
`;
function BoxLayout({ icon, title, children, styling, ...rest }) {
  return (
    <Container {...rest}>
      <Flex direction='column' align='stretch' height='100%' wrap='nowrap'>
        <Heading text={title} img={icon} className='section-heading' />
        <Content styling={styling}>{children}</Content>
      </Flex>
    </Container>
  );
}

export default BoxLayout;
