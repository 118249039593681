import React from 'react';
import styled from 'styled-components';
import { Button, Text } from '../../Ui';
import { Instagram } from '@styled-icons/boxicons-logos/Instagram';
import { useTranslation } from 'react-i18next';
const IgButton = styled(Button)`
  background-color: ${({ bgcolor }) => bgcolor};
  padding: 0 10px !important;
  gap: 5px;
  svg {
    right: 0;
  }
`;
const Ig = props => {
  const {
    metatags: { title, description, url, image },
  } = props;
  const [t] = useTranslation();
  const handleClick = () => {
    const url = `https://www.instagram.com/?url=${image}&caption=${title}`;
    window.open(url);
  };

  return (
    <IgButton bgcolor='#C13584' onClick={handleClick} noIcon>
      <Instagram size={24} />
      <Text size={14} white>
        {t('app.share')}
      </Text>
    </IgButton>
  );
};

export default Ig;
