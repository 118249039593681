import React, { useState, useEffect } from 'react';
import { respondTo } from '../../../../theme/mixin.js';
import { getSlug } from '../../../../utils/utils';
import Dynamic from '../../Dynamics/Dynamic';
import styled from 'styled-components/macro';
import bgDays from '../../../../assets/images/bg-days.png';
import { Flex, Loader, Modal, Text } from '../../../Ui';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import { acceptGameRules } from '../../../../store/actions/game.js';
import imgClick from '../../../../assets/images/img-click.png';
import bgdayPrev from '../../../../assets/images/bg-day.svg';
import bgDaysBorder from '../../../../assets/images/bg-days-border.svg';
const Container = styled.div`
  background-color: ${props => (props.bggamecolor ? props.bggamecolor : '')};
  background-size: cover;
  box-sizing: border-box;
  overflow: auto;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
  .content-participe-calendar {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    ${respondTo.xs`
      width: auto;
    `}
    ${respondTo.lg`
      width: calc(100% / 4);
    `}
    img {
      width: 100%;
    }
    .single-day {
      background-image: url(${bgDays});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 253px;
      height: 253px;
      position: relative;
      margin: 2px;
      cursor: pointer;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      border-radius: 15px;
      color: white;
      font-weight: bold;
      font-size: 40px;
      position: relative;
      padding: 20px;
      ${respondTo.md`
      font-size: 50px;
    `}
      ${respondTo.md`
      margin: 10px;
      font-size: 85px;
    `}
      .click-for-result {
        display: none;
      }
      &.not-played {
        cursor: initial;
        &::after {
          content: url(${bgdayPrev});
          position: absolute;
          border-radius: 15px;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      &.played {
        cursor: pointer;
        &:before {
          content: url(${bgDaysBorder});
          position: absolute;

          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        &::after {
          content: url(${bgdayPrev});
          position: absolute;
          border-radius: 15px;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        &:hover {
          .click-for-result {
            display: block;
            position: absolute;
            z-index: 1;
            top: 56%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 80%;
          }
        }
      }
      &.future_day {
        cursor: auto;
        pointer-events: none;
        &::after {
          content: '';
          position: absolute;
          border-radius: 20px;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: black;
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }
  .overlay {
    position: absolute;
    margin-top: 44px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: #ffffff99;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    padding: 15px;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    ${respondTo.sm` 
      margin-top: 70px;
      padding: 20px;
      width: 100px;
      height: 100px;
    `};
  }
`;

const TextParent = styled.div`
  text-align: center;
`;
const TextContainer = styled.div`
  background-color: #fff;
  border-radius: 60px;
  display: inline-block;
  font-size: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 22px;
  margin-left: 10px;
  margin-right: 10px;
  ${respondTo.sm`
	font-size: 22px;
	margin-left: 10px;
  `}
`;

const WrapperGame = styled.div`
  max-width: 960px;
  margin: 36px auto;
  max-width: 96%;
  text-align: center;
  ${respondTo.med`
		max-width: 90%;
	`}
  ${respondTo.sm`
		max-width: 760px;
	`}
	${respondTo.md`
		max-width: 830px;
	`}
`;
const OverlayRegola = styled.div`
  padding: 0 15px;
  .text-regola {
    font-size: 18px;
    margin-bottom: 22px;
    ${respondTo.sm`
 font-size:22px;
`}
  }
  .mb-24 {
    margin-bottom: 24px;
  }
  .regola {
    justify-content: space-between;
    background-color: #fff;
    padding: 10px 20px;
    max-width: 300px;
    width: 90%;
    border-radius: 50px;
    box-shadow: 0px 5px 15px #00000026;
    margin-bottom: 10px;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
    a {
      font-weight: 700;
    }
  }
  a {
    color: ${props => props.theme.primary};
    text-decoration: underline;
  }
`;

const CalendarClick = props => {
  const {
    onShowResult,
    onSetDayClicked,
    setRegulamentClicked,
    regulamentClicked,
    typeInteraction,
    g: {
      id,
      interaction,
      preinteraction,
      gamePrivacyAccepted,
      already_won,
      stop_attempts_after_win,
      log: { sid },
      settings: {},
      i18l: {
        info: {},
      },
      advent_calendar,
    },
    action: { data: dataAction, loading },
    regolamento,
  } = props;

  const [showResultSingleDay, setShowResultSingleDay] = useState(null);
  const dispatch = useDispatch();
  const { games } = useSelector(state => state.game || {});
  const extra = useSelector(state => state.user.user.userinfo?.extra || null);
  const [isregula, setIsregula] = useState(false);
  const [action, setAction] = useState(false);
  const [checked, setChecked] = useState(false);
  const [attemptsError, setAttemptsError] = useState(false);

  let slug = getSlug();

  useEffect(() => {
    if (extra && games.length > 0) {
      const newExtra = JSON.parse(extra);
      if (newExtra.accepted_privacy.find(id => id === games[0]?.id)) {
        setIsregula(false);
      } else {
        setIsregula(true);
      }
    }
  }, [extra, games]);

  let rules = JSON.parse(interaction.data);

  if (typeInteraction === 'preinteraction') {
    rules = JSON.parse(preinteraction.data);
  }
  const {
    settings: { online_duration },
  } = rules;
  useEffect(() => {
    if (dataAction.length > 0) {
      setTimeout(() => {
        onShowResult();
        onSetDayClicked(showResultSingleDay);
      }, 1500);
    }
  }, [dataAction]);

  const clickFinalHandler = day => {
    setShowResultSingleDay(day);
    setAction(true);
  };
  const clickHandler = () => {
    setChecked(true);
    dispatch(acceptGameRules(id, slug));
  };

  return (
    <>
      <React.Fragment>
        {loading || action ? (
          <Loader show={loading} />
        ) : (
          <>
            {gamePrivacyAccepted ? (
              <Container>
                {advent_calendar.map(day => (
                  <div className='content-participe-calendar' key={day.day}>
                    {day &&
                      (day.current_day && !day.played ? (
                        <div
                          className={`single-day ${(day.played || day.past_day) && 'played'} ${
                            day.future_day && 'future_day'
                          }`}
                          onClick={
                            !day.played && stop_attempts_after_win && already_won
                              ? () => setAttemptsError(true)
                              : () => clickFinalHandler(day)
                          }
                        >
                          <span className='click-for-result'>
                            <img src={imgClick} alt='' />
                          </span>
                          {day.day}
                        </div>
                      ) : !day.played ? (
                        <div
                          className={`single-day  ${day.future_day && 'future_day'} ${
                            !day.played && 'not-played'
                          }`}
                        >
                          {day.day}
                        </div>
                      ) : (
                        <div
                          className={`single-day ${(day.played || day.past_day) && 'played'} ${
                            day.future_day && 'future_day'
                          }`}
                          onClick={
                            !day.played && stop_attempts_after_win && already_won
                              ? () => setAttemptsError(true)
                              : () => {
                                  setShowResultSingleDay(showResultSingleDay);
                                  onSetDayClicked(day);
                                }
                          }
                        >
                          <span className='click-for-result'>
                            <img src={imgClick} alt='' />
                          </span>
                          {day.day}
                        </div>
                      ))}
                  </div>
                ))}
              </Container>
            ) : (
              <>
                <OverlayRegola className='overlay_regola'>
                  <Text bold className='text-regola'>
                    Ho letto il{' '}
                    <a
                      href={regolamento}
                      target='_blank'
                      rel='noopener noreferrer'
                      onClick={() => setRegulamentClicked(true)}
                    >
                      Regolamento
                    </a>{' '}
                    e dichiaro di accettarlo.
                  </Text>

                  <Flex className='regola' align='center'>
                    <Text bold>Accetto</Text>

                    <Switch
                      checked={checked}
                      onChange={clickHandler}
                      onColor='#86d3ff'
                      onHandleColor='#2693e6'
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                      activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                      height={20}
                      width={48}
                      className='react-switch'
                      disabled={!regulamentClicked}
                      id='material-switch'
                    />
                  </Flex>
                  <Text size='14' italic className='mb-24'>
                    Prima di accettare, è necessario aver cliccato sul regolamento.
                  </Text>

                  <Text size='18'>
                    Visualizza l'
                    <a
                      href={`${process.env.PUBLIC_URL}/pdf/INFORMATIVA PRIVACY You and Sun.pdf`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      informativa privacy
                    </a>
                  </Text>
                </OverlayRegola>
              </>
            )}
          </>
        )}
        {action ? (
          <Dynamic
            id={id}
            slug={slug}
            section={typeInteraction}
            action={props.action}
            sid={sid}
            {...props}
          />
        ) : null}
      </React.Fragment>
      <Modal
        show={attemptsError}
        close={() => {
          setAttemptsError(false);
        }}
      >
        {already_won ? (
          <p>Hai già vinto un premio per questo gioco.</p>
        ) : (
          <p>Per oggi hai terminato i tentativi di gioco.</p>
        )}
      </Modal>
    </>
  );
};

CalendarClick.propTypes = {};

export default CalendarClick;
