import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Wrapper, Logo, Text, Button, Loader } from '../Ui';
import NavigationItems from '../Navigation/NavigationItems/NavigationItems';
import { respondTo } from '../../theme/mixin';
import profileImage from '../../assets/images/iconProfile.svg';
import { Close } from '@styled-icons/material';
import { ReactComponent as Menu } from '../../assets/images/iconGrid.svg';
import LogoHeader from '../../assets/images/iconApp.svg';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { MdKeyboardArrowUp } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { numberWithDots } from '../../utils/utils';
import LinkDownload from '../Navigation/NavigationItems/NavigationItem/LinkDownload/LinkDownload';

const HeaderSection = styled.header`
  position: fixed;
  top: 15px;
  left: 15px;
  z-index: 9;
  width: calc(100% - 30px);
  .header-effect {
    background-color: #a6e2ff4f;
    backdrop-filter: blur(9px);
  }
  ${respondTo.sm`
    
  `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid ${({ theme }) => theme.navBorder};
  background-color: #a6fff97a;
  backdrop-filter: blur(45px);
  border-radius: 10px;
  padding: 10px;
  margin: 0 -15px;

  ${respondTo.sm`
    margin:0;
	`};

  .logo {
    ${respondTo.sm` 
      margin-right:150px
    `}
  }

  .rightWrapper {
    display: flex;
    align-items: center;

    & > div {
      margin-right: 10px;

      ${respondTo.sm`
        margin-right: 20px;
      `}
    }
  }
`;

const NavContainer = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 62px;
  left: 0;
  width: 100%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.default_colortext};
  box-shadow: ${({ theme }) => theme.shadow};
  ${respondTo.md`
    width: auto;
		display: flex;
    top: 0;
    margin-left:auto;
    position: static;
    border-top: none;
    box-shadow: none;
    background-color:transparent;
	`};
`;

const ProfileBox = styled.div`
  display: flex;
  align-items: center;
  order: -1;
  position: relative;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  padding: 5px;
  min-width: initial;

  ${respondTo.sm`
   order: 0;
   min-width: 170px;
   &:hover{
    box-shadow: ${({ theme }) => theme.shadow};
    background-color: #fff;
    .profile-actions {
      display: block;
      background-color: #fff;
    }
    .profile-row-up {
      display: block;
    }
    .profile-row-down {
      display: none;
    }
    .profile-point {
      opacity: 0;
    }
   }
  `}

  img {
    width: 30px;
    height: 30px;
    display: none;
    ${respondTo.sm`
   display: block;

  `}
  }
  .profile {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    justify-content: space-between;
    &-points {
      &:hover {
        display: none;
      }
    }
    &-btn {
      display: block;
      margin: 7px 0;
      border-radius: 5px;
      white-space: nowrap;
      text-align: center;
      span {
        &:hover {
          color: ${({ theme }) => theme.default_colortext};
        }
      }
    }
    &-link {
      span {
        color: ${({ theme }) => theme.primary};
      }
      border: 1px solid ${({ theme }) => theme.primary};
      &:hover {
        background-color: ${({ theme }) => theme.primary};
      }
    }
    &-esci {
      color: ${({ theme }) => theme.text_grey};
      border: 1px solid ${({ theme }) => theme.text_grey};
      &:hover {
        background-color: ${({ theme }) => theme.text_grey};
      }
    }
    &-name {
      margin-left: 10px;
      & span {
        line-height: 1;
      }
    }
    &-point {
      display: block;
    }
    &-actions {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      padding: 5px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      display: none;
    }
  }

  .profile-points {
    margin: 0 10px;
    padding: 0 10px;
    border-left: 1px solid #a3aec5;
    border-right: 1px solid #a3aec5;

    small {
      line-height: 1;
    }
  }

  .profile-row-up {
    display: none;
    cursor: pointer;
    margin-left: 10px;
  }
  .profile-row-down {
    display: none;
    cursor: pointer;
    margin-left: 10px;

    ${respondTo.sm`
   display: block;
  `}
  }
`;

const Header = props => {
  const { logo, logout, isLogged, multipromo, i18l } = props;

  const loadingCognito = useSelector(state => state.auth.loadingCognito);
  const games = useSelector(state => state.game.games);
  const userInfo = useSelector(state => state.user.user.userinfo);
  const points = useSelector(state => state.user.user.points);
  const { refreshToken } = useSelector(state => state.auth || {});
  const refresh_token = refreshToken || localStorage.getItem('refresh-token');
  const [idGame, setIdGame] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const webview = localStorage.getItem('webview');
  const scrollHandler = () => {
    if (window.scrollY > 100) {
      document.querySelector('.header-container').classList.add('header-effect');
      location.pathname === '/' && document.querySelector('.logo').classList.remove('logo-hide');
    } else {
      document.querySelector('.header-container').classList.remove('header-effect');
      location.pathname === '/' && document.querySelector('.logo').classList.add('logo-hide');
    }
  };
  useEffect(() => {
    if (games.length === 1) {
      setIdGame(games[0].id);
    }
  }, [games]);
  const show_turisanda = useSelector(state => state.user.user.show_turisanda);
  useEffect(() => {
    //window.addEventListener('scroll', scrollHandler);
    //return () => window.removeEventListener('scroll', scrollHandler);
  }, [location.pathname]);

  return (
    <HeaderSection>
      <Wrapper>
        <HeaderContainer className='header-container' path={location.pathname}>
          <Logo logo={LogoHeader} className='logo ' />
          {isLogged && (
            <ProfileBox className='box_profile'>
              <img src={profileImage} alt='' />
              <div className='profile'>
                <div className='profile-name'>
                  <Text size={14}>{userInfo?.firstname}</Text>
                  <Text size={14}>{userInfo?.lastname}</Text>
                </div>
                <div className='profile-actions'>
                  <Text capit size={10} bold style={{ display: 'flex' }}>
                    Hai{' '}
                    <Text primary size={10} margin='0 5px' bold>
                      {numberWithDots(points?.points_confirmed || 0) + ' Pt'}
                    </Text>{' '}
                    Y&S effettivi
                  </Text>
                  {show_turisanda && (
                    <Text capit size={10} bold style={{ display: 'flex' }}>
                      Hai{' '}
                      <Text primary size={10} margin='0 5px' bold>
                        {numberWithDots(points?.points_confirmed_turisanda || 0) + ' Pt'}
                      </Text>{' '}
                      Turisanda effettivi
                    </Text>
                  )}

                  <Link to='/user/profile' className='profile-link profile-btn'>
                    <Text upper size={10} bold>
                      pagina personale
                    </Text>
                  </Link>
                  {!webview && (
                    <Link to='/' className='profile-esci profile-btn'>
                      <Text upper size={10} bold onClick={() => logout(refresh_token)}>
                        esci
                      </Text>
                    </Link>
                  )}
                </div>
                <div>
                  <div className='profile-row-down'>
                    <MdKeyboardArrowDown />
                  </div>
                  <div className='profile-row-up'>
                    <MdKeyboardArrowUp />
                  </div>
                </div>
              </div>
            </ProfileBox>
          )}

          {loadingCognito ? (
            <Loader show={loadingCognito} />
          ) : (
            <>
              {isLogged ? (
                <>
                  <NavContainer isOpen={isMenuOpen}>
                    <NavigationItems
                      isLogged={isLogged}
                      multipromo={multipromo}
                      i18l={i18l}
                      idGame={idGame}
                      logout={logout}
                      setIsMenuOpen={setIsMenuOpen}
                      clicked={() => setIsMenuOpen(false)}
                      webview={webview}
                      refresh_token={refresh_token}
                    />
                  </NavContainer>
                  <MenuContainerMobile onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    {isMenuOpen ? (
                      <Close size={34} style={{ cursor: 'pointer' }} />
                    ) : (
                      <Menu className='menu' />
                    )}
                  </MenuContainerMobile>
                </>
              ) : (
                <div className='rightWrapper'>
                  <LinkDownload />
                  <a
                    style={{ display: 'block', marginLeft: 'auto' }}
                    href={process.env.REACT_APP_COGNITO_LOGIN}
                  >
                    <Button active>Accedi</Button>
                  </a>
                </div>
              )}
            </>
          )}
        </HeaderContainer>
      </Wrapper>
    </HeaderSection>
  );
};

const MenuContainerMobile = styled.div`
  display: flex;

  .menu {
    width: 34px;
    cursor: pointer;
  }
  ${respondTo.md`
		display: none;
	`}
`;

const MenuContainerDesk = styled.div`
  display: none;

  ${respondTo.md`
		display: block;
  `}
`;

Header.propTypes = {
  logo: PropTypes.string,
  appInit: PropTypes.func,
  logout: PropTypes.func,
  isLogged: PropTypes.string,
  appConfig: PropTypes.object,
};

export default Header;
