import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { respondTo } from '../../theme/mixin';
import { Link } from 'react-router-dom';

import { Text, Flex, Button } from '../Ui';

const AdvantagesModule = ({ cms }) => {
  const cms_text = cms[0].contents.find(e => e.name === 'home_advantages_text');
  const cms_image = cms[0].contents.find(e => e.name === 'home_advantages_image');

  const user = useSelector(state => state.user.user.userinfo);

  return (
    <AdvantagesWrapper>
      <Flex align='stretch'>
        <div className='paw-text'>
          <Text size={34} primary className='mb-20'>
            {cms_text?.i18l.title}
          </Text>

          <Text as='div' className='mb-30 paragraph'>
            {cms_text?.i18l.content}
          </Text>

          <Link to='/advantages'>
            <Button upper>Utilizzali</Button>
          </Link>
        </div>

        <div className='paw-image'>
          <img src={cms_image?.img} alt='' />
          <Text white light size={70}>
            {user?.wallet_amount || '0'}
          </Text>
          <Text white light size={34} style={{ top: 13 }}>
            €
          </Text>
        </div>
      </Flex>
    </AdvantagesWrapper>
  );
};

const AdvantagesWrapper = styled.div`
  overflow: hidden;
  box-shadow: 0px 5px 15px #00000026;
  border-radius: 10px;
  height: 100%;

  & > div {
    height: 100%;
  }

  .paw-text {
    width: 100%;
    padding: 30px 15px;
    background-color: #fff;
    position: relative;
    z-index: 1;

    ${respondTo.md`
      width: 66.666%;
    `}

    .paragraph {
      max-width: 455px;
    }

    .upload-receipt {
      background-color: #eaf0f3;
      border-radius: 10px;
      padding: 15px;
      text-align: center;
      position: relative;
      margin-top: -20px;
      top: 50px;

      ${respondTo.md`
        margin-top: 0;
        position: absolute;
        right: -30px;
        top: 50%;
        transform: translateY(-50%);
      `}

      strong {
        display: block;
        text-transform: uppercase;
        color: ${props => props.theme.primary};
        margin-bottom: 20px;
        font-size: 18px;
      }

      svg {
        margin: 0 10px;
      }
    }
  }

  .paw-image {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 250px;

    ${respondTo.md`
      width: 270px;
    `}

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    span {
      position: relative;
    }
  }
`;

export default AdvantagesModule;
