import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CalendarClick } from '../../Interactions';
import PreInteraction from '../../PreInteraction';
import { Button, Col, Flex, Modal, Text } from '../../../Ui';
import { Link, useHistory } from 'react-router-dom';
import BoxLayout from '../../../Ui/BoxLayout';
import { respondTo } from '../../../../theme/mixin';
import styled from 'styled-components';
import useMedia from '../../../../hooks/useMedia';
import SingleDayViewResult from '../../../../views/SingleDayViewResult/SingleDayViewResult';
import { getGame } from '../../../../store/actions';
import { getSlug } from '../../../../utils/utils';
import { useDispatch } from 'react-redux';
import { Loader } from '../../../Ui';
const CustomFlex = styled(Flex)`
  margin-bottom: 30px;
  min-height: 450px;
  background-color: transparent;
  z-index: 5;
  position: relative;
  .hiwBoxMobile {
    order: 1;
    margin-top: 30px;
    ${respondTo.sm` 
   order:0;
   margin-top: initial;
    `};
  }
  .col2 {
    height: ${({ gamePrivacyAccepted }) => (!gamePrivacyAccepted ? 'fit-content' : '330px')};
    ${respondTo.sm` 
   height:auto;
    `};
  }

  .col2-btn {
    padding-left: 0;
    svg {
      order: -1;
      right: 0;
    }
  }
  .leftBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .overlay_regola {
    background-color: #fff;
    /*     position: absolute;
    left: 0;
    top: 0; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin-top: -50px;
    min-height: 300px;
    ${respondTo.sm`
		 min-height: auto;
	`}
  }
  .text-regola {
    font-size: 18px;
    ${respondTo.sm`
		 font-size:22px;
	`}
  }
  .regola {
    justify-content: space-between;
    background-color: #fff;
    padding: 10px 20px;
    max-width: 300px;
    width: 90%;
    margin-top: 20px;
    border-radius: 50px;
    box-shadow: 0px 5px 15px #00000026;
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }
    a {
      color: ${props => props.theme.primary};
      font-weight: 700;
      text-decoration: underline;
    }
  }
  .travel-premi {
    margin-bottom: 0;
    margin-top: 60px;
    text-align: center;
    display: block;

    ${respondTo.sm` 
    text-align: initial;
      margin-bottom: 70px;
      margin-top: auto;
      width:initial;
    `};
  }
  .btn-scarica {
    order: 1;
    width: 100%;
    display: block;
    text-align: center;
    ${respondTo.sm` 
     order:0;
     width:initial;
     text-align: initial;
    `};
  }
  .btn-aiuto {
    order: 0;
    width: 100%;
    // display: block;
    text-align: center;
    margin-bottom: 10px;
    gap: 15px;

    margin-top: 15px;
    @media (min-width: 1050px) {
      margin-top: 0;
      order: 1;
      width: initial;
      text-align: initial;
      margin-bottom: initial;
    }
    /*  ${respondTo.md` 
   
    `}; */
  }
  .content-participe {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
    }
  }
  .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: #ffffff99;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    padding: 15px;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${respondTo.sm` 
       padding: 20px;
    width: 100px;
    height: 100px;
    `};
  }
`;
const CustomCol = styled(Col)`
  .section-heading {
    margin: 15px 0 0 15px;
  }
`;

const CalendarHandler = props => {
  const {
    g,
    g: {
      preinteraction,
      already_won,
      log: { steps },
      i18l: {
        how_play: { title: hpTitle, content: hpContent },
        info: { title: infoTitle },
      },
      gamePrivacyAccepted,
    },
    played,
    regolamento,
  } = props;
  let showPreInteraction = false;
  const history = useHistory();
  if (preinteraction !== null) {
    showPreInteraction = true;
  }
  const slug = getSlug();
  const dispatch = useDispatch();
  //const [attemptsError, setAttemptsError] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showResultPage, setShowResultPage] = useState(false);
  const [dayClicked, setDayClicked] = useState(null);
  const [showInteraction, setShowInteraction] = useState(!showPreInteraction); // true or false in base al fatto che ci sia la prop preinteraction
  const isMobile = useMedia('(max-width: 799px)');
  const scrollRef = useRef();
  useEffect(() => {
    if (scrollRef.current && isMobile) {
      //scrollRef.current.scrollIntoView();

      window.addEventListener('DOMContentLoaded', () =>
        window.scrollTo({ top: scrollRef.current.getBoundingClientRect().top, behavior: 'smooth' }),
      );
    }
  }, [isMobile]);
  let content = null;
  const onSetDayClicked = day => setDayClicked(day);
  const onShowResult = () => setShowResult(true);
  const onShowResultPage = () => setShowResultPage(true);
  const onShowInteractionHandler = () => {
    setShowInteraction(true);
  };
  const onDayClicked = () => {
    setDayClicked(null);
  };
  const onGetGame = () => {
    dispatch(getGame(props.g.id, slug));
  };
  const [regulamentClicked, setRegulamentClicked] = useState(false);
  content = (
    <>
      {showResultPage && dayClicked ? (
        <SingleDayViewResult
          click
          {...props}
          dayClicked={dayClicked}
          onGetGame={onGetGame}
          resultPageDynamic={true}
        />
      ) : dayClicked ? (
        <SingleDayViewResult click {...props} dayClicked={dayClicked} onDayClicked={onDayClicked} />
      ) : (
        <CustomFlex align='stretch' justify='center' ref={scrollRef}>
          {!gamePrivacyAccepted && (
            <Col width={50} className='hiwBoxMobile'>
              <BoxLayout title={hpTitle} noMinHeight style={{ height: '100%' }}>
                <div className='leftBox'>
                  <Text size={16} margin='0 0 25px 0'>
                    {hpContent}
                  </Text>
                  <Flex justify='space-between' align='center'>
                    {regolamento && (
                      <a
                        href={regolamento}
                        target='_blank'
                        rel='noopener noreferrer'
                        onClick={() => setRegulamentClicked(true)}
                      >
                        <Button active className='btn-scarica' hover>
                          SCARICA IL REGOLAMENTO
                        </Button>
                      </a>
                    )}
                    <Flex gap={15} className='btn-aiuto'>
                      <Button
                        arrowLeft
                        hoverGrey
                        border
                        className='col2-btn'
                        upper
                        onClick={() => history.goBack(-1)}
                      >
                        Indietro
                      </Button>

                      <Link to='/contact/#faq'>
                        <Button border hoverGrey>
                          BISOGNO D'AIUTO?
                        </Button>
                      </Link>
                    </Flex>
                  </Flex>
                </div>
              </BoxLayout>
            </Col>
          )}
          <CustomCol
            width={!gamePrivacyAccepted ? 50 : 100}
            className='sm_mt-15 col2'
            heightRegola={gamePrivacyAccepted}
          >
            <BoxLayout
              title={''}
              noMinHeight
              style={{ height: '100%', position: 'relative', padding: 0 }}
            >
              {!showInteraction && steps.preinteraction.data === null ? (
                <PreInteraction
                  preinteraction={preinteraction}
                  onShowInteractionHandler={onShowInteractionHandler}
                  {...props}
                />
              ) : (
                <CalendarClick
                  typeInteraction='interaction'
                  regulamentClicked={regulamentClicked}
                  setRegulamentClicked={setRegulamentClicked}
                  onShowResult={showResult && played ? onShowResult : onShowResultPage}
                  onSetDayClicked={onSetDayClicked}
                  {...props}
                />
              )}
            </BoxLayout>
          </CustomCol>
        </CustomFlex>
      )}
    </>
  );

  return content;
};

CalendarHandler.propTypes = {
  action: PropTypes.object,
};

export default CalendarHandler;
