import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { respondTo } from '../../../theme/mixin';
import { Text } from '../../Ui';

const Container = styled.div`
  position: absolute;
  top: ${props => (props.fromConcorso ? '15px' : '445px')};
  right: ${props => (props.fromConcorso ? '10px' : '15px')};
  border-radius: 15px;
  z-index: 2;
  width: 140px;
  padding: 10px;
  box-shadow: ${({ theme }) => theme.shadow};
  background-color: #fff;
  text-align: center;

  .ptg-point {
    border-radius: 15px;
    background-color: ${({ theme }) => theme.borderCode};
    padding: 5px;
    ${respondTo.md`
      top: 5px;
      right: 5px;
    `}
  }

  ${respondTo.sm`
    top: ${props => (props.fromConcorso ? '15px' : '385px')};
    right: ${props => (props.fromConcorso ? '15px' : '70px')};
  `}
`;
function PointsGame({ fromConcorso }) {
  const [missingDays, setMissingDays] = useState();

  useEffect(() => {
    const today = new Date().getDay();
    if (today <= 5) {
      setMissingDays(5 - today);
    } else if (today === 6) {
      setMissingDays(6);
    } else if (today === 7) {
      setMissingDays(5);
    }
  }, []);

  return (
    <Container fromConcorso={fromConcorso}>
      <div className='ptg-point'>
        {missingDays === 0 ? (
          <>
            <Text size={12}>oggi è</Text>
            <Text primary size={20} upper bold>
              Venerdì
            </Text>
          </>
        ) : (
          <>
            <Text size={12} upper>
              manca{missingDays > 1 && 'no'}
            </Text>
            <Text primary bold size={28}>
              {missingDays}
            </Text>
            <Text upper size={12}>
              giorn{missingDays === 1 ? 'o' : 'i'}
            </Text>
          </>
        )}
      </div>
      <Text type='navLinkSmall' upper style={{ marginTop: 5 }}>
        {missingDays === 0 ? (
          <>
            partecipa
            <br />
            subito!
          </>
        ) : (
          <>
            a venerdì,
            <br />
            torna presto!
          </>
        )}
      </Text>
    </Container>
  );
}

export default PointsGame;
