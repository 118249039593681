import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex, Col, Text, Button, Wrapper, Box, Loader, Modal } from '../../components/Ui';
import { ReactComponent as FavoriteBlack } from '../../assets/images/favorite_black.svg';
import { ReactComponent as FavoriteBorder } from '../../assets/images/favorite_border.svg';
import Fade from '../../components/Fade/Fade';
import { useDispatch, useSelector } from 'react-redux';
import Banner from '../../components/banner';
import ContentSlide from '../../components/ContentSlide';
import img from '../../assets/images/banner-placeholder.svg';
import imgVote from '../../assets/images/beach2.jpeg';
import { ReactComponent as VoteBlack } from '../../assets/images/vote_black.svg';
import { ReactComponent as VoteBorder } from '../../assets/images/vote_border.svg';
import { respondTo } from '../../theme/mixin';
import BoxLayout from '../../components/Ui/BoxLayout';
import customAxios from '../../config/axios-refresh-token';
import { useLocation, useParams } from 'react-router';
import NotFound from '../NotFound';
import { getGames, getPromoInfo } from '../../store/actions';
import { getSlug } from '../../utils/utils';
import Switch from 'react-switch';
import { acceptGameRules } from '../../store/actions/game';
import CustomSlider from '../../components/customSlider';
import { maxHeight } from 'styled-system';
const Gallery = () => {
  const { checkLoading, game, error404 } = useSelector(state => state.promo || {});
  const { id: idGame, i18l: i14l } = useSelector(state => state.game?.games?.[0] || {});
  const { gallery: galleryContent } = i14l || {};

  let avgRate = 4;
  const [count, setCount] = useState(8);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState();
  const [voted, setVoted] = useState(false);
  const [checked, setChecked] = useState(false);
  const [regolaAccepted, setRegolaAccepted] = useState(false);
  const [showPhoto, setShowPhoto] = useState('');

  const promo = useSelector(state => state.promo.promoinfo);
  const {
    pdf_regolamento,

    gallery,
    i18l,
    img_desktop,
    img_mobile,
    dark_text,
  } = promo || {};
  const { subtitle, title } = i18l || {};
  var options = {
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'custom-slider',
    infinite: true,
    dots: true,
    arrows: false,
    adaptiveHeight: true,
    dotsClass: 'slider-dots',
    autoplay: true,
  };
  //const { state } = useLocation();
  const { slug } = useParams();
  //const { slug, id: idGame } = state || {};
  const dispatch = useDispatch();
  // Function to load more items on button click
  const loadMore = () => {
    setCount(count + 4); // Increment count by 10
  };

  useEffect(() => {
    dispatch(getGames(slug, true));
    dispatch(getPromoInfo(slug, 'skip'));
  }, []);

  useEffect(() => {
    if (idGame) {
      customAxios
        .get(`game/${slug}/${idGame}/ratephotos`)
        .then(response => {
          setData(response.data);
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
        });
    }
  }, [voted, idGame, regolaAccepted]);

  const voteHandler = (id, setIsVoting) => {
    if (data?.total_votes === data?.votes_limit) {
      setError('Hai già utilizzato tutti i voti a disposizione');
      return;
    }
    setIsVoting(true);
    customAxios
      .post(`/game/${slug}/${idGame}/ratephoto/${id}/vote`)
      .then(response => {
        setVoted(prev => !prev);
        setIsVoting(false);
      })
      .catch(error => {
        setIsVoting(false);
        setError(error.response.data.error);
      });
  };
  const clickHandler = () => {
    setChecked(true);
    dispatch(acceptGameRules(idGame, slug)).then(() => setRegolaAccepted(true));
  };
  if (error404.length) {
    return <NotFound type='promo' />;
  }
  const photoClickHandler = url => {
    setShowPhoto(url);
  };
  return (
    <>
      {isLoading ? (
        <Loader show={isLoading} />
      ) : (
        <CustomWrapper>
          <Banner>
            <CustomSlider settings={options}>
              <SecondSlid>
                <ContentSlide
                  title={title}
                  text={subtitle}
                  img={img_desktop}
                  imgMobile={img_mobile}
                  noBtn
                  linear
                  darkText={dark_text}
                  textBold
                />
              </SecondSlid>
              {gallery?.length > 0 &&
                gallery?.map(item => (
                  <SecondSlid>
                    <ContentSlide
                      title={item.title}
                      text={item.text}
                      img={item.img_desktop}
                      imgMobile={item.img_mobile}
                      noBtn
                      textBold
                      darkText={item.dark_text}
                    />
                  </SecondSlid>
                ))}
            </CustomSlider>
          </Banner>
          <CustomFlex justify='space-between' gap='10px'>
            <CustomCol1 padding={0} width={60}>
              <BoxLayout
                title={galleryContent?.title}
                height='100%'
                styling={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Text size={18} margin='0 0 25px 0'>
                  {galleryContent?.content}
                </Text>
              </BoxLayout>
            </CustomCol1>
            <CustomCol2 padding={0} className='sm_mt-20' stype={{ flex: 1 }} width={38}>
              <BoxLayout title='VOTI ASSEGNATI' width='100%' style={{ flex: 1 }} bgColor='#0051CE'>
                <Text white className='mb-10'>
                  {data?.total_votes} di {data?.votes_limit}
                </Text>
                <Flex gap='10px' justify='space-between'>
                  {[...Array(data?.votes_limit)].map((item, i) => {
                    if (i < data?.total_votes) {
                      return <FavoriteBlack className='svg' />;
                    } else {
                      return <FavoriteBorder className='svg' />;
                    }
                  })}
                </Flex>
              </BoxLayout>
            </CustomCol2>
          </CustomFlex>
          {data?.gamePrivacyAccepted ? (
            <div>
              <Flex justify='space-between'>
                <Text primary size={24}>
                  FOTO
                </Text>
              </Flex>
              <Grid>
                {data?.gallery?.length > 0 &&
                  data?.gallery
                    ?.slice(0, count)
                    ?.map((item, i) => (
                      <GalleryItem
                        data={item}
                        key={item.id}
                        voteHandler={voteHandler}
                        clickHandler={photoClickHandler}
                        myvotes={data.myvotes}
                      />
                    ))}
              </Grid>
              {data?.gallery?.length > 8 && (
                <Button
                  active
                  style={{ margin: '50px auto', padding: '0 30px' }}
                  onClick={loadMore}
                >
                  CARICA ALTRE
                </Button>
              )}
            </div>
          ) : (
            data?.gamePrivacyAccepted !== undefined && (
              <OverlayRegola className='overlay_regola'>
                <Flex className='regola' align='center' gap='10px'>
                  <Flex gap='5px'>
                    <Text bold className='text-regola'>
                      Dichiaro di aver letto e accettato il
                    </Text>
                    <a
                      // href={`${process.env.PUBLIC_URL}/pdf/Regolamento_Mettiti_in_Sposa.pdf`}
                      href={pdf_regolamento}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ fontWeight: 'bold' }}
                    >
                      regolamento
                    </a>
                  </Flex>
                  <Switch
                    checked={checked}
                    onChange={clickHandler}
                    onColor='#86d3ff'
                    onHandleColor='#2693e6'
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                    activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                    height={20}
                    width={48}
                    className='react-switch'
                    id='material-switch'
                  />
                </Flex>
                <Text bold className='text-regola'>
                  Leggi{' '}
                  <a
                    href={`${process.env.PUBLIC_URL}/pdf/Informativa_concorso_Mettiti_in Sposa_Votanti.pdf`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    l’informativa privacy
                  </a>{' '}
                  connessa alla raccolta dei dati
                </Text>
              </OverlayRegola>
            )
          )}
        </CustomWrapper>
      )}
      <Modal
        show={error}
        close={() => {
          setError();
        }}
      >
        {error}
      </Modal>
      <Modal
        show={showPhoto}
        flex={true}
        close={() => {
          setShowPhoto('');
        }}
      >
        <img style={{ width: 'auto', maxHeight: 'calc(100vh - 200px)' }} src={showPhoto} alt='' />
      </Modal>
    </>
  );
};

const GalleryItem = ({ data, voteHandler, clickHandler, myvotes }) => {
  // const [voted, setVoted] = useState(false);
  const { id, photo_date, photo_url, photo_title, store_name, voted, votes } = data;
  const [isVoting, setIsVoting] = useState(false);
  const mineVotes = myvotes.filter(vot => vot === id)?.length;
  return (
    <CustomBox active={voted}>
      <div className='voted'>
        {mineVotes > 0 && mineVotes + ' vot' + (mineVotes === 1 ? 'o' : 'i')}
      </div>
      <img src={photo_url} alt='' onClick={() => clickHandler(photo_url)} />
      <Flex className='mt-10' align='center'>
        {isVoting ? <VoteBorder /> : <VoteBlack onClick={() => voteHandler(id, setIsVoting)} />}
        <div className='voteNum'>
          <Text bold>VOTI:</Text>
          <Text color='#AAAAA3'>{votes}</Text>
        </div>
        <div className='content'>
          <Text bold>{photo_title}</Text>
          {/* <Text color='#AAAAA3'>{photo_date}</Text> */}
        </div>
      </Flex>
    </CustomBox>
  );
};

const OverlayRegola = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 150px 15px;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  ${respondTo.med` 
      grid-template-columns: repeat(2, 1fr);
     `};
  ${respondTo.md` 
     grid-template-columns: repeat(3, 1fr);
     `};
  ${respondTo.lg` 
    grid-template-columns: repeat(4, 1fr);
     `};
`;

const CustomBox = styled(Box)`
  flex-grow: 1;
  padding: 15px;
  border: 3px solid ${({ active }) => (active ? '#002D72' : 'transparent')};
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${respondTo.med` 
     
    `};
  ${respondTo.md` 
   
    `};
  ${respondTo.lg` 
  
    `};
  .voted {
    position: absolute;
    top: 0;
    right: 30px;
    background-color: #002d72;
    color: #fff;
    padding: 8px;
    text-transform: uppercase;
    font-size: 9px;
    display: ${({ active }) => (active ? 'block' : 'none')};
    border-radius: 0 0 10px 10px;
  }
  img {
    border-radius: inherit;
    max-width: 100%;
    max-height: 211px;
    margin: 0 auto;
    display: block;
  }
  span {
    font-size: 12px;
  }
  svg {
    margin-right: 10px;
  }
  .content {
    flex-grow: 1;
    width: 100%;
    padding: 5px;
  }
  .voteNum {
    margin-left: 10px;
    display: flex;
    gap: 5px;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }
`;
const CustomWrapper = styled(Wrapper)`
  .slider-dots {
    right: 25px;
  }
`;
const CustomCol1 = styled(Col)`
  @media (max-width: 821px) {
    width: 100%;
  }
`;
const CustomCol2 = styled(Col)`
  .section-heading > span {
    color: #fff;
  }
  .svg {
    width: 50px;
  }
  @media (max-width: 821px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const CustomFlex = styled(Flex)`
  margin-bottom: 30px;
  background-color: transparent;
  z-index: 5;
  position: relative;
  .pdf_btn {
    margin-top: 15px;
    @media (min-width: 767px) {
      margin-top: 0;
    }
  }
  .qui {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
  ${respondTo.sm` 
      margin-top: -60px;
    `};
`;
const HiwBanner = styled.div``;
const TablePoints = styled.table`
  margin-top: 15px;
  width: 100%;
  border-collapse: collapse;

  thead {
    td {
      padding: 10px 20px;
      background-color: ${props => props.theme.primary};
      border-left: 1px solid #fff;
    }
  }

  tbody {
    td {
      font-size: 15px;
      padding: 10px 20px;
      border-bottom: 1px solid #a3aec5;
    }
  }
`;

const DownloadRegulation = styled.div`
  height: 230px;
  position: relative;
  margin-top: 30px;
  padding: 15px;

  & > div {
    position: relative;
    z-index: 1;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const SecondSlid = styled.div`
  .hiw-text {
    ${respondTo.sm` 
      margin-right:0;
      width:35.5%;
      right:auto;
      left:35px;
    `};
  }
`;
export default Gallery;
