import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import CookieBot from 'react-cookiebot';

import { Provider } from 'react-redux';
import store from './store';

import './i18n';

import { Loader } from './components/Ui';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-MNQ2VHR',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Loader initial show={true} />}>
      <App />
      <CookieBot domainGroupId='31c90564-989e-4cf6-ab37-3e0f659de64e' />
    </Suspense>
  </Provider>,
  document.getElementById('root'),
);
