import React from 'react';
import { Text } from '../../../../Ui';
import styled from 'styled-components';
import { respondTo } from '../../../../../theme/mixin';

const LinkDownload = () => {
  return (
    <LinkDownloadWrapper>
      <Text bold size={12}>
        Regolamento
      </Text>
      <div className='subDownload'>
        <div className='subDownloadContent'>
          <a
            href={`${process.env.PUBLIC_URL}/pdf/regolamento.pdf`}
            target='_blank'
            rel='noopener noreferrer'
          >
            REGOLAMENTO You and Sun
          </a>
          <a
            href={`${process.env.PUBLIC_URL}/pdf/Regolamento_OP_OLTRE_LA_VACANZA.pdf`}
            target='_blank'
            rel='noopener noreferrer'
          >
            REGOLAMENTO Oltre la vacanza
          </a>
        </div>
      </div>
    </LinkDownloadWrapper>
  );
};

const LinkDownloadWrapper = styled.div`
  display: block;
  padding: 15px 0;
  margin-right: 25px;
  position: relative;

  span {
    text-align: center;
    text-transform: uppercase;
    border: 1px solid transparent;
    transition: 0.3s;
    border-radius: 5px;
    padding: 0 5px;
    ${respondTo.sm`
      font-size: 12px;
    `};
  }

  .subDownload {
    position: absolute;
    padding-top: 10px;
    display: none;

    .subDownloadContent {
      background-color: #fff;
      border-radius: 4px;
      padding: 10px;
      width: 200px;

      a {
        display: block;
        font-size: 12px;
        margin-bottom: 7px;
        color: ${props => props.theme.primary};

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &:hover {
    background-color: #fff;
    border-radius: 4px 4px 0 0;

    .subDownload {
      display: block;
    }
  }
`;

export default LinkDownload;
