import React from 'react';
import { useSelector } from 'react-redux';
import Fade from '../../components/Fade/Fade';
import { Text } from '../../components/Ui';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';

const Practices = () => {
  const potential_points = useSelector(state => state.user.user.potential_points);

  return (
    <Fade>
      <Text size={48} light>
        Pratiche
      </Text>

      <PracticesTable>
        <table>
          <thead>
            <tr>
              <th>
                <Text bold upper primary size={10}>
                  UID
                </Text>
              </th>
              <th>
                <Text bold upper primary size={10}>
                  Data ordine
                </Text>
              </th>
              <th>
                <Text bold upper primary size={10}>
                  Data accredito
                </Text>
              </th>
              <th>
                <Text bold upper primary size={10}>
                  Punti
                </Text>
              </th>
              <th>
                <Text bold upper primary size={10}>
                  Eliminata
                </Text>
              </th>
              <th>
                <Text bold upper primary size={10}>
                  Note
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {potential_points?.map((point, actionIndex) => (
              <tr key={actionIndex}>
                <td>
                  <Text bold size={12}>
                    {point.uid}
                  </Text>
                </td>
                <td>
                  <Text bold size={12}>
                    {point.data_ordine_iniziale}
                  </Text>
                </td>
                <td>
                  <Text bold size={12}>
                    {point.data_accredito}
                  </Text>
                </td>
                <td>
                  <Text bold size={12}>
                    {point.punti}
                  </Text>
                </td>
                <td>
                  <Text bold size={12}>
                    {point.deleted ? 'Si' : 'No'}
                  </Text>
                </td>
                <td>
                  <Text bold size={12}>
                    {point.notes || '-'}
                  </Text>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </PracticesTable>
    </Fade>
  );
};

const PracticesTable = styled.div`
  margin-top: 30px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #a3aec5;
  border-left: 5px solid ${props => props.theme.primary};
  padding: 15px 15px 10px 10px;

  table {
    width: 100%;
    border-collapse: collapse;

    thead th {
      border-top: 1px solid ${props => props.theme.primary};
      border-bottom: 1px solid ${props => props.theme.primary};
      padding: 0 5px;
    }

    tbody td {
      padding: 15px 5px;
      border-bottom: 1px solid #a3aec5;
    }
  }
`;

export default Practices;
