import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../../../theme/mixin';
import { Text } from '../../../Ui';

const PrizeWrapper = styled.div`
  background-color: #fff;
  width: calc(100% - 30px);
  max-width: 370px;
  position: relative;
  margin: 15px auto 0 auto;
  padding: 10px 15px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px #00000026;
`;

const Prize = props => {
  const {
    prize: { title, content },
  } = props;

  return (
    <PrizeWrapper>
      <Text upper primary bold align='center'>
        {title}
      </Text>
    </PrizeWrapper>
  );
};

export default Prize;
