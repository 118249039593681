import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from './Card';
import { shuffleArray, getSlug, getElement } from '../../../../utils/utils';
import styled from 'styled-components';
import { Loader, Text } from '../../../Ui';
import Fade from '../../../Fade';
import Dynamic from '../../Dynamics/Dynamic';
import { respondTo } from '../../../../theme/mixin';
import uniqid from 'uniqid';
import { useTranslation } from 'react-i18next';

import bgMemory from '../../../../assets/images/memorybg.jpg';

const WrapperGame = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0px;
  margin: auto;
  max-width: 96%;
  ${respondTo.med`
    max-width: 460px;
  `}
  ${respondTo.sm`
	grid-template-columns: repeat(4, 1fr);
	max-width: 760px;
  `}

  ${respondTo.md`
    max-width: 760px;
  `}
`;

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  /* background-image: ${props => (props.bggame ? `url(${props.bggame})` : `url(${bgMemory})`)};
	background-position: center center;
	background-color: ${props => (props.bggamecolor ? props.bggamecolor : '')};
	background-size: cover; */
  box-sizing: border-box;
  overflow: auto;
  flex: 1;
  padding: 16px 0;
`;

const AttemptsWrapper = styled.div`
  margin: 16px auto 16px;
  max-width: 96%;
  ${respondTo.med`
    max-width: 460px;
  `}
  ${respondTo.sm`
	grid-template-columns: repeat(4, 1fr);
	max-width: 760px;
  `}

  ${respondTo.md`
    max-width: 760px;
  `}
`;

const AttemptsContainer = styled.div`
  background-color: #fff;
  border-radius: 60px;
  display: inline-block;
  font-size: 1px;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 22px;
  margin-left: 10px;

  ${respondTo.sm`
    font-size: 18px;
  `}
`;

const Memory = props => {
  const [initialCards, setInitialCards] = useState([]);
  const [finalCards, setFinalCards] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [toWin, setToWin] = useState(null);
  const [showCards, setShowCards] = useState(false);
  const [canClose, setCanClose] = useState(false);
  const [attempts, setAttempts] = useState(null);
  const [action, setAction] = useState(false);
  const [resultData, setResultData] = useState({});

  const {
    lang,
    typeInteraction,
    onShowInteractionHandler,
    onShowResult,
    g: {
      interaction,
      preinteraction,
      id,
      log: { sid },
    },
    action: { data: dataAction },
  } = props;

  let rules = JSON.parse(interaction.data);

  if (typeInteraction === 'preinteraction') {
    rules = JSON.parse(preinteraction.data);
  }

  let slug = getSlug();

  const {
    type,
    data,
    settings: {
      cardopen,
      attempts: nAttempts,
      onlywin,
      aspect: { brand, bggame, bggamecolor },
    },
    i18l: {
      [lang]: { results },
    },
  } = rules;

  const [t] = useTranslation();

  useEffect(() => {
    setInitialCards(data);
    return () => {
      setInitialCards([]);
      setShowCards(false);
      setFinalCards([]);
    };
  }, []);

  useEffect(() => {
    initGame();
    setAttempts(nAttempts);
  }, [initialCards]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowCards(true);
      if (!canClose) {
        setCanClose(true);
      }
    }, 150);

    return () => {
      clearTimeout(timeout);
    };
  }, [finalCards]);

  useEffect(() => {
    if (canClose) {
      const timeout = setTimeout(() => {
        closeCards();
        setCanClose(false);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [canClose]);

  useEffect(() => {
    if (toWin === 0) {
      createResultData(true);
    }
  }, [toWin]);

  useEffect(() => {
    if (selectedCards.length === 2) {
      setTimeout(() => {
        checkCards();
      }, 1000);
    }
  }, [selectedCards]);

  useEffect(() => {
    if (attempts === 0) {
      createResultData(false);
    }
  }, [attempts]);

  useEffect(() => {
    if (Object.entries(resultData).length !== 0) {
      setTimeout(() => {
        if (typeInteraction === 'preinteraction') {
          onShowInteractionHandler();
        } else {
          setAction(true);
        }
      }, 1000);
    }
  }, [resultData]);

  useEffect(() => {
    if (dataAction.length > 0) {
      let isData = getElement(dataAction, id);
      if (isData) {
        onShowResult();
      }
    }
  }, [dataAction]);

  const initGame = () => {
    let buildCards = [];
    if (type === 'match') {
      buildCards = initialCards;
    } else {
      if (initialCards.length > 0) {
        buildCards = initialCards
          .map(item => {
            return [item, item];
          })
          .reduce((first, second) => {
            return first.concat(second);
          });
      }
    }

    let arr = [];
    for (let k in buildCards) {
      arr.push({
        value: buildCards[k].value,
        id: buildCards[k].id,
        src: buildCards[k].src,
        open: cardopen,
        completed: false,
        uniqid: uniqid(),
      });
    }

    setFinalCards(shuffleArray(arr));

    if (initialCards.length > 0) {
      if (type === 'match') {
        setToWin(initialCards.length / 2);
      } else {
        setToWin(initialCards.length);
      }
    }
  };

  const clickCardHandler = index => {
    if (!canClose) {
      if (
        selectedCards.length === 2 ||
        finalCards[index].completed ||
        finalCards[index].open ||
        attempts === 0
      ) {
        return;
      } else {
        finalCards[index].open = true;
        let arrSel = [];
        arrSel.push(finalCards[index]);
        setSelectedCards(selectedCards.concat(arrSel));
      }
    }
  };

  const closeCards = () => {
    let finalCardsCopy = finalCards.slice();
    const finalCardsClosed = [];
    for (let k in finalCardsCopy) {
      finalCardsClosed.push({ ...finalCardsCopy[k], open: false });
    }
    setFinalCards(finalCardsClosed);
  };

  const checkCards = () => {
    if (selectedCards.length === 2) {
      if (selectedCards[0].value === selectedCards[1].value) {
        winHandler();
      } else {
        looseHandler();
        setAttempts(attempts - 1);
      }
    }
  };

  const resetCards = () => {
    setSelectedCards([]);
  };

  const looseHandler = () => {
    selectedCards.forEach(card => {
      card.open = false;
    });
    resetCards();
  };

  const winHandler = () => {
    selectedCards.forEach(card => {
      card.completed = true;
    });

    resetCards();
    setToWin(toWin - 1);
  };

  const createResultData = won => {
    let resultwin = won ? 'win' : 'loose';
    for (const prop in results) {
      const result = true;
      if (result) {
        const data = {
          profile: { ...results[resultwin] },
          attempts,
          nAttempts,
          onlywin,
          won,
        };
        setResultData(data);
      }
    }
  };

  return (
    <React.Fragment>
      <Container bggame={bggame} bggamecolor={bggamecolor}>
        <Fade show={showCards}>
          {showCards ? (
            <AttemptsWrapper>
              <Text primary bold size={20} align='center'>{`${t(
                'games.attempts',
              )}: ${attempts}/${nAttempts}`}</Text>
            </AttemptsWrapper>
          ) : null}
          <WrapperGame>
            {showCards
              ? finalCards.map((card, index) => (
                  <Item key={card.uniqid}>
                    <Card
                      isOpen={card.open}
                      isCompleted={card.completed}
                      index={index}
                      value={card.value}
                      brand={brand}
                      src={card.src}
                      clicked={() => clickCardHandler(index)}
                    />
                  </Item>
                ))
              : null}
          </WrapperGame>
        </Fade>
        <Loader show={!showCards} full />
      </Container>
      {action ? (
        <Dynamic
          id={id}
          action={props.action}
          slug={slug}
          section={typeInteraction}
          resultData={resultData}
          sid={sid}
          {...props}
        />
      ) : null}
    </React.Fragment>
  );
};

Memory.propTypes = {
  cards: PropTypes.array,
};

export default Memory;
