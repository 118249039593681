import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { respondTo } from '../../../../../theme/mixin';
import { shuffleArray } from '../../../../../utils/utils';
import Answer from './Answer';
import { Text } from '../../../../Ui';
import Question from '../Question';
const AnswerContainer = styled.div`
  display: block;
  padding: 16px 24px;
  border-radius: 16px;
  ${respondTo.sm`
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-evenly;
    `};
  .question-container-titles {
    text-align: left;
    font-size: 16px;
    color: #002d72;
    font-weight: normal;
  }
  .question-title {
    font-size: 30px;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 15px;
    margin-top: 15px;
  }
`;

const Answers = props => {
  const {
    options,
    id,
    clicked,
    instantcheck,
    selectedcheck,
    showtrueanswer,
    colorLoose,
    colorWin,
    colorAnswers,
    completed,
    textQuestion,
    selectedAnswer,
    selectedAnsw,
    index,
  } = props;
  const [isValid, setIsValid] = useState(false);
  const [showTrueAnswer, setShowTrueAnswer] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [tcomplete, setTcomplete] = useState(false);
  const [click, setClick] = useState(false);
  const QuestionText = styled.div`
    padding: 12px 26px;
    margin: 10px auto;
    font-size: 20px;
    ${respondTo.sm`
  padding: 16px 24px;
`}
  `;
  useEffect(
    e => {
      if (completed) {
        setDisabled(true);
        setTcomplete(true);
      } else {
        setDisabled(false);
        setTcomplete(false);
      }
    },
    [completed],
  );

  const onChangeHandler = e => {
    //REMAIND: qui si disabilitavano le domande
    // setDisabled(true);

    setTimeout(() => {
      if (instantcheck) {
        if (!selectedcheck && !showtrueanswer) {
          setIsValid(true);
        }
        if (showtrueanswer) {
          setShowTrueAnswer(true);
        }
        //clicked(e, answ);
      } else {
        //clicked(e, answ);
      }
    }, 600);
  };

  let answer = null;

  answer = options.map(opt => {
    return (
      <Answer
        instantcheck={instantcheck}
        selectedcheck={selectedcheck}
        colorLoose={colorLoose}
        colorWin={colorWin}
        colorAnswers={colorAnswers}
        key={opt.id}
        idQuestion={id}
        id={opt.id}
        isvalid={isValid}
        showtrue={showTrueAnswer}
        value={opt.value}
        points={opt.points}
        change={onChangeHandler}
        disabled={disabled}
        tcomplete={tcomplete}
        click={click}
        clicked={clicked}
        selectedAnswer={selectedAnswer}
        selectedAnsw={selectedAnsw}
      >
        {opt.text}
      </Answer>
    );
  });

  return (
    <AnswerContainer>
      <div className='question-container-titles'>
        <div>Domanda {index + 1}</div>
        <div className='question-title'>{textQuestion}</div>
      </div>
      {answer}
    </AnswerContainer>
  );
};

Answers.propTypes = {
  options: PropTypes.array,
  id: PropTypes.number,
  clicked: PropTypes.func,
  instantcheck: PropTypes.number,
};

export default Answers;
