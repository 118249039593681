import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import Slider from 'react-slick';
import { Text, Flex, Box, Col } from '../Ui';
import PrizeBox from '../PrizeBox/PrizeBox';

const PrizesListModule = ({ cms, prizes }) => {
  const cms_text = cms[0].contents.find(e => e.name === 'home_prizes_text');

  const options = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <PrizesListWrapper>
      <Box className='mt-30'>
        <Flex row={15}>
          <Col width={25} className='md_mb-20'>
            <Text size={34} primary className='mb-20'>
              {cms_text?.i18l.title}
            </Text>
            <Text as='div'>{cms_text?.i18l.content}</Text>
          </Col>
          <Col width={75}>
            <Slider {...options}>
              {prizes?.map((prize, prizeIndex) => (
                <PrizeBox key={prizeIndex} prize={prize} />
              ))}
            </Slider>
          </Col>
        </Flex>
      </Box>
    </PrizesListWrapper>
  );
};

const PrizesListWrapper = styled.div`
  .slick-list {
    margin: 0 -10px;
  }
  .slick-slide {
    padding: 0 10px;
  }
  .slick-track {
    margin-left: 0;
  }
`;

export default PrizesListModule;
