import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { respondTo } from '../../../../../theme/mixin';
import { getElement } from '../../../../../utils/utils';
import cardMemory from '../../../../../assets/images/brand.png';
import { Loader } from '../../../../Ui';

const FlipCard = styled.div`
  background-color: transparent;
  width: 130px;
  height: 192px;
  perspective: 1000px;
  display: inline-block;
  margin-bottom: 20px;
  margin: 10px auto;
  cursor: pointer;
  ${props =>
    props.isCompleted &&
    css`
      opacity: 0.5;
      cursor: default;
    `}
  ${respondTo.med`
        width: 150px;
        height: 221px;
		margin: 10px auto;
    `};
  ${respondTo.sm`
        width: 160px;
        height: 236px;
		margin: 10px;
    `};
  ${respondTo.md`
        width: 186px;
        height: 275px;
		margin: 10px;
		
    `};
`;

const FlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  ${props =>
    props.isOpen &&
    css`
      transform: rotateY(180deg);
      cursor: default;
    `};
`;

const FlipCardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-color: transparent;
  color: black;
  z-index: 2;
  border-radius: 8px;
  border: 4px solid white;
  background-image: ${props => (props.brand ? `url(${props.brand})` : `url(${cardMemory})`)};
  background-size: cover;
  ${props =>
    props.isCompleted &&
    css`
      display: none;
    `};
`;

const FlipCardBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  color: black;
  transform: rotateY(180deg);
  z-index: 1;
  background-size: cover;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    border-radius: 8px;
    border: 4px solid white;
  }
`;

const Img = styled.img`
  display: block;
  width: 60%;
  height: auto;
  margin: auto;
`;

const CardElement = props => {
  const { clicked, result, idGame, loading } = props;

  let data = getElement(result, idGame);

  return (
    <FlipCard {...props} onClick={clicked}>
      <FlipCardInner {...props}>
        <FlipCardFront {...props} />
        <FlipCardBack {...props}>
          {data ? (
            data.won ? (
              <img src={props.wincard} />
            ) : (
              <img src={props.loosecard} />
            )
          ) : (
            <Loader show={loading} small />
          )}
        </FlipCardBack>
      </FlipCardInner>
    </FlipCard>
  );
};

export default CardElement;
