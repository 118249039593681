import React from 'react';
import CountdownEl from 'react-countdown';
import styled from 'styled-components';
import { ChevronRight } from 'styled-icons/boxicons-regular';
import { darken } from 'polished';
import { useState, useEffect } from 'react';
const CountDownContainer = styled.div`
  height: 32px;
  width: 142px;
  border-radius: 5px;
  background-color: white;
  color: #002d72;
  border: solid 1px #002d72;

  text-transform: uppercase;
  &:hover {
    // background-color: ${props => darken(0.1, '#002d72')};
    border-radius: 5px;
  }
`;

const NextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  font-size: 12px;
  color: #002d72;
  text-align: center;
  cursor: pointer;
  pointer-events: ${props => (props.disabled ? 'none' : '')};
  & span {
    display: block;
    font-size: 12px;
    margin-top: -8px;
  }
  & svg {
    margin-left: 3px;
  }
  &:hover {
    border-radius: 5px;
    // background-color: ${props => darken(0.1, '#002d72')};
  }
`;

const CountDownSurvey = props => {
  const { time, remainingTime, rend, start } = props;
  const [nextDiv, setNextDiv] = useState(null);

  useEffect(() => {
    let timer;
    if (start) {
      timer = setTimeout(() => {
        setNextDiv(
          <NextContainer>
            <CountdownEl
              date={Date.now() + time}
              intervalDelay={10}
              precision={3}
              zeroPadTime={3}
              renderer={rend}
            />
          </NextContainer>,
        );
      }, 0);
    } else {
      timer = setTimeout(() => {
        setNextDiv(
          <NextContainer>
            <CountdownEl
              date={Date.now() + remainingTime}
              intervalDelay={10}
              precision={3}
              zeroPadTime={3}
              renderer={rend}
            />
          </NextContainer>,
        );
      }, 0);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [start, time]);

  return (
    <React.Fragment>
      <CountDownContainer isTime={start}>{nextDiv}</CountDownContainer>
    </React.Fragment>
  );
};

export default CountDownSurvey;
