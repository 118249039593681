import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0 15px;
  ${({ inHome }) => inHome && 'border-top: 1px solid white'};
`;

export default Wrapper;
