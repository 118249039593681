import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  products: [],
  allProducts: [],
  categories: [],
  ordersTurisanda: [],
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCTS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.products,
      };
    case actionTypes.GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.products,
        allProducts: action.products,
      };
    case actionTypes.GET_ORDER_TURISANDA_SUCCESS:
      return {
        ...state,
        ordersTurisanda: action.ordersTurisanda,
      };
    case actionTypes.GET_FILTERS_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.categories,
      };

    default:
      return state;
  }
};

export default productsReducer;
