import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Button, Wrapper } from '../../components/Ui';
import { useParams, Link } from 'react-router-dom';
import { respondTo } from '../../theme/mixin';
import customaxios from '../../config/axios-refresh-token';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../store/actions';
import ErrorInModal from '../../components/ErrorInModal';
import { Modal, Flex, Col, Text } from '../../components/Ui';
import Fade from '../../components/Fade/Fade';
import { ReactComponent as Storico } from '../../assets/images/icon_storico.svg';
import { ReactComponent as Letter } from '../../assets/images/icon_email.svg';
import ContentSlide from '../../components/ContentSlide';
import { Code } from 'styled-icons/material';
import CopyButton from '../../components/Games/Response/Code/DisplayCode/CopyButton/CopyButton';
import BoxLayout from '../../components/Ui/BoxLayout';
import { numberWithDots } from '../../utils/utils';
import { addToCart, getProductCart } from '../../store/actions/cart';
import { useHistory } from 'react-router';
import { getSingleProduct, openOrderModal } from '../../store/actions/products';

const ProductTurisanda = props => {
  const dispatch = useDispatch();
  const userPoints = useSelector(state => state.user.user.points);
  const [requestCompleted, setRequestCompleted] = useState(false);
  const loadingCart = useSelector(state => state.user.loadingCart);
  const [requestLoading, setLoadingRequest] = useState(loadingCart);
  const [modalError, setModalError] = useState(false);
  const [code, setCode] = useState(null);
  const [modalRequestOpened, setModalRequestOpened] = useState(false);

  const closeModalRequest = () => {
    setModalRequestOpened(false);
  };
  const closeModalError = () => {
    setModalError(false);
  };
  const addToCartProd = id => {
    dispatch(addToCart(id));
    dispatch(getProductCart());
  };
  useEffect(() => {
    setLoadingRequest(loadingCart);
  }, [loadingCart]);

  const { id } = useParams();
  const product = useSelector(state => state.product.product);

  const progress =
    userPoints.points_confirmed_turisanda >= +product.points
      ? 100
      : Math.floor((+userPoints.points_confirmed_turisanda * 100) / +product.points);

  useEffect(() => {
    if (id) {
      dispatch(getSingleProduct(id));
    }
  }, [dispatch, id]);

  const {
    image,
    brand,
    category_name,
    description,
    father_id,
    fulfillment_id,
    group,
    m_product_id,
    ns_catalog_id,
    object_type,
    points,
    qty,
    requestable,
    sector,
    short_description,
    title,
  } = product;

  return (
    <>
      <Fade>
        <Wrapper>
          <CustomFlex
            row={15}
            widthRequest={userPoints?.points_confirmed_turisanda >= points}
            progressNum={progress}
          >
            <Col width={50} className='col1'>
              <img src={image} alt='' className='premio-img' />
              <div className='request'>
                <Flex
                  justify='space-between'
                  align='stretch'
                  style={{ height: '100%' }}
                  wrap='nowrap'
                >
                  <Col
                    width={userPoints?.points_confirmed_turisanda >= points ? 50 : 100}
                    padding={10}
                    className='request-col1'
                  >
                    <Text
                      size={24}
                      primary
                      upper
                      style={{ margin: ' 0 10px 10px 10px' }}
                      align='center'
                    >
                      {code && requestCompleted
                        ? 'ECCO IL TUO CODICE'
                        : userPoints?.points_confirmed_turisanda <= points
                        ? 'PER RICHIEDERLO'
                        : 'PUOI RICHIEDERLO!'}
                    </Text>
                    {!code && !requestCompleted ? (
                      <>
                        <div className='progress'>
                          <div className='progress-width' progressNum></div>
                          <Text size={12} className='progress-num'>
                            {numberWithDots(points || 0)} pt
                          </Text>
                        </div>

                        <Text as='p' upper size={10} bold margin='3px 0 0 0' align='center'>
                          hai
                          <span className='percent'>{progress}%</span>
                          dei punti necessari
                        </Text>
                      </>
                    ) : (
                      <Text primary size={12} align='center' className='coupon-code'>
                        {code}
                      </Text>
                    )}
                  </Col>

                  {userPoints?.points_confirmed_turisanda >= points && (
                    <Col width={30} padding={10} className='request-col2'>
                      <Button
                        active
                        style={{ display: 'block', textAlign: 'center' }}
                        loading={requestLoading}
                        disabled={requestLoading}
                        onClick={() => addToCartProd(id)}
                      >
                        AGGIUNGI AL CARRELLO
                      </Button>
                    </Col>
                  )}
                </Flex>
              </div>
            </Col>
            <Col width={50} className='col2'>
              <Flex direction='column' style={{ width: '100%' }}>
                <Flex>
                  <Text size={14} primary upper style={{ marginLeft: '5px' }}>
                    {brand}
                  </Text>
                </Flex>

                <ContentSlide
                  title={short_description}
                  text={description}
                  size={30}
                  xBold='800'
                  noBtn
                  style={{ flex: 1 }}
                />
                <Link to='/catalogo' className='col2-link'>
                  <Button arrowLeft hoverGrey border fullwidth className='col2-btn' upper size={12}>
                    Indietro
                  </Button>
                </Link>
              </Flex>
            </Col>
          </CustomFlex>
        </Wrapper>
      </Fade>
      <Modal show={modalRequestOpened} close={closeModalRequest}>
        <Text as='p' size={18} bold margin='3px 0 25px 0' align='left'>
          Confermi di voler richiedere il premio selezionato?
        </Text>

        <Button
          active
          style={{ display: 'block', textAlign: 'left', marginLeft: 'auto' }}
          loading={requestLoading}
          disabled={requestLoading}
        >
          CONFERMA
        </Button>
      </Modal>
      <Modal show={modalError} close={closeModalError}>
        <ErrorInModal error={modalError} />
      </Modal>
    </>
  );
};
const CustomFlex = styled(Flex)`
  margin-bottom: 60px;

  .col1 {
    position: relative;
    padding: 0;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 100px;
  }
  .col2 {
    padding: 15px;
    background-color: #fff;
    margin-top: 20px;
    ${respondTo.sm`
        padding: 10px 10px 10px 30px;
       background-color:initial;
       margin-top:130px;
    `}
    &-link {
      width: 100%;
      ${respondTo.sm`
        width:initial;
    `}
    }
    &-btn {
      justify-content: space-between;
      svg {
        order: -1;
        right: 0;
      }
    }
  }

  .premio-img {
    width: 100%;
    height: 400px;
    display: block;
    object-fit: contain;
    object-position: top;
    margin-bottom: 138px;
    ${respondTo.sm`
    object-position: center;
      height: 550px;
      
    `}
  }

  .request {
    background-color: #fff;
    position: absolute;
    bottom: 10px;
    left: 10px;
    padding: 10px 0;
    border-radius: 15px;
    right: 10px;

    ${respondTo.sm`
        right: ${({ widthRequest }) => (widthRequest ? '10px' : 'auto')};
    `}
    &-col1 {
      ${respondTo.sm`
        width:100%;
    `}
      @media(min-width:1100px) {
        width: initial;
      }
    }
    &-col2 {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: -15px 0;
      border-left: 1px solid ${({ theme }) => theme.bgcode};
      ${respondTo.sm`
        width:100%;
    `}
      @media(min-width:1100px) {
        width: 40%;
      }
    }
  }
  .progress {
    width: 100%;
    height: 16px;
    background-color: #f1f1f1;
    position: relative;
    border-radius: 5px;
    &-width {
      width: ${({ progressNum }) => progressNum + '%'};
      height: 100%;
      border-radius: inherit;
      background-color: ${({ progressNum }) => (progressNum === 100 ? '#0051CE' : '#002d7280')};
    }
    &-num {
      color: ${({ progressNum }) => (progressNum === 100 ? 'white' : ({ theme }) => theme.primary)};
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%);
    }
  }
  .percent {
    color: ${({ theme }) => theme.primary};
    margin: 0 3px;
  }
  .coupon-code {
    width: 50%;
    background-color: ${({ theme }) => theme.bgColorDiv};
    padding: 3px 10px;
    margin: 0 auto;

    @media (min-width: 1100px) {
      width: 100%;
    }
  }
  .hiw-content {
    padding: 0;
    margin-top: 10px;
  }
  .hiw-text {
    width: 100%;
  }
`;

export default ProductTurisanda;
