import React from 'react';
import styled from 'styled-components';
import imgIcon from '../../assets/images/iconCart.svg';
import Heading from '../Ui/heading';
import bgImg from '../../assets/images/bgTuaCard.svg';
import { respondTo } from '../../theme/mixin';
import logo from '../../assets/images/iconApp.svg';
import { useSelector } from 'react-redux';
import { Flex, Text, Box } from '../Ui';
import { numberWithDots } from '../../utils/utils';
import { Link } from 'react-router-dom';
const Container = styled(Box)`
  display: none;

  ${respondTo.sm`
  border-radius: 15px;
  overflow: hidden;
  display: block;
  > a {
    display: flex;
    flex-direction: column;
  }
  padding: 15px 0 0 15px;
      width: 60%;
  `}
  .code {
    margin-bottom: 20px;
  }
`;

const Content = styled.div`
  background-image: url(${bgImg});
  background-repeat: no-repeat;
  background-position: bottom -10px right -20px;
  background-size: 50% 100%;
  width: 100%;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .point-text {
    color: ${({ theme }) => theme.secondary};
  }
  .logo-img {
    width: 130px;
    margin-top: 15px;
  }
`;

function TuaCard() {
  const { firstname, lastname, code } = useSelector(state => state.user.user?.userinfo || {});
  const { points_confirmed, points_to_confirm } = useSelector(
    state => state.user.user?.points || {},
  );

  return (
    <Container>
      <Link style={{ height: '100%' }} to='/user/profile'>
        <Heading text='la tua card You and Sun' img={imgIcon} />
        <Content>
          <img src={logo} className='logo-img' />
          <div className='content-text'>
            <Text capit size={24} bold>
              {firstname} {lastname}
            </Text>
            <Flex gap='50px' style={{ marginTop: '10px' }}>
              <Text size={12} capit>
                hai <span className='point-text'>{numberWithDots(points_confirmed || 0)} pt</span>{' '}
                confermati
              </Text>
              <Text size={12} capit>
                hai <span className='point-text'>{numberWithDots(points_to_confirm || 0)} pt</span>{' '}
                da confermare
              </Text>
            </Flex>
          </div>
          <div className='code'>
            <Text size={12}>N° tessera</Text>
            <Text size={24}>{code}</Text>
          </div>
        </Content>
      </Link>
    </Container>
  );
}

export default TuaCard;
