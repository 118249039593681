import React, { useState } from 'react';
import styled from 'styled-components';
import Heading from '../Ui/heading';
import icon from '../../assets/images/iconInvitAmico.svg';
import { Button, Text } from '../Ui';
import { respondTo } from '../../theme/mixin';
import GenerateCode from './generateCode';
import BoxLayout from '../Ui/BoxLayout';
import bg2 from '../../assets/images/imgInvitAmico.png';
import bg1 from '../../assets/images/bgInvitAmico.jpg';
const Container = styled.div`
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadow};
  background-color: ${({ theme }) => theme.primary};
  border-radius: 15px;
  padding: 15px;
  z-index: 1;
  padding-bottom: 30px;
  color: ${({ theme }) => theme.default_colortext};
  cursor: pointer;
  ${respondTo.sm`
      width: 38%;
  `}
  .section-heading {
    color: ${({ theme }) => theme.default_colortext};
  }
`;
function InvitaAmico() {
  const [showCode, setShowCode] = useState(false);
  const clickHandler = e => {
    e.stopPropagation();
    setShowCode(prev => !prev);
  };
  return (
    <BoxLayout
      onClick={() => setShowCode(prev => !prev)}
      title='invita un amico'
      icon={icon}
      width='38%'
      bg1={bg1}
      bg2={bg2}
      bgSize='340px 220px,110% 110%'
      bgSizeMobile='240px 160px,110% 110%'
      bgPosition='bottom right,center'
    >
      <Text size={14} bold>
        Hai degli amici a cui piace viaggiare?
      </Text>
      <Text size={14}>
        Invitali a iscriversi all'iniziativa <strong>You and Sun </strong>attraverso il tuo codice
        personale e guadagna subito <strong>100 punti.</strong>
      </Text>
      <Text size={14}>
        <strong>Attenzione però:</strong> riceverai i punti solo per{' '}
        <strong>i primi tre amici</strong> che si iscriveranno al programma.
      </Text>
      <Text size={14} margin='0 0 30px 0'>
        Adesso che sai come funziona, genera il tuo codice e invita subito chi vuoi.{' '}
        <strong>Il tuo prossimo viaggio è sempre più vicino!</strong>
      </Text>
      {!showCode && (
        <Button
          primary
          white
          upper
          size='14px'
          onClick={e => clickHandler(e)}
          border='none'
          hover
          style={{ border: 'none' }}
        >
          genera codice
        </Button>
      )}
      {showCode && <GenerateCode />}
    </BoxLayout>
  );
}

export default InvitaAmico;
