import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import CopyButton from '../../Games/Response/Code/DisplayCode/CopyButton/CopyButton';
import { Button, Text } from '../../Ui';
const Container = styled.div`
  .gnc-code {
    border-radius: 5px;
    background-color: ${({ theme }) => theme.bgcode};
    padding: 10px;
    margin-bottom: 15px;
    min-width: 150px;
    width: 230px;
    color: ${({ theme }) => theme.primary};
  }
  .text_color {
    color: ${({ theme }) => theme.text_link};
  }
  .btnHover {
    font-weight: bold;
    &:hover {
      color: ${({ theme }) => theme.text_link};
    }
  }
`;
function GenerateCode() {
  const { mgm_code } = useSelector(state => state.user.user.userinfo);

  return (
    <Container>
      <Text size={10} upper color='blue' bold className='text_color'>
        ecco il tuo codice invito
      </Text>
      <div className='gnc-code'>{mgm_code}</div>
      <CopyButton data={mgm_code} />
      {/*  <Button active copy sizeIcon='16px' hover='color: blue' padding='0'>
        copia il codice
      </Button> */}
    </Container>
  );
}

export default GenerateCode;
