import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Text } from '../../Ui';
import styled from 'styled-components';
import { getProtocol } from '../../../utils/utils';
import { Facebook } from 'styled-icons/boxicons-logos';
import { UserLocation } from 'styled-icons/remix-fill';

const FbButton = styled(Button)`
  background-color: ${({ bgcolor }) => bgcolor};
  padding: 0 10px !important;
  gap: 5px;
  svg {
    right: 0;
  }
`;

const Fb = props => {
  const [t] = useTranslation();
  const {
    metatags: { title, description, url, image },
  } = props;
  console.log(image);
  let protocol = getProtocol();

  const handlShare = () => {
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600,left=100,top=100`;
    let shareparams = `r=${title}QMIFB${description}QMIFB${url}QMIFB${image}`;
    let u = `${protocol}/imageurl=${image}`;
    window.open(`https://www.facebook.com/sharer.php?u=${u}`, 'sharer', shareparams);
  };
  /*   
    const handlShare = () => {
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600,left=100,top=100`;
    let shareparams = `r=${title}QMIFB${description}QMIFB${url}QMIFB${image}`;
    let u = `https://www.vinciconcarreraducati.it/fb-share.php?${shareparams}`;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${u}`, 'sharer', params);
  }; 
  */
  //www.facebook.com/sharer.php?u=https:example.com?imageurl=${this.state.imageUrl}}
  return (
    <FbButton noIcon onClick={handlShare} bgcolor='#3b5998'>
      <Facebook size={24} />
      <Text size={14} white>
        {t('app.share')}
      </Text>
    </FbButton>
  );
};

export default Fb;
