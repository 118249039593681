import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dynamic from '../../Dynamics/Dynamic';
import Scratch from './Scratch';
import { respondTo } from '../../../../theme/mixin';
import styled from 'styled-components';
import { getElement, getSlug } from '../../../../utils/utils';
import Fade from '../../../Fade/Fade';
import bgMemory from '../../../../assets/images/memorybg.jpg';
import { Text } from '../../../Ui';

const Container = styled.div`
  padding-top: 24px;
  // background-image: ${props => (props.bggame ? `url(${props.bggame})` : `url(${bgMemory})`)};
  // background-position: center center;
  // background-color: ${props => (props.bggamecolor ? props.bggamecolor : '')};
  // background-size: cover;
  box-sizing: border-box;
  overflow: auto;
  flex: 1;
`;

const CardContainer = styled.div`
  padding: 24px 0;
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  max-width: 93%;
  margin: auto;
  grid-gap: 30px;
  padding: 24px 0;
  ${respondTo.sm`
    max-width: 508px;
    grid-gap: 24px;
  `}
`;
const TextParent = styled.div`
  text-align: center;
`;

const ScratchAndWin = props => {
  const {
    lang,
    typeInteraction,
    g: {
      id,
      interaction,
      preinteraction,
      log: { sid },
    },
    onSetAttempts,
    showSw,
    action: { data },
  } = props;

  let rules = JSON.parse(interaction.data);

  if (typeInteraction === 'preinteraction') {
    rules = JSON.parse(preinteraction.data);
  }

  let slug = getSlug();

  const {
    type,
    settings: {
      numberofcards,
      aspect: { brand, bggame, bggamecolor, loosecard, wincard, symbol },
    },
    i18l: {
      [lang]: { textUp, textDown },
    },
  } = rules;

  const [isLocked, setIsLocked] = useState(true);
  const [action, setAction] = useState(false);
  const [show, setShow] = useState(false);

  let numberofcardsArray = [];
  for (let i = 0; i < numberofcards; i++) {
    numberofcardsArray.push(i);
  }

  let cards = [];
  let cardstodisplay = null;

  const unLockScratch = () => {
    setIsLocked(false);
  };
  const onSetAction = () => {
    setAction(true);
  };

  let dataElement = getElement(data, id);

  if (dataElement) {
    let n = Math.floor(Math.random() * numberofcardsArray.length - 1) + 1;
    if (dataElement.won) {
      for (let i = 0; i < numberofcardsArray.length; i++) {
        if (i === n) {
          cards.push({
            i,
            iswinning: true,
            nodynamic: true,
            hiddenpart: wincard,
          });
        } else {
          cards.push({
            i,
            iswinning: false,
            nodynamic: true,
            hiddenpart: loosecard,
          });
        }
      }
    } else {
      for (let i = 0; i < numberofcardsArray.length; i++) {
        cards.push({
          i,
          iswinning: false,
          nodynamic: true,
          hiddenpart: loosecard,
        });
      }
    }
    cardstodisplay = cards.map(c => {
      return (
        <Scratch
          key={c.i}
          iswinning={c.iswinning}
          hiddenpart={c.hiddenpart}
          islocked={false}
          brand={brand}
          onSetAttempts={onSetAttempts}
          {...props}
        />
      );
    });
  } else {
    cardstodisplay = numberofcardsArray.map(c => {
      return (
        <Scratch
          key={c}
          hiddenpart={``}
          islocked={true}
          brand={brand}
          onSetAttempts={onSetAttempts}
          {...props}
        />
      );
    });
  }

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <React.Fragment>
      <Container bggame={bggame} bggamecolor={bggamecolor}>
        <Fade show={showSw}>
          <TextParent>
            <Text bold capit primary>
              {textUp}
            </Text>
          </TextParent>
          <CardContainer>
            <Scratch
              unlock={() => unLockScratch()}
              onSetAction={onSetAction}
              hasdynamic={true}
              hiddenpart={symbol}
              brand={brand}
              onSetAttempts={() => {}}
              {...props}
            />
          </CardContainer>
          <TextParent>
            <Text primary bold capit>
              {textDown}
            </Text>
          </TextParent>
          <CardsContainer>{cardstodisplay}</CardsContainer>
        </Fade>
      </Container>
      {action ? (
        <Dynamic id={id} slug={slug} section={typeInteraction} sid={sid} {...props} />
      ) : null}
    </React.Fragment>
  );
};

ScratchAndWin.propTypes = {};

const comparisonFn = function (prevProps, nextProps) {
  if (prevProps.attempts > nextProps.attempts && prevProps.attempts != 4) {
    return true;
  } else {
    return false;
  }
};

export default React.memo(ScratchAndWin, comparisonFn);
