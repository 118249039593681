import * as actionTypes from '../actions/actionTypes';

const initialState = {
  user: {
    cart: [],
    orderTurisanda: [],
    objectCart: {},
    newOrder: {
      error: '',
      errorOrder: '',
      loading: false,
      completed: false,
    },
  },

  formInfo: null,
  loadingForm: false,
  loading: true,
  error: null,
  activatedloading: true,
  activated: false,
  activatederror: null,
  userLogged: null,
  userInfoUpdateSuccess: false,
  loadingChangePsw: false,
  loadingCart: false,
  passwordChanged: false,
  errorChangePsw: null,
  firstAccessLoading: false,
  firstAccessError: '',
  firstAccessComplete: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTIVATE_USER_START:
      return {
        ...state,
        firstAccessError: '',
        firstAccessLoading: true,
      };

    case actionTypes.EDIT_USER_SUCCESS:
      return {
        ...state,
        updateModal: true,
        user: { ...state.user, userinfo: action.user },
      };
    case actionTypes.EDIT_USER_ERROR:
      return {
        ...state,
        updateError: true,
      };
    case actionTypes.ORDER_PRODUCT_START:
      console.log(state.newOrder);
      return {
        ...state,
        user: {
          ...state.user,
          newOrder: {
            ...state.newOrder,
            loading: true,
            completed: false,
          },
        },
      };

    case actionTypes.ORDER_PRODUCT_SUCCESS:
      return {
        ...state,
        updateModal: true,
        user: {
          ...state.user,
          cart: [],
          newOrder: {
            ...state.newOrder,
            loading: false,
            completed: true,
          },
        },
      };

    case actionTypes.ORDER_PRODUCT_ERROR_CLEAR:
      return {
        ...state,
        user: {
          ...state.user,

          newOrder: {
            error: '',
            errorOrder: '',
            loading: false,
            completed: false,
          },
        },
      };
    case actionTypes.ORDER_PRODUCT_ERROR:
      console.log(state.user, action.errorOrder);
      return {
        ...state,
        user: {
          ...state.user,
          newOrder: {
            error: true,
            errorOrder: action.errorOrder,
            loading: false,
            completed: false,
          },
        },
      };

    case actionTypes.TO_CART_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          cart: action.cart,
        },
      };
    case actionTypes.GET_PRODUCT_CART_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          objectCart: action.objectCart,
          newOrder: {
            error: '',
            errorOrder: '',
            loading: false,
            completed: false,
          },
        },
      };
    case actionTypes.GET_CART_LOADING:
      return {
        ...state,
        loadingCart: true,
      };
    case actionTypes.GET_CART_LOADING_SUCCESS:
      return {
        ...state,
        loadingCart: false,
      };

    case actionTypes.CLOSE_MODAL_EDIT:
      return {
        ...state,
        updateModal: false,
      };
    case actionTypes.ACTIVATE_USER_ERROR:
      return {
        ...state,
        firstAccessLoading: false,
        firstAccessError: action.error,
      };
    case actionTypes.ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        firstAccessLoading: false,
        firstAccessError: '',
        firstAccessComplete: true,
        user: {
          ...state.user,
          first_access_done: action.first_access_done,
        },
      };
    case actionTypes.GET_USER_INFO_START:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.GET_FORM_INFO_SUCCESS:
      return {
        ...state,
        formInfo: { ...action.info, id_game: action.id, playslog_id: action.playslog_id },
        loadingForm: true,
      };
    case actionTypes.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
      };
    case actionTypes.GET_USER_INFO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.SET_USER_INFO_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SET_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfoUpdateSuccess: true,
        error: null,
        user: {
          ...state.user,
          userinfo: action.data,
        },
      };
    case actionTypes.SET_USER_INFO_ERROR:
      return {
        ...state,
        loading: false,
        userInfoUpdateError: action.error,
      };
    case actionTypes.SET_USER_INFO_CLEAR:
      return {
        ...state,
        loading: false,
        userInfoUpdateSuccess: null,
        userInfoUpdateError: null,
      };
    case actionTypes.USER_ACTIVATION_START:
      return {
        ...state,
        activatedloading: true,
      };
    case actionTypes.USER_ACTIVATION_SUCCESS:
      return {
        ...state,
        activated: true,
        activatedloading: false,
      };
    case actionTypes.USER_ACTIVATION_ERROR:
      return {
        ...state,
        activatederror: action.error,
        activatedloading: false,
      };
    case actionTypes.USER_ACTIVATION_CLEAR_ERROR:
      return {
        ...state,
        activatedloading: false,
        activatederror: null,
      };
    case actionTypes.CHANGE_PASSWORD_START:
      return {
        ...state,
        loadingChangePsw: true,
      };
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loadingChangePsw: false,
        passwordChanged: true,
      };
    case actionTypes.CHANGE_PASSWORD_CLEAR:
      return {
        ...state,
        loadingChangePsw: false,
        passwordChanged: null,
      };
    case actionTypes.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        loadingChangePsw: false,
        errorChangePsw: action.error,
      };
    case actionTypes.CHANGE_PASSWORD_CLEAR_ERROR:
      return {
        ...state,
        loadingChangePsw: false,
        errorChangePsw: null,
      };
    default:
      return state;
  }
};

export default userReducer;
