import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Flex, Text, Button } from '../Ui';
import { numberWithDots } from '../../utils/utils';
import { IoIosArrowForward } from 'react-icons/io';
import { respondTo } from '../../theme/mixin';

const CouponBox = ({ partner, isTurisanda }) => {
  const { title, value, main_image, logo, progress } = partner;

  return (
    <CouponBoxWrapper progressNum={progress}>
      <div className='coupon-image'>
        <img src={logo} alt='' />
      </div>
      <div className='coupon-progress'>
        <div className='coupon-progress-width'></div>
        <Text size={12} className='coupon-progress-num'>
          {numberWithDots(value || 0)} pt
        </Text>
      </div>

      <Text as='p' upper size={10} bold margin='3px 0 0 0' align='center'>
        hai
        <span className='coupon-percent'>{progress}%</span>
        dei punti necessari
      </Text>

      <Text bold size={13} primary align='center' margin='5px 0' className='coupon-title'>
        {title}
      </Text>
      <Link
        to={isTurisanda ? `/premioTurisanda/${partner.id}` : `/viaggi/${partner.id}`}
        className='coupon-link'
      >
        Dettagli <IoIosArrowForward />
      </Link>
    </CouponBoxWrapper>
  );
};

const CouponBoxWrapper = styled.div`
  background-color: #fff;
  transition: ease-in-out 0.4s;
  padding: 10px;
  border-radius: 15px;
  overflow: hidden;
  // box-shadow: ${({ theme }) => theme.shadow};
  height: 100%;
  display: flex;
  flex-direction: column;

  .coupon-image {
    width: 100%;
    height: auto;
    margin-bottom: 2px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: inset 0 0 0 0 #000000;
    transition: ease-in-out 0.4s;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .coupon-progress {
    width: 100%;
    height: 16px;
    background-color: #f1f1f1;
    position: relative;
    border-radius: 5px;
    &-width {
      width: ${({ progressNum }) => (progressNum ? progressNum + '%' : '0px')};
      height: 100%;
      border-radius: inherit;
      background-color: #002d7280;
    }
    &-num {
      color: ${({ theme }) => theme.primary};
      font-weight: bold;
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%);
    }
  }
  .coupon-percent {
    color: ${({ theme }) => theme.primary};
    margin: 0 3px;
  }
  .coupon-title {
    font-weight: bold;
  }
  .coupon-link {
    width: 100%;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primary};
    border-radius: 5px;
    transition: 0.4s;
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    svg {
      font-size: 12px;
      margin-left: 10px;
    }
  }
  &:hover {
    transition: ease-in-out 0s;
    img {
      opacity: 0.7;
    }
    .coupon-image {
      transition: ease-in-out 0s;
      box-shadow: inset 0 0 0 200px #000000;
    }
    .coupon-link {
      background-color: ${({ theme }) => theme.text_link};
      color: #fff;
      cursor: pointer;
    }
    .coupon-title {
      color: ${({ theme }) => theme.text_link};
    }
  }
`;

export default CouponBox;
