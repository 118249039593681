import React from 'react';
import styled from 'styled-components';
import { Button, Text } from '../../../Ui';
import iconParticipe from '../../../../assets/images/boxWin.png';

import { useDispatch } from 'react-redux';
import { getFormInfo } from '../../../../store/actions/user';
import { useHistory } from 'react-router';
import { ChevronRight } from '@styled-icons/boxicons-regular';
import { respondTo } from '../../../../theme/mixin';
const ResultDynamicWrapper = styled.div`
  position: relative;
  text-align: ${({ click }) => (click ? 'left' : 'center')};
  //margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${({ click }) => (click ? 'stretch' : 'center')};
  height: 100%;
  .btnDesk,
  .btnMob {
    width: fit-content;
    margin-bottom: 15px;
    margin-left: 15px;
  }
  .btnDesk {
    display: none;
    ${respondTo.sm` 
        display: block;
    `};
  }
  .btnMob {
    display: block;
    ${respondTo.sm` 
        display: none;
    `};
  }
`;

const ResultDynamic = props => {
  const {
    dynamicData: { title = '', content },
    thePrize: { title: titleWin = '', content: contentWin, extra },
    wingame,
    click,
    playslog_id,
    id_game,
    form,
    //extra,
    formend,
    attemptslimit,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const clickHandler = () => {
    if (form) {
      if (formend) return;
      dispatch(getFormInfo(id_game, playslog_id)).then(() => {
        history.push('/form-win');
      });
    }
  };
  return (
    <ResultDynamicWrapper click={click}>
      <Text primary size={24} bold style={{ ...(click && { padding: '0 15px 15px 15px' }) }}>
        {title + ' ' + titleWin}
      </Text>
      {!formend && (
        <Button active upper hover onClick={clickHandler} className='btnMob'>
          Richiedi subito
        </Button>
      )}
      <img src={click ? wingame : iconParticipe} alt='' />
      <div>
        <Text
          primary
          size={24}
          bold
          style={{
            ...(click && {
              padding: '0 15px 20px 15px',
            }),
          }}
        >
          {extra || content}
        </Text>
        {!formend && (
          <Button active upper hover onClick={clickHandler} className='btnDesk'>
            Richiedi subito
          </Button>
        )}
      </div>
    </ResultDynamicWrapper>
  );
};

export default ResultDynamic;
