import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from '../../../../../Ui';
import { useTranslation } from 'react-i18next';

const CopyButton = props => {
  const [copied, setCopied] = useState(false);
  const { data, active, ...rest } = props;
  const [t] = useTranslation();

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <CopyToClipboard text={data} onCopy={() => setCopied(true)} {...rest}>
      <Button
        active={active}
        copy
        sizeIcon='16px'
        padding='0'
        onClick={e => e.stopPropagation()}
        className='btnHover'
      >
        {copied ? t('games.copied') : t('games.copycode')}
      </Button>
    </CopyToClipboard>
  );
};

CopyButton.propTypes = {
  data: PropTypes.string,
};

export default CopyButton;
