import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { respondTo } from '../../theme/mixin';
import { Button, Modal, Loader, Wrapper, Text } from '../../components/Ui';
import Fade from '../../components/Fade';
import { openOrderModal } from '../../store/actions/products';
import { ChevronRight, X } from '@styled-icons/boxicons-regular';
import { removeFromCart } from '../../store/actions/cart';
import { changeQtyProduct } from '../../store/actions/cart';
import { clearOrder } from '../../store/actions/products';
import ContentBox from '../../components/ContentBox/ContentBox';
import EditProfileStep from '../../layouts/BaseLayout/EditProfileStep';
import BoxLayout from '../../components/Ui/BoxLayout';
import { getProductCart } from '../../store/actions/cart';
import CartForm from '../../components/CartForm/CartForm';
import CartPageSuccess from '../CartPageSuccess/CartPageSuccess';
import Select from '../../components/Ui/Input/Select/Select';
import bgTurisanda from '../../assets/images/turisanda_home_ban.png';

const CartWrapper = styled.div`
  padding-top: 150px;
  margin-left: 15px;
  margin-right: 15px;
  .wish-header {
    display: flex;
    justify-content: space-between;

    span {
      color: #ff7db9;
      font-size: 18px;
      font-weight: 300;
    }
  }
  .description {
    margin-top: 30px;
    * {
      color: white;
    }
  }
  .wish-products {
    .wish-product {
      height: 169px;

      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 15px #aaaaa366;
      border-radius: 16px;
      opacity: 1;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      border-radius: 15px;
      padding: 15px;
      flex-wrap: wrap;

      ${respondTo.md`
        flex-wrap: nowrap;
      `}

      &:nth-child(1) {
        .prod-data:before,
        .prod-actions:before,
        .prod-points:before,
        .prod-percent:before {
          position: absolute;
          top: -15px;
          color: #ff7db9;
          font-weight: 300;
          font-size: 18px;
          display: none;

          ${respondTo.md`
            display: block;
          `}
        }
      }

      .prod-info {
        display: flex;
        align-items: center;
        max-width: 850px;
        flex-grow: 1;
        .prod-image {
          display: flex;
          flex: 0 0 120px;
          background-color: #fff;
          height: 100%;
          overflow: hidden;
          align-items: center;

          ${respondTo.md`
            flex: 0 0 169px;
          `}

          img {
            width: 100%;
            height: auto;
          }
        }

        .prod-data {
          margin-left: 25px;

          ${respondTo.md`
            margin-left: 40px;
          `}

          span {
            color: #e61274;
          }

          a {
            display: block;
            text-transform: uppercase;
            margin-bottom: 10px;
            font-weight: 700;
            color: #3a3c42;
          }
        }
      }

      .prod-text {
        color: #002d72;
        font-size: 12px;
        padding: 3px 7px 2px 7px;
      }

      .prod-actions {
        display: flex;
        text-align: center;

        .prod-act {
          margin: 0 10px;
          cursor: pointer;

          .wish {
            background-color: #adb1bc;
            color: #fff;
          }

          .cart {
            background: transparent linear-gradient(270deg, #ff7db9 0%, #e61274 100%) 0% 0%
              no-repeat padding-box;
          }

          div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45px;
            height: 45px;
            margin: 0 auto;
            border-radius: 50%;
            box-shadow: 0px 5px 15px #00000026;
            margin-bottom: 5px;
          }

          span {
            color: #ff7db9;
            text-transform: uppercase;
            font-size: 9px;
            line-height: 9px;
            font-weight: 700;
            display: block;

            &.remove {
              color: #adb1bc;
            }
          }
        }
      }
    }
  }

  .empty-wishlist {
    color: #002d72;
    font-size: 24px;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      display: block;
    }
  }

  .total-cart {
    width: 100%;
    max-width: 585px;
    margin-left: auto;
    margin-top: 30px;
    padding: 0 15px;

    ${respondTo.md`
      padding: 0;
    `}

    .total-cart-body {
      display: flex;
      justify-content: space-between;

      .left {
        width: calc(50% - 15px);

        h4 {
          color: #e61274;
          font-size: 28px;
          font-weight: 300;
          margin-bottom: 10px;
        }
      }

      .right {
        width: auto;

        li {
          display: flex;
          border-bottom: 1px solid #e4e7f1;
          justify-content: end;
          margin-bottom: 5px;
          gap: 16px;

          span,
          p {
            font-size: 14px;
          }

          &.first {
            span {
              color: #161615;
            }
            p {
              color: #002d72;
              font-size: 16px;
              font-weight: bold;
            }
          }
          &.second {
            border-bottom: 0;
            span,
            p {
              font-size: 16px;
              color: #0a89cb;
            }
            p {
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .inCheckout {
    padding: 0 15px;

    ${respondTo.md`
      padding: 0;
    `}
  }
`;

const ConfirmOrderStep = styled.div``;

const Cart = props => {
  const dispatch = useDispatch();
  const [modalError, setModalError] = useState(false);
  const userCheckError = useSelector(state => state.user.user.newOrder?.error);

  const userCheckErrorOrder = useSelector(state => state.user.user.newOrder?.errorOrder);
  const user = useSelector(state => state.user.user);
  const userCheckErrorString = errorObject => {
    if (!errorObject) {
      return null;
    }

    if (typeof errorObject === 'string') {
      return <div>{errorObject}</div>;
    }

    if (typeof errorObject === 'object') {
      const errorMessages = Object.values(errorObject);
      return (
        <div>
          {errorMessages.map((message, index) => (
            <div key={index}>{message}</div>
          ))}
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    if (userCheckError) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setModalError(true);
    }
  }, [userCheckError]);
  const closeModalError = () => {
    setModalError(false);
  };
  const loadingCart = useSelector(state => state.user.loadingCart);

  const [orderCompleted, setOrderCompleted] = useState(false);
  const [isInCheckout, setIsInCheckout] = useState(false);

  const cart = useSelector(state => state.user.user.cart);
  const cartInfo = useSelector(state => state.user.user.objectCart);
  const userInfo = useSelector(state => state.user.user.userinfo);
  const userPointsTurisanda = useSelector(
    state => state.user.user.points.points_confirmed_turisanda,
  );
  const canCheckout = cartInfo?.can_checkout;
  const newOrder = useSelector(state => state.user.user?.newOrder);

  const [total, setTotal] = useState(0);

  useEffect(() => {
    dispatch(getProductCart());
    if (cart) {
      let tot = 0;
      cart.forEach(prod => {
        tot += prod.pivot.quantity ? prod.points * prod.pivot?.quantity : prod.points;
      });

      setTotal(tot);
    }
  }, [cart]);

  useEffect(() => {
    if (newOrder?.completed) setOrderCompleted(true);
  }, [newOrder]);

  return (
    <>
      <Fade>
        <Wrapper>
          {newOrder?.loading ? (
            <Loader show={true} full />
          ) : (
            <CartWrapper>
              <div className='wish-products'>
                {orderCompleted ? (
                  <CartPageSuccess />
                ) : cart && cart.length > 0 ? (
                  loadingCart ? (
                    <Loader show={loadingCart} />
                  ) : orderCompleted ? (
                    <CartPageSuccess />
                  ) : (
                    cart.map(prod => (
                      <div className='wish-product' key={prod.id}>
                        <div className='prod-info'>
                          <Link to={`/prodottoTurisanda/${prod.id}`} className='prod-image'>
                            <img src={prod.image} alt='' />
                          </Link>

                          <div className='prod-data'>
                            {/* <span>{prod.brand}</span> */}
                            <Link to={`/prodottoTurisanda/${prod.id}`}>
                              {prod.short_description}
                            </Link>
                          </div>
                        </div>
                        <div className='prod-text quantity-pros'>
                          <Select
                            className='quantity'
                            style={{ width: '65px' }}
                            onChange={e => dispatch(changeQtyProduct(prod.id, e.target.value))}
                            value={prod.pivot?.quantity ? prod.pivot?.quantity : 1}
                          >
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(ele => {
                              return (
                                <option key={ele} value={ele}>
                                  {ele}
                                </option>
                              );
                            })}
                          </Select>
                        </div>
                        <div className='prod-text prod-points'>
                          {prod.pivot?.quantity ? prod.points * prod.pivot?.quantity : prod.points}{' '}
                          Pt
                        </div>
                        <div className='prod-actions'>
                          <div
                            className='prod-act'
                            onClick={() => dispatch(removeFromCart(prod.id))}
                          >
                            <div className='wish'>
                              <X size={30} />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )
                ) : (
                  <BoxLayout>
                    <div className='empty-wishlist'>
                      <span>IL TUO CARRELLO È VUOTO </span>
                      <Link to='catalogo'>
                        <Button upper active>
                          Vai al catalogo
                        </Button>
                      </Link>
                    </div>
                  </BoxLayout>
                )}
              </div>
              {!orderCompleted && cart && cart.length > 0 && !isInCheckout && (
                <div className='total-cart'>
                  <ContentBox title='Totale carrello' color='#002d72' noborder>
                    <div className='total-cart-body'>
                      <div className='left'>
                        {userInfo && (
                          <>
                            {userPointsTurisanda - total >= 0 ? (
                              <>
                                <p>
                                  Hai accumulato i punti necessari: puoi ordinare i tuoi premi
                                  preferiti!
                                </p>
                              </>
                            ) : (
                              <>
                                <p>
                                  Non hai punti a sufficenza. Continua ad accumulare punti e ottieni
                                  i tuoi premi preferiti.
                                </p>
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <div className='right'>
                        <ul>
                          <li className='first'>
                            <span>Tot. premi</span>
                            <p>{total} PT</p>
                          </li>
                          <li className='second'>
                            <span>Tuoi punti</span>
                            <p>{userInfo && userPointsTurisanda} PT</p>
                          </li>
                        </ul>
                        {userInfo && userPointsTurisanda - total >= 0 && (
                          <Button
                            upper
                            active
                            gradient={{ left: '#E61274', right: '#FF7DB9' }}
                            hoverGradient={{ left: '#E61274', right: '#E61274' }}
                            style={{ marginLeft: 'auto', marginTop: 34 }}
                            onClick={() => {
                              setIsInCheckout(true);
                            }}
                          >
                            Continua
                          </Button>
                        )}
                      </div>
                    </div>
                  </ContentBox>
                </div>
              )}

              {!orderCompleted && userInfo && isInCheckout && cart && cart.length > 0 && (
                <div className='inCheckout'>
                  <CartForm
                    cartInfo={cartInfo}
                    userInfo={userInfo}
                    closed={() => setIsInCheckout(false)}
                  />
                </div>
              )}
              <BoxLayout
                width='100%'
                title='HAI BISOGNO DI AIUTO?'
                className='description'
                white={'white'}
                bg1={bgTurisanda}
              >
                <Text size={18} color={'white'} margin='0 0 20px 0'>
                  Se hai problemi con un ordine scrivici a premi.turisanda@nextmailing.it
                </Text>

                <Text size={18} color={'white'} margin='0 0 5px 0'>
                  Ti risponderemo al più presto.
                </Text>
              </BoxLayout>
            </CartWrapper>
          )}
        </Wrapper>
      </Fade>
      <Modal show={modalError} close={closeModalError}>
        {userCheckErrorString(userCheckErrorOrder)}
      </Modal>
    </>
  );
};

export default Cart;
