import React, { useEffect } from 'react';
import styled from 'styled-components';
import Fade from '../../components/Fade/Fade';
import Banner from '../../components/banner';
import img from '../../assets/images/bgGuadagnare.png';
import icon from '../../assets/images/iconGuadagnare.svg';
import { Link } from 'react-router-dom';
import { Input, Button, Box, Modal, Flex, Col, Text, Wrapper } from '../../components/Ui';
import ContentSlide from '../../components/ContentSlide';
import { respondTo } from '../../theme/mixin';
import BoxLayout from '../../components/Ui/BoxLayout';
import { t } from 'i18next';
import { useLastLocation } from 'react-router-last-location';

const HowToEarn = () => {
  const lastLocation = useLastLocation();

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#puntiextra') {
      window.scrollTo({
        top: document.querySelector('#puntiextra').offsetTop + 450,
        behavior: 'smooth',
      });
    } else if (hash === '#accumulobase') {
      window.scrollTo({
        top: document.querySelector('#accumulobase').offsetTop + 450,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: 0,
      });
    }
  }, []);

  const list = [
    {
      text1: 'WELCOME BONUS - ISCRIZIONE AL PROGRAMMA',
      point: '250 PT',
      text2: 'Una tantum',
    },
    {
      text1:
        '<a href="https://www.alpitour.it/my-alpitour-world/profilo/dettagli.html" target="_blank">COMPLETAMENTO PROFILO 100 % - AREA RISERVATA</a>',
      point: '200 PT',
      text2: 'Una tantum al completamento al 100% del profilo',
    },
    {
      text1: 'INVITA UN AMICO AL PROGRAMMA LOYALTY',
      point: '100 PT',
      text2: 'Per ogni amico invitato che si iscriverà al programma e fino ad un massimo di 3',
    },
    {
      text1: 'SE IL TUO AMICO PRENOTA UNA VACANZA',
      point: '150 PT',
      text2:
        'Per ogni amico invitato che prenoterà una vacanza / viaggio e fino ad un massimo di 3',
    },
    {
      text1: 'QUESTIONARIO QUALITÀ POST VACANZA',
      point: '70 PT',
      text2: 'Per ogni vacanza al completamento di max 1 questionario',
    },
    {
      text1:
        '<a href="https://www.alpitour.it/my-alpitour-world/lista-convenzioni.html" target="_blank">ATTIVAZIONE BUONO VGUT PER NGUT (GRANDI UTENZE)</a>',
      point: '50 PT',
      text2: 'Una tantum - la prima attivazione',
    },
    {
      text1:
        '<a href="https://www.alpitour.it/my-alpitour-world/buoni-sconto.html" target="_blank">ATTIVAZIONE BUONO LISTA NOZZE PARTECIPANTI</a>',
      point: '70 PT',
      text2: 'Una tantum - la prima attivazione',
    },
    {
      text1:
        '<a href="https://www.alpitour.it/my-alpitour-world/buoni-sconto.html" target="_blank">ATTIVAZIONE BUONO LISTA NOZZE SPOSI</a>',
      point: '50 PT',
      text2: 'Una tantum - la prima attivazione',
    },
    {
      text1:
        '<a href="https://www.alpitour.it/trova-agenzie.html" target="_blank">ASSOCIAZIONE AGENZIA PREFERITA</a>',
      point: '30 PT',
      text2: 'Una tantum - la prima attivazione',
    },
    {
      text1: 'ADESIONE A “PROMOZIONI FLASH”',
      point: '500 PT',
      text2:
        'Per ogni acquisto di “Promozioni Flash” ed effettiva partenza del Titolare della pratica',
    },
    {
      text1: 'PARTECIPAZIONE AL GIOCO DEL VENERDI’ NELL’AMBITO DEL CONCORSO “SUNNY FRIDAY” *',
      point: '10 PT',
      text2:
        'Partecipazione al concorso a premi “SUNNY FRIDAY” tramite sessione di gioco del venerdì sul sito youandsun.it',
    },
  ];
  const base = [
    { range: '1001€', a: '1100€', point: '1100PT' },
    { range: '1101€', a: '1200€', point: '1200PT' },
    { range: '1201€', a: '1300€', point: '1300PT' },
    { range: '1301€', a: '1400€', point: '1400PT' },
    { range: '1401€', a: '1500€', point: '1500PT' },
    { range: '1501€', a: '1600€', point: '1600PT' },
    { range: '1601€', a: '1700€', point: '1700PT' },
    { range: '1701€', a: '1800€', point: '1800PT' },
    { range: '1801€', a: '1900€', point: '1900PT' },
    { range: '1901€', a: '2000€', point: '2000PT' },
  ];
  return (
    <Fade>
      <Wrapper>
        <Banner>
          <HiwBanner>
            <ContentSlide
              title='Come guadagnare punti'
              text='Scopri tutte le azioni che ti permettono di accumulare punti.'
              img={img}
              icon={icon}
              noBtn
              linear
              size={30}
              xBold='800'
              textBold
            />
          </HiwBanner>
        </Banner>
        <Content>
          <BoxLayout noMinHeight title='ACCUMULO BASE' id='accumulobase'>
            <strong>
              <i>Tabella esemplificativa conversione punti *</i>
            </strong>
            <CustomFlex justify='space-between' align='center' gap='10px' wrap='nowrap'>
              <Col width={20}>
                <Text size={11} bold upper primary>
                  RANGE DI SPESA DA:
                </Text>
              </Col>
              <Col width={50}>
                <Text size={11} bold upper primary>
                  A:
                </Text>
              </Col>
              <Col width={30}>
                <Text size={11} bold upper primary>
                  PUNTI ASSEGNATI
                </Text>
              </Col>
            </CustomFlex>
            {base.map((item, i) => (
              <CustomFlex key={i} justify='space-between' align='center' gap='10px' wrap='nowrap'>
                <Col width={20}>
                  <Text size={11} bold upper>
                    {item.range}
                  </Text>
                </Col>
                <Col width={50}>
                  <Text size={11} bold upper>
                    {item.a}
                  </Text>
                </Col>
                <Col width={30}>
                  <Text size={11} bold upper>
                    {item.point}
                  </Text>
                </Col>
              </CustomFlex>
            ))}
            <Text size={11} bold className='mt-20'>
              * LE FASCE DI PREZZO NON HANNO LIMITE ED I PUNTI VENGONO CALCOLATI SULLA BASE DI
              SCAGLIONI DI 100PUNTI CIASCUNA
            </Text>
          </BoxLayout>
          <BoxLayout noMinHeight title='PUNTI EXTRA' style={{ marginTop: '40px' }} id='puntiextra'>
            {list.map((item, i) => (
              <CustomFlex key={i} justify='space-between' align='center' gap='10px'>
                <Text size={11} bold upper width='40%' className='text1'>
                  {item.text1}
                </Text>
                <Text size={11} bold upper primary width='5%'>
                  {item.point}
                </Text>
                <Text capit bold size={11} width='50%'>
                  {item.text2}
                </Text>
              </CustomFlex>
            ))}
            <Text size={11} bold className='mt-20' upper>
              *La partecipazione al concorso non ha limiti, ma permette di ricevere i punti solo per
              le prime 3 sessioni di gioco effettuate, per un massimo di 30 punti
            </Text>
          </BoxLayout>

          <Flex justify='space-between' align='center' style={{ marginTop: '40px' }}>
            <a
              href={`${process.env.PUBLIC_URL}/pdf/regolamento.pdf`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button active className='btn-scarica' hover>
                SCARICA IL REGOLAMENTO
              </Button>
            </a>
            <Link to={lastLocation?.pathname}>
              <Button border className='btn-aiuto' hoverGrey>
                INDIETRO
              </Button>
            </Link>
          </Flex>
        </Content>
      </Wrapper>
    </Fade>
  );
};
const HiwBanner = styled.div`
  .hiw-img {
    object-position: 7%;
    ${respondTo.sm` 
      object-position: initial;
    `};
  }
`;
const Content = styled.div`
  background-color: transparent;
  z-index: 5;
  position: relative;
  margin-bottom: 30px;

  ${respondTo.sm` 
    margin-top: -60px;
  `};
`;
const CustomFlex = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.borderCode};
  padding: 5px 0;
  margin-top: 10px;
  .text1 {
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export default HowToEarn;
