import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { respondTo } from '../../../../../theme/mixin';
import { Button, Box, Text, Flex } from '../../../../Ui';
import { Link } from 'react-router-dom';
import { acceptGameRules } from '../../../../../store/actions/game';
import customAxios from '../../../../../config/axios-refresh-token';
import Switch from 'react-switch';
import { StartGame } from '../../../../Ui';
const DivContainerRegola = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 150px 15px;
  max-width: 270px;
  gap: 55px;
`;
const LinkRegola = styled.a`
  text-decoration: underline;
  color: #002d72;
  font-size: 12px;
  font-weight: bold;
`;
const OverlayRegola = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  border-radius: 15px;
`;
const Cont = styled.div`
  justify-content: center;
  display: flex;
  gap: 32px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const Cont2 = styled.div`
  flex: 1;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 2px 15px #00000029;
  padding: 21px 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex: auto;
  }
`;
const Cont3 = styled.div`
  flex: 1;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 2px 15px #00000029;
  padding: 21px 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TextContainer = styled.div`
  margin-bottom: 22px;
  ${respondTo.sm`
		margin-bottom: 30px;
	`}
`;

const StartGameQuiz = props => {
  const { clicked, infoPages, info } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false);
  const [regolaAccepted, setRegolaAccepted] = useState(false);
  const { id: idGame, i18l: i14l } = useSelector(state => state.game?.games?.[0] || {});
  const idGameTo = useSelector(state => state.promo.game[0].id);

  const gamePrivacyAccepted = useSelector(state => state.promo.game[0].gamePrivacyAccepted);
  const [isLoading, setIsLoading] = useState(false);

  const promo = useSelector(state => state.promo);

  const { pdf_regolamento } = promo || {};
  const { slug } = useParams();

  const regulation_pdf = info.regolamento;
  const clickHandler = () => {
    setChecked(true);
    dispatch(acceptGameRules(idGameTo, slug)).then(() => setRegolaAccepted(true));
  };
  // useEffect(() => {
  //   if (idGameTo) {
  //     // let endpoint = `game/${slug}/${idGameTo}/acceptGameRules`;
  //     // customAxios
  //     //   .post(endpoint)
  //     //   .then(response => {
  //     //     // dispatch(acceptGameRulesSuccess());
  //     //     setIsLoading(false);
  //     //   })
  //     //   .catch(error => {
  //     //     setIsLoading(false);
  //     //   });
  //   }
  // }, [idGameTo, gamePrivacyAccepted]);
  return gamePrivacyAccepted ? (
    <StartGame clicked={clicked} />
  ) : (
    <Cont>
      <Cont2>
        <TextContainer>
          <Text as='p' size={24} upper color='#002d72'>
            {infoPages.i18l.how_play.title}
          </Text>
          <p>{infoPages.i18l.how_play.content}</p>
        </TextContainer>
        <Flex justify='space-between' gap='10px' align='center'>
          <a href={regulation_pdf} target='_blank' rel='noopener noreferrer'>
            <Button active hover>
              SCARICA IL REGOLAMENTO
            </Button>
          </a>
          <Link to='/contact/#faq'>
            <Button border hoverGrey>
              BISOGNO D'AIUTO?
            </Button>
          </Link>
        </Flex>
      </Cont2>
      <Cont3>
        {gamePrivacyAccepted !== undefined && (
          <DivContainerRegola>
            <Text bold className='text-regola'>
              Accetta il regolamento per partecipare al concorso.
            </Text>
            <OverlayRegola className='overlay_regola'>
              <Flex className='regola' justify='space-between' align='center' gap='10px'>
                <LinkRegola href={regulation_pdf} target='_blank' rel='noopener noreferrer'>
                  Regolamento
                </LinkRegola>

                <Switch
                  checked={checked}
                  onChange={clickHandler}
                  onColor='#86d3ff'
                  onHandleColor='#2693e6'
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                  activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                  height={20}
                  width={48}
                  className='react-switch'
                  id='material-switch'
                />
              </Flex>
            </OverlayRegola>
          </DivContainerRegola>
        )}
      </Cont3>
    </Cont>
  );
};

export default StartGameQuiz;
