export const breakpoints = {
  xs: '1rem',
  xmed: '26.25rem',
  med: '37.5rem',
  sm: '50rem',
  md60: '60rem',
  md: '64rem',
  ml: '67rem',
  md78: '78rem',
  lg: '90rem',
  xl: '120rem',
};
