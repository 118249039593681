import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Loader, CountDown, Steps } from '../../../Ui';
import CountDownSurvey from './CountDownSurvey/CountDownSurvey';
import StepsSurvey from './StepsSurvey';
import StartGameQuiz from './StartGameQuiz/StartGameQuiz';
import Fade from '../../../Fade';
import Answers from './Answers';
import Question from './Question';
import styled from 'styled-components';
import { respondTo } from '../../../../theme/mixin';
import Dynamic from '../../Dynamics/Dynamic';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { isBetween, getSlug, getElement } from '../../../../utils/utils';
import { FastForward } from 'styled-icons/boxicons-regular';
import { darken, lighten } from 'polished';
import { ChevronRight } from 'styled-icons/boxicons-regular';
import customAxios from '../../../../config/axios-refresh-token';
import ThankYouPage from '../../Handlers/ThankYouPage/ThankYouPage';
import Ig from '../../../Shares/Ig/Ig';
import { CodeS } from 'styled-icons/remix-line';
const Container = styled.div`
  background-image: ${props => `url(${props.bg})`};
  background-position: center center;
  box-sizing: border-box;
  overflow: auto;
  flex: 1;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-flow: column;
  /*   width: 100%;

  height: ${({ height }) => height}px; */
  height: 100%;
  min-height: 360px;
  ${respondTo.sm`
 height: 100%
  `}
`;

const QuizContainer = styled.div`
  margin: 0px auto;
  padding: 0px;
  max-width: 100%;
  padding-bottom: 10px;
  min-height: 640px;
  position: relative;
  box-sizing: border-box;

  ${respondTo.med`
   
   
    min-height: 640px;
    position: relative;
    box-sizing: border-box;
  `};
  ${respondTo.sm`
   
  `};
  ${respondTo.md`
   
  `};
`;

const SlideContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .container-answers-stepper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .container-question {
    display: flex;
    gap: 30px;
    > div {
      background: white;
      width: 50%;
      border: solid 1px transparent;
      border-radius: 16px;
      box-shadow: 0px 5px 15px #00000026;
    }
    @media (max-width: 768px) {
      flex-wrap: wrap;
      > div {
        width: 100%;
      }
    }
  }
`;

const StepsContainer = styled.div``;

const StepsCountContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 12px auto 0;
  padding: 16px 24px;
  justify-content: ${props => (props.isTimer ? 'space-between' : 'flex-end')};
`;
const CountContainer = styled.div`
  display: ${props => (props.isMobile ? 'none' : 'flex')};
  align-items: center;
  position: absolute;
  z-index: 10;
  right: 20px;
  margin: 12px auto 0;

  justify-content: ${props => (props.isTimer ? 'space-between' : 'flex-end')};
  @media (max-width: 768px) {
    display: ${props => (props.isMobile ? 'flex' : 'none')};
  }
`;
const NextContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #0a89cb;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  & span {
    display: block;
  }
  & svg {
    margin-left: 3px;
  }
  &:hover {
    background-color: ${props => darken(0.1, '#0a89cb')};
  }
`;
const ConfirmContainer = styled.div`
  height: 32px;
  width: 142px;
  border-radius: 5px;
  background-color: #002d72;
  text-transform: uppercase;

  &:hover {
    background-color: ${props => darken(0.1, '#002d72')};
    border-radius: 5px;
  }
`;

const NextContainerConfirm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  font-size: 12px;
  border-radius: 5px;
  color: white;
  text-align: center;
  cursor: pointer;
  pointer-events: ${props => (props.disabled ? 'none' : '')};
  background: ${props => (props.disabled ? darken(0.1, props.theme.secondary) : '#002d72')};

  & span {
    display: block;
    font-size: 12px;
    margin-top: -8px;
  }
  & svg {
    margin-left: 3px;
  }
  &:hover {
    border-radius: 5px;
    background-color: ${props => darken(0.1, '#002d72')};
  }
`;
const ContainerTimerDone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  font-size: 12px;
  color: white;
  text-align: center;
  cursor: pointer;
  background: white;
  color: #002d72;
  border: solid 1px #002d72;
  & span {
    display: block;
    font-size: 12px;
    margin-top: -8px;
  }
  & svg {
    margin-left: 3px;
  }
  // &:hover {
  //   border-radius: 5px;
  //   background-color: ${props => darken(0.1, '#002d72')};
  // }
  width: 142px;
  border-radius: 5px;
  text-transform: uppercase;
`;
const SurveyQuiz = props => {
  const {
    lang,
    typeInteraction,
    onShowResult,
    onShowThankYouPage,
    infoPages,
    g: {
      interaction,
      preinteraction,
      id,
      log: {
        sid,
        steps: {
          interaction: { data: dataLoose },
        },
      },
    },
    action: { data: dataAction },
  } = props;

  let rules = JSON.parse(interaction.data);

  if (typeInteraction === 'preinteraction') {
    rules = JSON.parse(preinteraction.data);
  }
  let slug = getSlug();

  const {
    id: idGame,
    type,
    settings,
    settings: {
      instantcheck,
      selectedcheck,
      showtrueanswer,
      max,
      random,
      time,
      timer,
      next,
      steps,
      onlywin,
      aspect: { bggame, colorWin, colorLoose, colorAnswers },
    },
    g,
    i18l: {
      [lang]: { results, game },
    },
  } = rules;

  const carRef = useRef(null);
  const [contentThankYouPage, setContentThankYouPage] = useState(null);
  const [showThankYouPage, setshowThankYouPage] = useState(false);
  const onShowResultThankyoupage = (el, act) => {
    setContentThankYouPage(el, act);
  };
  const [cont, setCont] = useState([]);

  const [selected, setSelected] = useState([]);
  const [resultData, setResultData] = useState({});
  const [answers, setAnswers] = useState([]);
  const [index, setIndex] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [startCountDown, setStartCountDown] = useState(false);

  const [startGame, setStartGame] = useState(!timer);
  const [step, setStep] = useState(1);
  const [timeComplete, setTimeCompleted] = useState(false);
  const [pause, setPause] = useState(false);
  const [goToNextByClick, setGoToNextByClick] = useState(false);
  const [action, setAction] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [selectedAnsw, setSelectedAnsw] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [objAnswerSel, setObjAnswerSel] = useState(null);
  const [remainingTime, setRemainingTime] = useState(0);
  const nextIndex = () => {
    setIndex(index + 1);
  };
  const nextHandler = () => {
    setCurrentSlide(currentSlide + 1);
    setStep(step + 1);

    setCurrentQuestion(selected[step]);

    setObjAnswerSel(null);
    setTimeCompleted(false);
    setStartCountDown(true);
  };

  const changeSlideHandler = () => {
    setTimeout(() => {
      setStartCountDown(true);
      setStep(step + 1);
      setCurrentSlide(currentSlide + 1);

      setObjAnswerSel(null);
      setPause(false);
      setGoToNextByClick(false);
    }, 800);
  };
  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  const startCountDownHandler = () => {
    setStartCountDown(true);
  };
  const formatTime = time => {
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    const milliseconds = Math.floor((time % 1000) / 10); // Aggiornato per ottenere i millisecondi come due cifre

    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}:${milliseconds.toString().padStart(2, '0')}`;
  };

  const renderer = ({ minutes, seconds, milliseconds, completed }) => {
    setRemainingTime(minutes * 60000 + seconds * 1000 + milliseconds);
    if (completed) {
      if (answers.length === currentSlide) {
        let isQuestionIdPresent = false;
        if (objAnswerSel) {
          isQuestionIdPresent = answers.some(obj => +obj.questionid === +objAnswerSel.questionid);
        }
        if (!isQuestionIdPresent) {
          setAnswers(prevState => [
            ...prevState,
            { questionid: `${currentQuestion.id}`, points: '0' },
          ]);
        }
      }

      setTimeCompleted(true);
      return <div>00:00:00</div>;
    } else {
      return <div>{formatTime(minutes * 60000 + seconds * 1000 + milliseconds)}</div>;
    }
  };

  const startGameHandler = () => {
    setStartGame(true);
    startCountDownHandler();
  };

  const updateCurrentSlide = i => {
    if (currentSlide !== i) {
      setCurrentSlide(i);
    }
  };

  const selectAnswerHandler = (e, obj) => {
    setPause(true);
    setDisabled(false);
    setSelectedAnswer(obj.answerid);
    setObjAnswerSel(obj);
    setSelectedAnsw(obj.answerid);
    const existingAnswer = answers.find(answer => +answer.questionid === +obj.questionid);
    if (existingAnswer) {
      let updatedAnswers = answers.filter(answer => +answer.questionid !== +obj.questionid);
      updatedAnswers.push(obj);
      setAnswers(updatedAnswers);
    } else {
      let updatedAnswers2 = answers.filter(answer => +answer.questionid !== +obj.questionid);
      updatedAnswers2.push(obj);
      setAnswers(updatedAnswers2);
    }
  };

  const checkFinish = (answers, selected) => {
    if (answers.length > 0) {
      if (answers.length === selected.length) {
      } else {
        nextIndex();
        if (next === 'auto') {
          nextHandler();
        }
      }
    }
  };

  const createResult = answers => {
    if (type === 'quiz') {
      const totalPoints = answers.reduce(function (prev, current) {
        return prev + parseInt(current.points);
      }, 0);

      const haveYouWin = answers.filter(a => a.result === '1');
      const rightanswers = { rightanswers: haveYouWin.length.toString() };
      const totalquestions = { totalquestions: answers.length.toString() };
      const haveyouwin = { haveyouwin: haveYouWin };

      const won = haveYouWin.length === selected.length ? true : false;

      for (const prop in results) {
        const result = isBetween(
          totalPoints,
          parseInt(prop.split('-')[0]),
          parseInt(prop.split('-')[1]),
        );
        if (result) {
          const data = {
            profile: { ...results[prop] },
            attempts: rightanswers.rightanswers,
            nAttempts: totalquestions.totalquestions,
            onlywin,
            won,
            answers,
          };
          setResultData(data);
        }
      }
    }
    if (type === 'survey') {
      let p = answers.map(a => a.points);
      let r = p.reduce(
        (b, c) => (
          (b[b.findIndex(d => d.el === c)] || b[b.push({ el: c, count: 0 }) - 1]).count++, b
        ),
        [],
      );
      let max = Math.max.apply(
        Math,
        r.map(o => o.count),
      );
      let last = r.filter(r => r.count === max);
      let el = null;

      if (last.length > 0) {
        if (last.length === 1) {
          el = last[0].el;
        } else {
          let elArr = last.reduce(function (prev, curr) {
            return prev.el < curr.el ? prev : curr;
          });
          el = elArr.el;
        }
      }

      const data = {
        profile: { ...results[el] },
        answers,
      };

      setResultData(data);
    }
  };

  useEffect(() => {
    init();
    return () => {};
  }, []);

  const init = () => {
    if (random) {
      let shuffled = game.sort(() => 0.5 - Math.random());

      setSelected(shuffled.slice(0, max));
    } else {
      setSelected(game);
    }
  };

  useEffect(() => {
    let d = [];
    if (selected.length > 0) {
      setCurrentQuestion(selected[step - 1]);

      d = selected.map((el, key) => {
        return (
          <SlideContainer key={el.id}>
            <div className='container-question'>
              <Question media={el.media} pause={pause}>
                {el.text}
              </Question>
              <div className='container-answers-stepper'>
                <Answers
                  textQuestion={el.text}
                  colorAnswers={colorAnswers}
                  colorWin={colorWin}
                  colorLoose={colorLoose}
                  options={el.options}
                  id={el.id}
                  index={key}
                  clicked={selectAnswerHandler}
                  instantcheck={instantcheck}
                  selectedcheck={selectedcheck}
                  showtrueanswer={showtrueanswer}
                  isStartDisabled={timer}
                  currentSlide={currentSlide}
                  completed={timeComplete}
                  selectedAnswer={selectedAnswer}
                  selectedAnsw={selectedAnsw}
                />

                <CountContainer isTimer={timer} isMobile={isMobile}>
                  {timer ? (
                    timeComplete ? (
                      next !== 'auto' ? (
                        <ContainerTimerDone disabled={true}>00:00:00</ContainerTimerDone>
                      ) : null
                    ) : (
                      <CountDownSurvey
                        start={startCountDown}
                        time={time}
                        startgame={startGameHandler}
                        rend={renderer}
                        next={next}
                        step={step}
                        timeComplete={timeComplete}
                        gotonext={nextHandlerFromClick}
                        goToNextByClick={goToNextByClick}
                        remainingTime={remainingTime}
                      />
                    )
                  ) : null}
                </CountContainer>

                <StepsCountContainer isTimer={timer}>
                  {steps ? (
                    <StepsContainer>
                      <StepsSurvey current={step} total={selected.length} />
                    </StepsContainer>
                  ) : null}
                  {timer ? (
                    timeComplete ? (
                      next !== 'auto' ? (
                        <ConfirmContainer>
                          <NextContainerConfirm
                            onClick={nextHandlerFromClick}
                            disabled={goToNextByClick}
                          >
                            Conferma <ChevronRight size={25} />
                          </NextContainerConfirm>
                        </ConfirmContainer>
                      ) : null
                    ) : (
                      <ConfirmContainer isTime={startCountDown}>
                        <NextContainerConfirm onClick={nextHandlerFromClick} disabled={disabled}>
                          Conferma
                          <ChevronRight size={25} />
                        </NextContainerConfirm>
                      </ConfirmContainer>
                    )
                  ) : null}
                </StepsCountContainer>
              </div>
            </div>
          </SlideContainer>
        );
      });
    }

    setCont(d);
    if (timeComplete) {
      setDisabled(false);
      setGoToNextByClick(false);
    }
  }, [
    selected,
    timeComplete,
    pause,
    selectedAnswer,
    selectedAnsw,
    answers,
    currentQuestion,
    startCountDown,
  ]);

  useEffect(() => {
    setTimeout(() => {
      checkFinish(answers, selected);
    }, 500);

    setStartCountDown(false);
  }, [answers]);

  const nextHandlerFromClick = () => {
    if (next === 'click') {
      setSelectedAnsw(null);
      setSelectedAnswer(null);
      setGoToNextByClick(true);
      setDisabled(true);
      if (answers.length === selected.length) {
        setTimeout(() => {
          createResult(answers);
        }, 250);
      }
    }
  };

  useEffect(() => {
    if (goToNextByClick) {
      nextHandler();
    } else {
      return;
    }
  }, [goToNextByClick]);

  useEffect(() => {
    if (Object.entries(resultData).length !== 0) {
      setAction(true);
      // onShowThankYouPage();
      setshowThankYouPage(true);
      onShowResultThankyoupage(resultData);
    }
  }, [resultData]);

  useEffect(() => {
    if (dataAction) {
      if (dataAction.length > 0) {
        let isData = getElement(dataAction, id);
        if (isData) {
          onShowResult();
        }
      }
    }
  }, [dataAction]);

  const [widthImg, setWidthImg] = useState(0);
  const [heightImg, setHeightImg] = useState(0);
  //recuperer le height et le width de l image pour le metre dans container

  /*   let imgSize = new Image();
  imgSize.src = bggame;

  imgSize.onload = () => {
    setWidthImg(imgSize.width);
    setHeightImg(imgSize.height);
  }; */

  return (
    <React.Fragment>
      {showThankYouPage ? (
        <ThankYouPage {...props} contentThankYouPage={contentThankYouPage} />
      ) : cont.length > 0 ? (
        <Container bg={bggame}>
          {startGame ? (
            <QuizContainer>
              <Fade show={true}>
                <CountContainer isTimer={timer} isMobile={!isMobile}>
                  {timer ? (
                    timeComplete ? (
                      next !== 'auto' ? (
                        <ContainerTimerDone disabled={true}>00:00:00</ContainerTimerDone>
                      ) : null
                    ) : (
                      <CountDownSurvey
                        start={startCountDown}
                        time={time}
                        startgame={startGameHandler}
                        rend={renderer}
                        next={next}
                        step={step}
                        timeComplete={timeComplete}
                        gotonext={nextHandlerFromClick}
                        goToNextByClick={goToNextByClick}
                        remainingTime={remainingTime}
                      />
                    )
                  ) : null}
                </CountContainer>

                <Carousel
                  dynamicHeight={true}
                  selectedItem={currentSlide}
                  // onChange={updateCurrentSlide}
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                  showIndicators={false}
                  swipeable={false}
                  ref={carRef}
                  onChange={changeSlideHandler}
                >
                  {cont}
                </Carousel>
              </Fade>
            </QuizContainer>
          ) : (
            <StartGameQuiz infoPages={infoPages} info={props} clicked={startGameHandler} />
          )}
        </Container>
      ) : (
        <Loader show={true} full />
      )}

      {action ? (
        <Dynamic
          id={id}
          action={props.action}
          slug={slug}
          section={typeInteraction}
          resultData={resultData}
          sid={sid}
          selected={selected}
          {...props}
        />
      ) : null}
    </React.Fragment>
  );
};

SurveyQuiz.propTypes = {
  option: PropTypes.string,
  id: PropTypes.number,
};

export default SurveyQuiz;
