import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const GoogleAnalytics = props => {
  const { ga } = props;

  return <Helmet></Helmet>;
};

GoogleAnalytics.propTypes = {
  ga: PropTypes.string,
};

export default GoogleAnalytics;
