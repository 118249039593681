import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex, Col, Text, Button, Wrapper } from '../../components/Ui';
import Fade from '../../components/Fade/Fade';
import { useDispatch, useSelector } from 'react-redux';
import Banner from '../../components/banner';
import ContentSlide from '../../components/ContentSlide';
import img from '../../assets/images/boxTravelGame.png';
import { hotjar } from 'react-hotjar';

import sunnyFridayHeroImg from '../../assets/images/sunny-friday-hero.png';
import sunnyFridayHeroMobileImg from '../../assets/images/sunny-friday-hero-mobile.png';

import icon from '../../assets/images/travelGame.svg';
import iconParticipe from '../../assets/images/boxGame.png';
import { ReactComponent as HandOpen } from '../../assets/images/handOpen.svg';
import { respondTo } from '../../theme/mixin';
import BoxLayout from '../../components/Ui/BoxLayout';
import customAxios from '../../config/axios-refresh-token';
import Game from '../Game/Game';
import Switch from 'react-switch';
import { getUserInfo } from '../../store/actions';
import { Link } from 'react-router-dom';
import SunnyBoxPrizes from '../../components/SunnyBoxPrizes/SunnyBoxPrizes';

const SunnyFriday = () => {
  const dispatch = useDispatch();

  const { games } = useSelector(state => state.game || {});
  const extra = useSelector(state => state.user.user.userinfo?.extra || null);

  let slug = 'sunny-friday';
  const [isregula, setIsregula] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (extra && games.length > 0) {
      const newExtra = JSON.parse(extra);
      if (newExtra.accepted_privacy.find(id => id === games[0]?.id)) {
        setIsregula(false);
      } else {
        setIsregula(true);
      }
    }
  }, [extra, games]);

  useEffect(() => {
    hotjar.initialize(3032126, 6);
  }, []);

  const clickHandler = () => {
    setChecked(true);
    let endpoint = `/game/${slug}/${games[0].id}/acceptpromotionprivacy`;
    customAxios.post(endpoint).then(() => {
      dispatch(getUserInfo());
      setIsregula(false);
    });
  };

  return (
    <Fade>
      <Wrapper>
        <Banner>
          <HiwBanner>
            <ContentSlide
              title='Sunny Friday'
              text='Ogni venerdì prova a vincere la tua prossima vacanza.'
              img={sunnyFridayHeroImg}
              icon={icon}
              noBtn
              size={30}
              xBold='800'
              textBold
              imgMobile={sunnyFridayHeroMobileImg}
            />
          </HiwBanner>
        </Banner>
        <CustomFlex align='stretch' justify='center'>
          <Col padding={10} className='sm_mt-20 ' stype={{ flex: 1 }} width={50}>
            <Flex align='center' direction='column' style={{ height: '100%' }}>
              <SunnyBoxPrizes
                title='I PREMI'
                width='100%'
                style={{ flex: 1 }}
                noMinHeight
                game
                lancio
                fromConcorso
              />

              <BoxLayout title='COME GIOCARE' noMinHeight style={{ marginTop: '20px' }}>
                <Text size={16} margin='0 0 25px 0'>
                  Accetta il regolamento e tenta la fortuna.
                  <br />
                  <br /> Clicca sul pacchetto regalo per scoprire subito se hai vinto. Il tuo
                  prossimo viaggio è ad un solo click di distanza!
                  <br />
                  Non hai vinto? Ritenta Venerdì prossimo!
                </Text>
                <Flex justify='space-between' align='center'>
                  <a
                    href={`${process.env.PUBLIC_URL}/pdf/regolamento_sunny_friday.pdf`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Button active className='btn-scarica' hover>
                      SCARICA IL REGOLAMENTO
                    </Button>
                  </a>
                  <Link to='/contact/#faq'>
                    <Button border className='btn-aiuto' hoverGrey>
                      BISOGNO D'AIUTO?
                    </Button>
                  </Link>
                </Flex>
              </BoxLayout>
            </Flex>
          </Col>
          <Col padding={10} width={50} className='sm_mt-20'>
            <BoxLayout title='Partecipa' height='100%'>
              {!isregula ? (
                <Game sunnyFriday />
              ) : (
                <>
                  <Text primary size={16}>
                    Clicca sul pacchetto e scopri subito se hai vinto.
                  </Text>
                  <div className='content-participe'>
                    <img src={iconParticipe} alt='' />
                    <div className='overlay'>
                      <HandOpen />
                    </div>
                  </div>

                  <div className='overlay_regola'>
                    <Text bold size={22}>
                      Accetta il regolamento per
                      <br />
                      partecipare al concorso.
                    </Text>
                    <Flex className='regola' align='center'>
                      <a
                        href={`${process.env.PUBLIC_URL}/pdf/regolamento_sunny_friday.pdf`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Regolamento
                      </a>

                      <Switch
                        checked={checked}
                        onChange={clickHandler}
                        onColor='#86d3ff'
                        onHandleColor='#2693e6'
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                        activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                        height={20}
                        width={48}
                        className='react-switch'
                        id='material-switch'
                      />
                    </Flex>
                  </div>
                </>
              )}
            </BoxLayout>
          </Col>
        </CustomFlex>
      </Wrapper>
    </Fade>
  );
};
const CustomFlex = styled(Flex)`
  margin-bottom: 30px;
  background-color: transparent;
  z-index: 5;
  position: relative;
  flex-direction: column-reverse;

  ${respondTo.sm` 
    flex-direction: row;
  `};

  .overlay_regola {
    background-color: #fffefee0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
  }
  .regola {
    justify-content: space-between;
    background-color: #fff;
    padding: 10px 20px;
    max-width: 300px;
    width: 100%;
    margin-top: 20px;
    border-radius: 50px;
    box-shadow: 0px 5px 15px #00000026;

    a {
      color: ${props => props.theme.primary};
      font-weight: 700;
      text-decoration: underline;
    }
  }
  .travel-premi {
    margin-bottom: 0;
    margin-top: 60px;
    text-align: center;
    display: block;

    ${respondTo.sm` 
    text-align: initial;
      margin-bottom: 70px;
      margin-top: auto;
      width:initial;
    `};
  }
  .btn-scarica {
    order: 1;
    width: 100%;
    display: block;
    text-align: center;
    ${respondTo.sm` 
     order:0;
     width:initial;
     text-align: initial;
    `};
  }
  .btn-aiuto {
    order: 0;
    width: 100%;
    display: block;
    text-align: center;
    margin-bottom: 10px;
    ${respondTo.sm` 
     order:1;
     width:initial;
     text-align: initial;
     margin-bottom: initial;
    `};
  }
  .content-participe {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
    }
  }
  .overlay {
    position: absolute;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: #ffffff99;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    padding: 15px;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${respondTo.sm` 
     top: 50%;
       padding: 20px;
    width: 100px;
    height: 100px;
    `};
  }
  ${respondTo.sm` 
      margin-top: -60px;
    `};
`;
const HiwBanner = styled.div`
  .hiw-img {
    object-position: 7%;
    ${respondTo.sm` 
      object-position: initial;
    `};
  }
`;

export default SunnyFriday;
