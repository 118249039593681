import React from 'react';
import styled from 'styled-components';
import { Text, Flex, Button } from '../../components/Ui';
import { respondTo } from '../../theme/mixin';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const YourSituationModule = () => {
  const points = useSelector(state => state.user.user.points);

  return (
    <YourSituationWrapper>
      <Text light size={48} primary>
        La tua situazione
      </Text>
      <Flex justify='space-between' align='flex-end'>
        <div className='situation-box'>
          <Text as='h5' white bold upper>
            Prenotazione viaggi
          </Text>
          <div className='situation-info-box'>
            <div>
              <Text as='small' upper white bold size={12}>
                {'Corto<br />raggio'}
              </Text>
              <Text primary light size={30}>
                {points?.points_short_range || 0}
              </Text>
            </div>
            <div>
              <Text as='small' upper white bold size={12}>
                {'Medio<br />raggio'}
              </Text>
              <Text primary light size={30}>
                {points?.points_medium_range || 0}
              </Text>
            </div>
            <div>
              <Text as='small' upper white bold size={12}>
                {'Lungo<br />raggio'}
              </Text>
              <Text primary light size={30}>
                {points?.points_long_range || 0}
              </Text>
            </div>
          </div>
        </div>

        {/* <div className='situation-box'>
          <Text as='h5' white bold upper>
            Extra
          </Text>
          <div className='situation-info-box'>
            <div>
              <Text as='small' upper white bold size={12}>
                {'Cliente<br />socio'}
              </Text>
              <Text primary light size={30}>
                {points?.points_partner_customer || 0}
              </Text>
            </div>
            <div>
              <Text as='small' upper white bold size={12}>
                {'Cliente<br />invitato'}
              </Text>
              <Text primary light size={30}>
                {points?.points_invited_customer || 0}
              </Text>
            </div>
          </div>
        </div> */}

        <div className='situation-box'>
          <Text as='h5' white bold upper>
            Punti
          </Text>
          <div className='situation-info-box'>
            <div>
              <Text as='small' upper white bold size={12}>
                {'Punti<br />confermati'}
              </Text>
              <Text primary light size={30}>
                {points?.points_confirmed || 0}
              </Text>
            </div>
            <div>
              <Text as='small' upper white bold size={12}>
                {'Punti<br />da confermare'}
              </Text>
              <Text primary light size={30}>
                {points?.points_to_confirm || 0}
              </Text>
            </div>
          </div>
        </div>

        <Link to='/user/profile'>
          <Button white>Dettagli</Button>
        </Link>
      </Flex>
    </YourSituationWrapper>
  );
};

const YourSituationWrapper = styled.div`
  background-color: #0077c2;
  padding: 10px 15px 15px 15px;
  margin-bottom: 30px;

  .situation-box {
    margin-top: 15px;
    width: 100%;

    ${respondTo.md`
      width: auto;
    `}

    h5 {
      border-bottom: 1px solid #fff;
      padding-bottom: 10px;
      margin-bottom: 15px;
    }

    .situation-info-box {
      display: flex;

      & > div {
        min-width: 100px;

        small {
          line-height: 1;
        }
      }
    }
  }

  button {
    margin-top: 30px;

    ${respondTo.md`
      margin-top: 0;
    `}
  }
`;

export default YourSituationModule;
