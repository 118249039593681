import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Button, Wrapper } from '../../components/Ui';
import { useParams, Link } from 'react-router-dom';
import { respondTo } from '../../theme/mixin';
import customaxios from '../../config/axios-refresh-token';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from '../../store/actions';
import ErrorInModal from '../../components/ErrorInModal';
import { Modal, Flex, Col, Text } from '../../components/Ui';
import Fade from '../../components/Fade/Fade';
import { ReactComponent as Storico } from '../../assets/images/icon_storico.svg';
import { ReactComponent as Letter } from '../../assets/images/icon_email.svg';
import ContentSlide from '../../components/ContentSlide';
import { Code } from 'styled-icons/material';
import CopyButton from '../../components/Games/Response/Code/DisplayCode/CopyButton/CopyButton';
import BoxLayout from '../../components/Ui/BoxLayout';
import { numberWithDots } from '../../utils/utils';
let vouchers = [
  {
    partner_id: 36,
    partner_name: 'Lonely Planet',
    requested: [],
    available: [
      {
        uid_pratica: '2022-03-09-1302034',
        num_pratica: 1302034,
      },
      {
        uid_pratica: '2022-03-14-1342679',
        num_pratica: 1342679,
      },
      {
        uid_pratica: '2022-03-17-1347048',
        num_pratica: 1347048,
      },
    ],
  },
  {
    partner_id: 37,
    partner_name: 'Treedom',
    requested: ['6000011'],
    available: [
      {
        uid_pratica: '2022-03-09-1302034',
        num_pratica: 1302034,
      },
      {
        uid_pratica: '2022-03-17-1347048',
        num_pratica: 1347048,
      },
    ],
  },
];
const SinglePartnerCoupon = props => {
  const [requestCompleted, setRequestCompleted] = useState(false);
  const [requestLoading, setLoadingRequest] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [partner, setPartner] = useState();

  const [modalRequestOpened, setModalRequestOpened] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  const { name, description, static_code, main_image, value } = useSelector(
    state => state.partnersTurisanda.partnerListTurisanda?.find(item => +item.id === +id) || {},
  );

  const userEmail = useSelector(state => state.user.user.userinfo.email);

  const partners = useSelector(state => state.partnersTurisanda.partnerListTurisanda);
  const vouchers = useSelector(state => state.user.user.voucher_turisanda).filter(
    vouch => +vouch.partner_id === +id,
  )[0];

  const requestedVoucher = vouchers.requested;
  const availableVouchers = vouchers.available;
  const [praticId, setPraticId] = useState(null);
  const [vouchersAvailable, setVouchersAvailable] = useState(null);
  const [vouchersRequest, setVouchersRequest] = useState(null);
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    if (id && partners) {
      const newPartner = partners.find(partner => partner.id === Number(id));
      setPartner(newPartner);
    }
  }, [partners, id]);

  useEffect(() => {
    let vouchersAvail = <Text size='16'>Nessun codice richiedibile</Text>;
    let vouchersRequested = <Text size='16'>Nessun codice richiesto</Text>;
    if (availableVouchers.length > 0) {
      vouchersAvail = availableVouchers.map((ele, key) => {
        return (
          <Flex
            key={key}
            justify='space-between'
            align='stretch'
            style={{ height: '100%' }}
            wrap='nowrap'
            className='flexvoucher'
          >
            <Col width={80}>
              <Text size={14} bold>
                Numero pratica {ele.num_pratica}
              </Text>
            </Col>

            <Col width={30} padding={10} className='request-col2'>
              <Button
                active
                style={{ display: 'block', textAlign: 'center', width: 'max-content' }}
                loading={requestLoading}
                disabled={requestLoading}
                onClick={() => requestPrizeModal(ele.uid_pratica)}
              >
                RICHIEDI
              </Button>
            </Col>
          </Flex>
        );
      });
    }
    if (requestedVoucher.length > 0) {
      vouchersRequested = requestedVoucher.map((ele, key) => {
        return (
          <Flex
            key={key}
            justify='space-between'
            align='stretch'
            style={{ height: '100%' }}
            wrap='nowrap'
            className='flexvoucher'
          >
            <Col width={60} style={{ textAlign: 'center' }}>
              <Text
                size={12}
                bold
                style={{
                  padding: '2px',
                  borderRadius: '5px',
                  background: '#AAAAA366',
                  color: '#002D72',
                }}
              >
                {ele}
              </Text>
            </Col>

            <Col width={30} padding={10} className='request-col2'>
              <CopyButton active style={{ textAlign: 'center', padding: '0 20px' }} data={ele}>
                COPIA IL CODICE
              </CopyButton>
            </Col>
          </Flex>
        );
      });
    }
    setVouchersRequest(vouchersRequested);
    setVouchersAvailable(vouchersAvail);
  }, [vouchers, id]);
  const closeModalRequest = () => {
    setModalRequestOpened(false);
  };
  const closeModalError = () => {
    setModalError(false);
  };
  const requestPrizeModal = function (id) {
    setPraticId(id);
    setModalRequestOpened(true);
  };

  const requestCouponHandler = code => {
    let endpoint = `partners/${partner.id}/request`;
    setModalRequestOpened(false);
    setLoadingRequest(true);
    let data = {
      uid_pratica: praticId,
    };
    customaxios
      .post(endpoint, data)
      .then(result => {
        setLoadingRequest(false);
        dispatch(actionCreators.getUserInfo());
        setRequestCompleted(true);
      })
      .catch(error => {
        setLoadingRequest(false);
        setModalError(error.response.data.error);
      });
  };

  return (
    <>
      <Fade>
        <Wrapper>
          <CustomFlex row={15}>
            <Col width={50} className='col1'>
              <img src={main_image} alt='' className='premio-img' />
            </Col>
            <Col width={50} className='col2'>
              <Flex direction='column' style={{ width: '100%' }}>
                <Flex>
                  <Text as='p' size={12}>
                    codice:
                  </Text>
                  <Text size={12} primary style={{ marginLeft: '5px' }}>
                    {static_code}
                  </Text>
                </Flex>

                <ContentSlide
                  title={name}
                  text={description}
                  size={30}
                  xBold='800'
                  noBtn
                  style={{ flex: 1 }}
                />
                <Link to='/turisanda' className='col2-link'>
                  <Button arrowLeft hoverGrey border fullwidth className='col2-btn' upper size={12}>
                    Indietro
                  </Button>
                </Link>
              </Flex>
            </Col>
          </CustomFlex>
          <CustomFlex row={15}>
            <Col width='50'>
              <div className='request'>
                <Text size={24} primary upper style={{ margin: ' 0 0 10px 0' }}>
                  I TUOI CODICI Richiedibili
                </Text>
                {vouchersAvailable}
              </div>
            </Col>
            <Col width={50} className=''>
              <div className='request'>
                <Text size={24} primary upper style={{ margin: ' 0 0 10px 0' }}>
                  I TUOI CODICI Richiesti
                </Text>
                {vouchersRequest}
              </div>
            </Col>
          </CustomFlex>
        </Wrapper>
      </Fade>
      <Modal show={modalRequestOpened} close={closeModalRequest}>
        <Text as='p' size={18} margin='3px 0 25px 0' align='left'>
          Confermi di voler richiedere il premio selezionato?
        </Text>
        <Flex justify='space-between' align='center'>
          <Text as='p' margin='3px 0 0 0' align='left'>
            <Text as='p' uppercase primary size={8} align='left'>
              La tua email:
            </Text>
            <Text as='p' size={14} bold align='left'>
              {userEmail}
            </Text>
          </Text>
          <Button
            active
            style={{ display: 'block', textAlign: 'left', marginLeft: 'auto' }}
            loading={requestLoading}
            disabled={requestLoading}
            onClick={() => requestCouponHandler(partner.static_code)}
          >
            CONFERMA
          </Button>
        </Flex>
      </Modal>
      <Modal show={modalError} close={closeModalError}>
        <ErrorInModal error={modalError} />
      </Modal>
    </>
  );
};
const CustomFlex = styled(Flex)`
  margin-bottom: 60px;
  width: 100%;
  margin: auto;
  .col1 {
    position: relative;
    padding: 0;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 100px;
  }
  .col2 {
    padding: 15px;
    background-color: #fff;
    margin-top: 20px;
    ${respondTo.sm`
        padding: 10px 10px 10px 30px;
       background-color:initial;
       margin-top:130px;
    `}
    &-link {
      width: 100%;
      ${respondTo.sm`
        width:initial;
    `}
    }
    &-btn {
      justify-content: space-between;
      svg {
        order: -1;
        right: 0;
      }
    }
  }

  .premio-img {
    width: 100%;
    height: 400px;
    display: block;
    object-fit: cover;

    ${respondTo.sm`
      height: 600px;
    `}
  }

  .request {
    background-color: #fff;
    padding: 21px 16px;
    border-radius: 15px;
    margin-top: 40px;
    .flexvoucher {
      border-bottom: 1px solid ${({ theme }) => theme.bgcode};
      padding: 20px 0;
    }
    &-col1 {
      ${respondTo.sm`
        width:100%;
    `}
      @media(min-width:1100px) {
        width: initial;
      }
    }
    &-col2 {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: -15px 0;
      width: auto;
      @media (min-width: 1100px) {
        width: auto;
      }
    }
  }

  .coupon-code {
    width: 50%;
    background-color: ${({ theme }) => theme.bgColorDiv};
    padding: 3px 10px;
    margin: 0 auto;

    @media (min-width: 1100px) {
      width: 100%;
    }
  }
  .hiw-content {
    padding: 0;
    margin-top: 20px;
  }
  .hiw-text {
    width: 100%;
  }
`;

export default SinglePartnerCoupon;
