import * as actionTypes from './actionTypes';
import customaxios from '../../config/axios-refresh-token';
import { sendLogError } from './error';
import { getUserInfo } from './user';
import axios from 'axios';
import customAxiosNm from '../../config/axios-refresh-token-no-multipart';

// Products
const getProductsLoading = () => {
  return {
    type: actionTypes.GET_PRODUCTS_LOADING,
  };
};

const getProductsSuccess = products => {
  return {
    type: actionTypes.GET_PRODUCTS_SUCCESS,
    products,
  };
};

const getAllProductsSuccess = products => {
  return {
    type: actionTypes.GET_ALL_PRODUCTS_SUCCESS,
    products,
  };
};

export const getAllProducts = () => {
  let endpoint = 'products';
  return dispatch => {
    customaxios
      .get(endpoint)
      .then(result => {
        if (result.status === 200) {
          dispatch(getAllProductsSuccess(result.data));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
};

const getOrderTurisandaSuccess = ordersTurisanda => {
  return {
    type: actionTypes.GET_ORDER_TURISANDA_SUCCESS,
    ordersTurisanda: ordersTurisanda,
  };
};

export const getOrderTurisanda = () => {
  let endpoint = 'products/orders';
  return dispatch => {
    customaxios
      .get(endpoint)
      .then(result => {
        if (result.status === 200) {
          dispatch(getOrderTurisandaSuccess(result.data.data));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const getProducts = filter => {
  const dataToSend = {};

  if (filter) {
    dataToSend.search = filter.search.value;
    dataToSend.category = filter.category.value;
    dataToSend.points_min = filter.rangeFrom.value === '' ? 0 : filter.rangeFrom.value;
    dataToSend.points_max =
      filter.rangeTo.value === '' ? filter.rangeTo.maxValue : filter.rangeTo.value;
    dataToSend.requestable_only = filter.requestable_only.value;
  }

  let endpoint = 'products';
  return dispatch => {
    dispatch(getProductsLoading());
    customaxios
      .get(endpoint, { params: dataToSend })
      .then(result => {
        if (result.status === 200) {
          dispatch(getProductsSuccess(result.data));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
};

// Single product
const getProductLoading = product => {
  return {
    type: actionTypes.GET_PRODUCT_LOADING,
  };
};

const getProductSuccess = product => {
  return {
    type: actionTypes.GET_PRODUCT_SUCCESS,
    product,
  };
};

export const getSingleProduct = id => {
  let endpoint = `products/${id}`;
  return dispatch => {
    dispatch(getProductLoading());
    customaxios
      .get(endpoint)
      .then(result => {
        if (result.status === 200) {
          dispatch(getProductSuccess(result.data));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const clearOrder = () => {
  return {
    type: actionTypes.CLEAR_ORDER,
  };
};

export const getFiltersCategoriesSuccess = categories => {
  return {
    type: actionTypes.GET_FILTERS_CATEGORIES_SUCCESS,
    categories: categories,
  };
};

export const getFiltersCategories = () => {
  let endpoint = 'catalog-categories';
  return dispatch => {
    customaxios
      .get(endpoint)
      .then(result => {
        if (result.status === 200) {
          dispatch(getFiltersCategoriesSuccess(result.data));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const openWishlistModalAccess = () => {
  return {
    type: actionTypes.OPEN_WISHLIST_MODAL_ACCESS,
  };
};

export const closeWishlistModalAccess = () => {
  return {
    type: actionTypes.CLOSE_WISHLIST_MODAL_ACCESS,
  };
};
